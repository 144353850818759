import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack, Wrap, WrapItem, Button, Icon } from '@chakra-ui/react';
import { FaRegClone } from 'react-icons/fa';
import { HiOutlineTrash } from 'react-icons/hi';
import { useRulesModels } from '../../../../../providers/models';
import InputSelect from '../../../../../components/base/v2/inputs/select';
import clone from '../../../../../utils/clone';
import Params from './params';

import * as I from '../../../interfaces';

const Features = ({
  appendFeature,
  featureIndex,
  featuresLength,
  removeFeature,
}: I.FeaturesProps): React.ReactElement => {
  const { featuresNames, isDisabled } = useRulesModels();
  const { getValues, formState } = useFormContext();

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const handleCloneFeature = useCallback((): void => {
    appendFeature({
      ...clone(getValues(`features.${featureIndex}`)),
    });
  }, []);

  const handleRemoveFeature = useCallback((): void => {
    removeFeature(featureIndex);
  }, [featureIndex]);

  const hasOnly = featuresLength <= 1;

  const ruleName = useWatch({
    control,
    name: `features.${featureIndex}.name`,
  });

  const options = featuresNames.map((item) => {
    return { value: item, option: item };
  });

  return (
    <>
      <Wrap spacing="regular">
        <WrapItem>
          <InputSelect
            label="Feature Name"
            options={options}
            {...register(`features.${featureIndex}.name`)}
            formcontrol={{
              isDisabled,
              isRequired: true,
              error: errors.features?.[featureIndex]?.name,
            }}
            mr="16px"
          />
        </WrapItem>
        <Params featureIndex={featureIndex} ruleName={ruleName} />
      </Wrap>

      <Stack direction="row" marginLeft="auto !important">
        <Button
          onClick={handleCloneFeature}
          isLoading={formState.isSubmitting}
          isDisabled={isDisabled}
        >
          <Icon as={FaRegClone} />
        </Button>
        <Button
          onClick={handleRemoveFeature}
          isLoading={formState.isSubmitting}
          isDisabled={hasOnly || isDisabled}
        >
          <Icon as={HiOutlineTrash} />
        </Button>
      </Stack>
    </>
  );
};

export default Features;
