import React, { useState, useEffect } from 'react';
import AutoSuggest from 'react-autosuggest';
import { useFormContext, useWatch } from 'react-hook-form';
import { trexApi } from '../../../../services/api';
import { SETID } from '../../../../services/storage';
import { AutoCompleteProps } from '../../../../interfaces/components/base/autocomplete';
import { useToastFunctions } from '../../../../utils/useToastFunction';
import * as I from './interfaces';
import * as S from './styles';

export default function AutocompleteTags({
  action,
  disabled,
  name,
  scopeName,
}: Readonly<AutoCompleteProps>): React.ReactElement {
  const [tagsList, setTagsList] = useState<I.AutoCompleteTagModal[]>([
    { id: '', value: '' },
  ]);
  const [dataValue, setDataValue] = useState('');
  const [suggestions, setSuggestions] = useState<I.AutoCompleteTagModal[]>([
    { id: '', value: '' },
  ]);

  const methods = useFormContext();
  const { showToast } = useToastFunctions();
  const { control, watch } = methods;
  const UUID = localStorage.getItem(SETID);

  const tagInput = useWatch({
    control,
    name: 'tag_id',
  });

  useEffect(() => {
    if (tagInput === undefined && dataValue !== '') {
      setDataValue('');
    }
  }, [tagInput]);

  const getTags = async () => {
    try {
      const res = await trexApi.get('tags', {
        params: {
          page: 1,
          'page-size': 15,
          search: dataValue,
          scope: scopeName,
        },
        headers: {
          'X-SetID': UUID,
        },
      });
      if (res.data.items) {
        setTagsList(res.data.items);
      }
    } catch {
      showToast({
        title:
          'Erro ao pesquisar tag, não foi possível pesquisar a tag. Tente novamente mais tarde',
        status: 'error',
      });
    }
  };

  function getTagsSuggestions(
    value: string
  ): Array<{ id: string; value: string }> {
    return tagsList.filter((tag) =>
      `${tag.value.toLowerCase()} (ID: ${tag.id.toLowerCase()})`.includes(
        value.toLowerCase().trim()
      )
    );
  }

  const callAction = (suggestion: I.AutoCompleteTagModal): void => {
    if (action) {
      action('tag_id', suggestion.id);
    }
  };

  useEffect(() => {
    getTags();

    if (dataValue === '' && !!watch('tag_id') && action) {
      action('tag_id', undefined);
    }
  }, [dataValue]);

  return (
    <S.Container>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={(): void => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }): void => {
          setDataValue(value);
          setSuggestions(getTagsSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestion }): void =>
          callAction(suggestion)
        }
        getSuggestionValue={(suggestion): string => suggestion.value}
        renderSuggestion={(suggestion): React.ReactElement => (
          <span>{`${suggestion.value} (ID: ${suggestion.id.substring(
            0,
            6
          )}...)`}</span>
        )}
        inputProps={{
          id: 'suggestionTagsField',
          value: dataValue,
          placeholder: 'Pesquisar',
          name: name ?? 'tags_list',
          onChange: (_, { newValue }): void => {
            setDataValue(newValue);
          },
          disabled: !!disabled,
        }}
        highlightFirstSuggestion
      />
    </S.Container>
  );
}

AutocompleteTags.defaultProps = {
  action: null,
  name: '',
  disabled: false,
  scope: '',
};
