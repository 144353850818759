/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip, Text } from '@chakra-ui/react';

import * as I from './interfaces';

export const Name = ({
  title,
  field,
  width,
  minWidth,
  url,
  editable,
}: I.ColumnProps) => {
  return {
    title,
    field,
    minWidth,
    width,
    editable,
    render: (rowData: any): React.ReactNode => {
      return (
        <Tooltip label="Visualizar" placement="bottom">
          <NavLink to={`${url}${rowData.id}`}>
            <strong>
              {rowData[field || 'name']}{' '}
              <span style={{ color: '#F05D5E' }}>
                (ID: {rowData.id?.substring(0, 6)}...)
              </span>
            </strong>
          </NavLink>
        </Tooltip>
      );
    },
  };
};

export const Status = ({ width, minWidth }: I.ColumnProps) => {
  return {
    title: 'Status',
    field: 'status',
    width,
    minWidth,
    lookup: { enabled: 'active', disabled: 'inactive' },
    render: (rowData: any): React.ReactNode => {
      return (
        <Text
          textTransform="capitalize"
          fontWeight="bold"
          color={rowData.status === 'active' ? '#548158' : '#f05d5e'}
        >
          {rowData.status === 'enabled' ? 'Ativo' : 'Inativo'}
        </Text>
      );
    },
  };
};

export const CustomDate = ({
  title,
  field,
  width,
  minWidth,
  emptyValue,
  editable,
}: I.ColumnProps) => {
  return {
    title,
    field,
    width,
    minWidth,
    emptyValue,
    editable,
    type: 'datetime',
    dateSetting: { locale: 'pt-br', format: 'DD/MM/YYYY HH:mm' },
  };
};

export const Bond = ({
  cellStyle,
  title,
  field,
  width,
  minWidth,
  emptyValue,
  editable,
  render,
}: I.ColumnProps) => {
  return {
    cellStyle,
    title,
    field,
    width,
    minWidth,
    emptyValue,
    editable,
    render,
  };
};

export const Custom = ({
  title,
  field,
  width,
  minWidth,
  emptyValue,
  editable,
}: I.ColumnProps) => {
  return {
    title,
    field,
    width,
    minWidth,
    emptyValue,
    editable,
  };
};
