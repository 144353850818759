import { useKeycloak } from '@react-keycloak/web';

export default function CheckRoles(roles: string[]): boolean {
  const { keycloak } = useKeycloak();

  return roles.some((r) => {
    const realm = keycloak?.hasRealmRole(r);
    const resource = keycloak?.hasResourceRole(r, 'trex-application');
    const mudi = keycloak?.hasResourceRole(r, 'Mudi');
    const account = keycloak?.hasResourceRole(r, 'account');
    return realm || resource || mudi || account;
  });
}
