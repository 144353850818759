import React from 'react';
import * as S from './styles';
import BuyersInfo from './info/buyersInfo';
import PurchaseInfo from './info/purchaseInfo';
import Shippings from './info/shippingsInfo';
import PaymentInfo from './info/paymentInfo';

const Info = () => {
  return (
    <S.Container>
      <S.Row>
        <S.LeftContainer>
          <BuyersInfo />
        </S.LeftContainer>
        <S.RightContainer>
          <Shippings />
        </S.RightContainer>
      </S.Row>
      <S.Row style={{ marginTop: '24px' }}>
        <S.LeftContainer>
          <PurchaseInfo />
        </S.LeftContainer>
        <S.RightContainer>
          <PaymentInfo />
        </S.RightContainer>
      </S.Row>
    </S.Container>
  );
};

export default Info;
