export const fixed = (item: number, some: string): number => {
  const lenght = item.toString().length;

  if (some === 'multiply') {
    if (lenght >= 4) {
      return lenght - 3;
    }
    return lenght;
  }

  return 4;
};

export function transformClientPercentages(percentages?: number) {
  if (percentages) {
    return Number((percentages / 100).toFixed(fixed(percentages, 'divide')));
  }
  return undefined;
}
