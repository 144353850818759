import React from 'react';

import { IconBaseProps } from 'react-icons';
import { Icon, IconProps } from '@chakra-ui/react';
import { SiSkypeforbusiness, SiGoogle, SiYahoo } from 'react-icons/si';
import {
  GrFacebook,
  GrInstagram,
  GrTwitter,
  GrYoutube,
  GrLinkedin,
  GrApple,
  GrWordpress,
  GrWindows,
  GrPinterest,
  GrSpotify,
} from 'react-icons/gr';

export type SocialMidiasModel =
  | 'Apple'
  | 'Facebook'
  | 'Google'
  | 'Gravatar'
  | 'Instagram'
  | 'Linkedin'
  | 'Microsoft'
  | 'Pinterest'
  | 'Skype'
  | 'Spotify'
  | 'Twitter'
  | 'Yahoo'
  | 'Youtube';

export const socialMidias = {
  Apple: (props?: IconBaseProps): React.ReactNode => {
    return <GrApple {...props} />;
  },
  Facebook: (props?: IconBaseProps): React.ReactNode => {
    return <GrFacebook {...props} />;
  },
  Google: (props?: IconBaseProps): React.ReactNode => {
    return <SiGoogle {...props} />;
  },
  Gravatar: (props?: IconBaseProps): React.ReactNode => {
    return <GrWordpress {...props} />;
  },
  Instagram: (props?: IconBaseProps): React.ReactNode => {
    return <GrInstagram {...props} />;
  },
  Linkedin: (props?: IconBaseProps): React.ReactNode => {
    return <GrLinkedin {...props} />;
  },
  Microsoft: (props?: IconBaseProps): React.ReactNode => {
    return <GrWindows {...props} />;
  },
  Pinterest: (props?: IconBaseProps): React.ReactNode => {
    return <GrPinterest {...props} />;
  },
  Skype: (props?: IconBaseProps): React.ReactNode => {
    return <SiSkypeforbusiness {...props} />;
  },
  Spotify: (props?: IconBaseProps): React.ReactNode => {
    return <GrSpotify {...props} />;
  },
  Twitter: (props?: IconBaseProps): React.ReactNode => {
    return <GrTwitter {...props} />;
  },
  Yahoo: (props?: IconBaseProps): React.ReactNode => {
    return <SiYahoo {...props} />;
  },
  Youtube: (props?: IconBaseProps): React.ReactNode => {
    return <GrYoutube {...props} />;
  },
};

export function socialMidia(value: SocialMidiasModel): void {
  socialMidias[value]();
}

export const chakraSocialMidias = {
  Apple: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrApple} {...props} />;
  },
  Facebook: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrFacebook} {...props} />;
  },
  Google: (props?: IconProps): React.ReactNode => {
    return <Icon as={SiGoogle} {...props} />;
  },
  Gravatar: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrWordpress} {...props} />;
  },
  Instagram: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrInstagram} {...props} />;
  },
  Linkedin: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrLinkedin} {...props} />;
  },
  Microsoft: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrWindows} {...props} />;
  },
  Pinterest: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrPinterest} {...props} />;
  },
  Skype: (props?: IconProps): React.ReactNode => {
    return <Icon as={SiSkypeforbusiness} {...props} />;
  },
  Spotify: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrSpotify} {...props} />;
  },
  Twitter: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrTwitter} {...props} />;
  },
  Yahoo: (props?: IconProps): React.ReactNode => {
    return <Icon as={SiYahoo} {...props} />;
  },
  Youtube: (props?: IconProps): React.ReactNode => {
    return <Icon as={GrYoutube} {...props} />;
  },
};

export function chakraSocialMidia(value: SocialMidiasModel): void {
  chakraSocialMidias[value]();
}
