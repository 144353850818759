import styled from 'styled-components';
import ActionButton from '../base/button';

export const Container = styled('div')`
  width: 100%,
  height: 100vh,
  display: flex,
  alignItems: 'center',
  justifyContent: 'center',
`;

export const BtnStreetView = styled(ActionButton)`
  &.btnStreetView {
    position: absolute;
    bottom: ${({ theme }) => theme.spacing(2.5)}px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    width: auto;
    line-height: 1.2;
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    font-weight: 700;
    min-width: ${({ theme }) => theme.spacing(20)}px;
    font-size: 18px;
    background: ${({ theme }) => theme.colors.v2.green[300]};
    color: ${({ theme }) => theme.colors.v2.white[100]};
    padding: ${({ theme }) => theme.spacing(2)}px;
    &:hover{
      background: ${({ theme }) => theme.colors.v2.green[200]}
    },
  },
`;
