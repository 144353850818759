import { Text } from '@chakra-ui/react';
import React from 'react';
import { theme } from '../../styles/theme';
import useGenericCallback from '../../utils/callbacks';
import IconButton from '../icons/iconButton';
import * as I from './interfaces';
import * as S from './styles';

export default function Stat({
  title,
  children,
  subtitle,
  text,
  fontSize = 'medium',
  fontWeight = 600,
  identifier,
  fontColor,
  copy,
}: I.StatProps): React.ReactElement {
  const { handleCopyButtonClick } = useGenericCallback();

  return (
    <S.Container data-testid={`stat-container-${identifier}`}>
      {title && (
        <S.Title
          data-testid={`stat-title-${identifier}`}
          color={fontColor || theme.colors.v2.dark[200]}
        >
          {title}
        </S.Title>
      )}
      <S.Row>
        {text && (
          <Text
            fontSize={fontSize}
            data-testid={`stat-text-${identifier}`}
            fontWeight={fontWeight}
            color={fontColor || theme.colors.v2.green[400]}
          >
            {text}
          </Text>
        )}
        {children}
        {copy && (
          <S.CopyContainer>
            <IconButton
              color={fontColor || theme.colors.v2.dark[200]}
              fontSize="small"
              onClick={handleCopyButtonClick(copy)}
            />
          </S.CopyContainer>
        )}
      </S.Row>
      <S.SubTitle data-testid={`stat-subtitle-${identifier}`}>
        {subtitle}
      </S.SubTitle>
    </S.Container>
  );
}
