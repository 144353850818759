import React from 'react';
import { Button, Icon, Stack } from '@chakra-ui/react';

import { useFormContext } from 'react-hook-form';
import { FiExternalLink } from 'react-icons/fi';
import * as I from '../interfaces';

import AutocompleteGroups from '../../../../components/base/autocomplete_groups';
import { H3 } from '../../../../components/base/Headings';
import { handleExternalLink } from '../../../../utils/externalLink';

export default function Group({
  isDisabled,
  value,
  setValue,
}: I.BondsProps): React.ReactElement {
  const { register } = useFormContext();

  return (
    <Stack spacing="md">
      <H3>Grupo Vinculado</H3>
      <Stack direction="row" spacing="md" alignItems="center">
        <div className="default--input" style={{ flex: 1 }}>
          <AutocompleteGroups
            {...register('group_id')}
            action={(element: string): void => setValue(element)}
            disabled={isDisabled}
            defaultValue={value}
          />
        </div>

        {value && isDisabled && (
          <Button
            data-testid="external-link"
            type="button"
            onClick={() => handleExternalLink(`/groups/info?id=${value}`)}
            colorScheme="gray"
            title="Acessar esse grupo"
          >
            <Icon as={FiExternalLink} />
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
