export const actionTranslations: { [key: string]: string } = {
  approve: 'Aprovar',
  reject: 'Rejeitar',
  review: 'Revisar',
  suspend: 'Suspender',
};

export const actionColors: {
  [key: string]: 'green' | 'red' | 'orange' | 'blue';
} = {
  approve: 'green',
  reject: 'red',
  review: 'orange',
  suspend: 'blue',
};
