import React from 'react';
import BoxReview from '.';
import { useOrder } from '../../providers/order';
import SuspensionBox from './boxes/suspensionBox';
import HeldBox from './boxes/heldBox';

const HandleBox = () => {
  const { orderContext } = useOrder();

  if (orderContext?.review_detail?.state === 'withheld') {
    return <HeldBox />;
  }

  if (orderContext?.suspension_detail?.state === 'suspended') {
    return <SuspensionBox />;
  }

  return <BoxReview />;
};

export default HandleBox;
