/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { FieldError } from 'react-hook-form';
import * as I from './interfaces';

export const getError = ({
  errors,
  path,
}: I.GetErrorProps): FieldError | undefined => {
  const pathParts = path.split('.');
  return pathParts.reduce((currentError, part) => {
    if (typeof currentError === 'object' && currentError !== null) {
      return currentError[part];
    }
    return undefined;
  }, errors as any);
};
