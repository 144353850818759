import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import NotificationCircle from '../../notificationCircle';
import BaseTabs from '../../tabs';
import Bureau from './bureau';
import MapInfo from './mapInfo';
import CommentsInfo from './commentsInfo';
import TransactionsHistory from './transactionsHistory';

import * as S from './styles';
import * as I from '../../../interfaces/order';
import { useOrder } from '../../../providers/order';
import useAnalysis from '../../../hooks/api/analysis';

const TabInfo = ({ similarities }: I.SimilarityProps): React.ReactElement => {
  const [transactionLength, setTransactionLength] = useState(0);
  const [totalCommentsLength, setTotalCommentsLength] = useState(0);
  const { orderContext } = useOrder();
  const { getAllAnalysis } = useAnalysis();

  const query = {
    page: 1,
    pageSize: 5,
  };

  const handleCommentsLength = (): void => {
    const events = orderContext?.review_detail?.events;
    const comments = orderContext?.review_detail?.comments;
    const suspension = orderContext?.suspension_detail?.events;

    const commentsLength = comments?.length ?? 0;
    const reviewLength =
      events?.filter(
        (item) => item.event_type === 'review' && item.action === 'finalize'
      ).length ?? 0;

    const delaysLength =
      events?.filter((item) => item.action === 'delay').length ?? 0;
    const heldLength =
      events?.filter((item) => item.action === 'withhold').length ?? 0;
    const suspensionLength =
      suspension?.filter((item) => item?.message).length ?? 0;

    setTotalCommentsLength(
      commentsLength +
        reviewLength +
        delaysLength +
        heldLength +
        suspensionLength
    );
  };

  useEffect(() => {
    const handleData = async () => {
      const { data } = await getAllAnalysis({ id: orderContext?.id, query });
      setTransactionLength(data.total_of_items);
    };

    if (orderContext) {
      handleData();
      handleCommentsLength();
    }
  }, [orderContext]);

  return (
    <S.Container>
      <BaseTabs
        titles={[
          'Dados de birô',
          <>
            <Text>Histórico de transações</Text>
            <NotificationCircle text={transactionLength} />
          </>,
          <>
            <Text>Comentários</Text>
            <NotificationCircle text={totalCommentsLength} />
          </>,
          'Mapa',
        ]}
      >
        <S.TabPanel style={{ marginTop: '24px' }}>
          <Bureau similarities={similarities} />
        </S.TabPanel>
        <S.TabPanel>
          <TransactionsHistory />
        </S.TabPanel>
        <S.TabPanel>
          <CommentsInfo />
        </S.TabPanel>
        <S.TabPanel>
          <MapInfo />
        </S.TabPanel>
      </BaseTabs>
    </S.Container>
  );
};

export default TabInfo;
