import { Tag as ChakraTag, TagRightIcon } from '@chakra-ui/react';
import styled from 'styled-components';

export const Tag = styled(ChakraTag)<{
  $backgroundColor: string;
  $fontColor: string;
}>`
  border-radius: ${({ theme }) => theme.spacing(5)}px !important;
  background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
  color: ${({ $fontColor }) => $fontColor} !important;
  padding: ${({ theme }) => theme.spacing(0.5)}px
    ${({ theme }) => theme.spacing(1.5)}px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LeftTagIcon = styled(TagRightIcon)`
  margin-right: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const TagIcon = styled(TagRightIcon)`
  margin-left: ${({ theme }) => theme.spacing(0.5)}px;
`;
