import { theme } from '../../styles/theme';
import * as I from './interfaces';

export const colors: I.Colors = {
  green: {
    100: theme.colors.v2.green['100'],
    200: theme.colors.v2.green['200'],
  },
  orange: {
    100: theme.colors.v2.orange['100'],
    200: theme.colors.v2.orange['200'],
  },
  red: {
    100: theme.colors.v2.red['100'],
    200: theme.colors.v2.red['200'],
  },
  blue: {
    100: theme.colors.v2.blue['100'],
    200: theme.colors.v2.blue['200'],
  },
  gray: {
    100: theme.colors.v2.gray['100'],
    200: theme.colors.v2.gray['200'],
  },
  darkGray: {
    100: '#30374214',
    200: theme.colors.v2.gray['200'],
  },
};

export const handleBadgeColor = ({ schema, transparent }: I.ColorProps) => {
  const backgroundColor = transparent
    ? colors[schema as keyof I.Colors][100]
    : colors[schema as keyof I.Colors][200];
  const fontColor = transparent
    ? colors[schema as keyof I.Colors][200]
    : theme.colors.v2.white['100'];

  return {
    backgroundColor,
    fontColor,
  };
};
