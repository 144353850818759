import * as React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import * as I from '../baseModal/inteface';
import * as S from './styles';

const ReviewModal = ({
  isOpen,
  size = 'md',
  onClose,
  action,
  secondAction,
  closeText,
  title,
  description,
  actionText,
  children,
  scrollBehavior = 'outside',
}: I.AlertModalProps): React.ReactElement => {
  return (
    <Modal
      isOpen={isOpen}
      scrollBehavior={scrollBehavior}
      size={size}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <S.ModalHeader>
          <S.Title>{title}</S.Title>
        </S.ModalHeader>
        <S.CloseButton />
        <ModalBody padding="24px">
          <span>{description}</span>
          {children}
        </ModalBody>
        {(action || closeText) && (
          <ModalFooter>
            {closeText && (
              <Button mr={3} onClick={secondAction || onClose}>
                {closeText}
              </Button>
            )}
            {action && (
              <S.SubmitButton type="submit" onClick={action}>
                {actionText || 'ENVIAR'}
              </S.SubmitButton>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ReviewModal;
