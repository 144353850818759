import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  position: relative;

  input {
    height: 2.75rem !important;
    border-radius: 0.5rem !important;
    border: 1px solid !important;
    border-color: ${({ theme }) => theme.colors.v2.dark[200]} !important;
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 50px;
    width: 280px;
    border: 1px solid ${({ theme }) => theme.colors.gray[700]};
    background-color: ${({ theme }) => theme.colors.white[700]};
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 20;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) => theme.colors.gray[800]};
  }

  .highlight {
    color: ${({ theme }) => theme.colors.red[800]};
    font-weight: bold;
  }

  .react-autosuggest__suggestion--focused .highlight {
    color: ${({ theme }) => theme.colors.black[200]};
  }
`;

export default Container;
