/* eslint-disable react/prop-types */
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import {
  FormControl,
  FormLabel,
  Select as ChakraSelect,
  FormErrorMessage,
} from '@chakra-ui/react';

import { FiAlertCircle } from 'react-icons/fi';
import { SelectProps } from '../../../../interfaces/components/base/input';
import Tooltip from '../../tooltip';

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  { label, tip, error, name, options, values, isRequired, ...rest },
  ref
): React.ReactElement => {
  return (
    <FormControl
      isInvalid={!!error}
      width="fit-content"
      isRequired={isRequired}
    >
      {!!label && (
        <FormLabel htmlFor={name} fontSize="xs" display="flex">
          {!!tip && (
            <Tooltip content={tip}>
              <FiAlertCircle style={{ marginRight: '7px' }} />
            </Tooltip>
          )}
          {label}
        </FormLabel>
      )}
      <ChakraSelect
        id={name}
        name={name}
        ref={ref}
        isRequired={isRequired}
        {...rest}
        size="sm"
        minWidth="160px"
        focusBorderColor="blue.100"
        variant="outline"
        borderRadius="5px"
      >
        {values.map((item: string, index: number) => (
          <option key={item} value={item}>
            {options ? options[index] : item}
          </option>
        ))}
      </ChakraSelect>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

const Select = forwardRef(SelectBase);

export default Select;
