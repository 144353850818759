import React, { useCallback } from 'react';
import {
  Editable,
  EditableInput,
  EditablePreview,
  Icon,
  Stack,
} from '@chakra-ui/react';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useRulesModels } from '../../../../providers/models';

import * as I from '../../interfaces';

const CardsAction = ({
  dragProvider,
  moveFeature,
  featureIndex,
  features,
}: I.CardActionsProps): React.ReactElement => {
  const { isDisabled } = useRulesModels();
  const hasOnly = features.length <= 1;

  const handleChange = useCallback(
    (e: string) => {
      moveFeature(featureIndex, Number(e));
    },
    [featureIndex]
  );

  return (
    <Stack direction="row" spacing="regular" alignItems="center">
      <div {...dragProvider.dragHandleProps}>
        <Icon
          as={RxDragHandleDots2}
          fontSize="larger"
          marginLeft="-18px"
          style={{ transitionDuration: `0.001s` }}
          cursor={hasOnly ? 'not-allowed' : 'move'}
        />
      </div>

      <Editable
        value={String(featureIndex)}
        maxW="40px"
        minW="40px"
        textAlign="center"
        bg="gray.400"
        borderRadius="7px"
        isDisabled={isDisabled}
        onChange={handleChange}
      >
        <EditablePreview w="100%" _hover={{ background: 'white.200' }} />
        <EditableInput
          as="select"
          _focus={{ background: 'white.200' }}
          _active={{
            background: 'white.200',
          }}
        >
          {features.map((featurePath, featurePathIndex) => {
            const key = `index_options_${featurePathIndex}-${featurePath.id}-${featurePathIndex}`;

            return (
              <option key={key} value={featurePathIndex}>
                {featurePathIndex}
              </option>
            );
          })}
        </EditableInput>
      </Editable>
    </Stack>
  );
};

export default CardsAction;
