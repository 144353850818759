import Keycloak from 'keycloak-js';

export const keycloak = Keycloak({
  realm: 'TRex',
  url: process.env.REACT_APP_KEYCLOAK_API,
  clientId: 'Mudi',
});

export const keycloakProviderInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
};
