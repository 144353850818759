import React, { useEffect, useState } from 'react';
import { Divider, Text, useDisclosure } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import { useOrder } from '../../providers/order';
import { handleTagsByOrigin, handleTitleTranslation } from './utils';
import { TagsinOrderModel } from '../../interfaces/order';
import { handleIcon } from '../../utils/tags';
import Badge from '../badge';
import ReviewModal from '../reviewModal';

import * as S from './styles';

const OrderTags = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const { orderContext } = useOrder();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const orderedTags = handleTagsByOrigin(orderContext?.tags);

  useEffect(() => {
    if (orderContext) {
      setLoading(false);
    }
  }, [orderContext]);

  const handleTags = () => {
    return Object.entries(orderedTags).map(
      (item: [string, TagsinOrderModel[]], index) => {
        return (
          <div key={item[0]}>
            <Text fontSize={18} mb={2} color="#767F8D">
              {handleTitleTranslation(item[0])}
            </Text>
            {item[1].map((tag) => {
              const key = `tag-${tag.id}`;
              return (
                <Badge
                  key={key}
                  identifier={key}
                  label={tag.value}
                  schema="darkGray"
                  fontSize="large"
                  leftIcon={handleIcon(tag.origin) as IconType}
                  transparent
                  mr={2}
                  mb={3}
                />
              );
            })}
            {Object.entries(orderedTags).length > 2 &&
              Object.entries(orderedTags).length !== index + 1 && (
                <Divider my={4} />
              )}
          </div>
        );
      }
    );
  };

  if (loading || !orderContext?.tags) {
    return <></>;
  }

  return (
    <>
      {orderContext?.tags && (
        <S.Container>
          <S.TagsContainer>
            {orderContext.tags.map((tag) => {
              const key = `tag-${tag.id}`;
              return (
                <Badge
                  key={key}
                  identifier={key}
                  label={tag.value}
                  schema="darkGray"
                  fontSize="large"
                  leftIcon={handleIcon(tag.origin) as IconType}
                  minWidth="none"
                  transparent
                  mr={4}
                />
              );
            })}
          </S.TagsContainer>
          <S.StyledButton onClick={() => onOpen()}>
            <Text>Ver mais...</Text>
          </S.StyledButton>
          <ReviewModal
            title="Tags"
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
          >
            <>{handleTags()}</>
          </ReviewModal>
        </S.Container>
      )}
    </>
  );
};

export default OrderTags;
