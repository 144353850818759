/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-throw-literal */
import { Query } from '@material-table/core';
import { AxiosError } from 'axios';
import { FlowModel } from '../../../interfaces/flow';
import { trexApi } from '../../../services/api';
import { SETID } from '../../../services/storage';
import checkValid from '../../../utils/check_valid';
import { UpdateFlowType } from '../interfaces';

const useRulesFlows = () => {
  const UUID = localStorage.getItem(SETID);
  const prefix = 'flows';

  const createFlow = async (data: FlowModel) => {
    try {
      const response = await trexApi.post(prefix, data, {
        headers: { 'X-SetID': UUID },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const updateFlow = async ({ id, data, version }: UpdateFlowType) => {
    try {
      const response = await trexApi.put(`${prefix}/${id}`, data, {
        headers: {
          'if-match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const getRules = async () => {
    try {
      const response = await trexApi.get('rules', {
        params: { 'page-size': 100 },
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const getFlows = async (query: Query<FlowModel>) => {
    try {
      const response = await trexApi.get(prefix, {
        params: {
          page: query.page + 1,
          'page-size': query.pageSize,
          'sort-by': query.orderBy?.field
            ? `${query.orderBy.field.toString()},${query.orderDirection}`
            : null,
          search: checkValid(query.search),
        },
        headers: {
          'X-SetID': UUID,
        },
      });
      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const getOneFlow = async (id: string | undefined) => {
    try {
      const response = await trexApi.get(`${prefix}/${id}`, {
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  return {
    createFlow,
    updateFlow,
    getRules,
    getOneFlow,
    getFlows,
  };
};
export default useRulesFlows;
