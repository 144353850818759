import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Switch } from 'react-router-dom';

import PrivateRoute from './privateRoute';
import ConstantRoutes from './routesArray';
import ClientsList from '../pages/clients/list';
import OrdersList from '../pages/orders/list';

export default function Routes(): React.ReactElement {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <div>Carregando...</div>;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return <>redirect to keycloak...</>;
  }

  return (
    <Switch>
      <PrivateRoute
        roles={['view_clients', 'view_analysis']}
        path="/"
        exact
        component={
          keycloak.resourceAccess?.['trex-application'].roles.includes(
            'view_analysis'
          )
            ? OrdersList
            : ClientsList
        }
      />

      {ConstantRoutes.map((route) => (
        <PrivateRoute
          key={route.path}
          roles={route.roles}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}
