/* eslint-disable import/prefer-default-export */

import { Query, QueryResult } from '@material-table/core';
import { toast } from 'react-toastify';
import useAnalysis from '../../../../hooks/api/analysis';
import { OrderModel } from '../../../../interfaces/order';
import * as I from './interface';

export const loadHistory = (
  query: Query<OrderModel> | I.QueryType,
  orderContext: OrderModel | null
): Promise<QueryResult<OrderModel>> =>
  new Promise((resolve, reject) => {
    const { getAllAnalysis } = useAnalysis();

    query.page += 1;

    getAllAnalysis({ id: orderContext?.id, query })
      .then((result) => {
        if (result.data.items) {
          return resolve({
            data: result.data.items,
            page: result.data.page - 1,
            totalCount:
              result.data.total_of_items > 9999
                ? 10000
                : result.data.total_of_items,
          });
        }
        return resolve({
          data: [],
          page: result.data.page - 1,
          totalCount: result.data.total_of_items,
        });
      })
      .catch(() => {
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
        return reject(toast.error('Nenhum resultado encontrado'));
      });
  });
