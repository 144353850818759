/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { AbsoluteCenter, Stack, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import DoubleCheckModal from '../../base/v2/DoubleCheckModal';
import LoadingSpinner from '../../loadingSpinner';
import usePartners from '../../../hooks/api/partners';
import clone from '../../../utils/clone';
import { handleTagsFormToScopeModel } from '../../bondTags/utils';

import { FormToPartnersModel } from './utils';
import validateSchema from '../../../validations/partners';
import { handleSubmitAndCloseModal } from '../../complex/form/utils';
import BondTags from '../../bondTags';
import SubmenuPartners from './submenu';
import Contacts from './contacts';
import GenericFooter from '../../complex/form/generic/footer';

import * as I from '../../../interfaces/partners';
import * as I2 from '../../bondTags/interfaces';

const PartnerUpsertForm = ({
  loadInfo,
  partner,
  isDisabled,
  setIsDisabled,
}: I.PropsPartnersForm): React.ReactElement => {
  const [partnerData, setPartnerData] = useState(partner);
  const [isLoaded, setIsLoaded] = useState(false);

  const { createPartner, editPartner } = usePartners();
  const { push } = useHistory();

  const {
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
    isOpen: isSubmitOpen,
  } = useDisclosure();

  const {
    onOpen: onDescartOpen,
    onClose: onDescartClose,
    isOpen: isDescartOpen,
  } = useDisclosure();

  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: partnerData,
  });

  useEffect(() => {
    methods.reset(partnerData);
    setIsLoaded(true);
  }, [partnerData]);

  const handleError = (error: { response: string }) => {
    onSubmitClose();
    const { reason } = JSON.parse(error.response ?? '');
    const fieldRegex = /the field '([^']+)'/;
    const match = clone(reason).match(fieldRegex);

    if (match) {
      const field = match[1];
      methods.setError(field, {
        type: 'manual',
        message: reason,
      });
    }

    toast.error(`Não foi possível criar o parceiro: ${reason}`);
  };

  const handleSuccessResponse = (
    res: I.PartnerResponseForm,
    isUpdate: boolean
  ) => {
    if ([200, 201, 204].includes(res.request.status)) {
      const successMessage = isUpdate
        ? 'Parceiro alterado com sucesso!'
        : 'Parceiro publicado com sucesso!';

      toast.success(successMessage);

      if (!isUpdate) {
        push(`/partners/info?id=${res.data.id}`);
      } else {
        setIsLoaded(false);
        setPartnerData(res.data);
      }

      onSubmitClose();
    } else {
      handleError(res.request);
    }
  };

  const submitForm = async (data: I.PartnersModel): Promise<boolean> => {
    methods.formState.isSubmitting = true;
    const sendData = FormToPartnersModel(data);

    try {
      let res;
      if (partnerData.id) {
        res = await editPartner({
          id: partnerData.id,
          data: sendData,
          version: partnerData.version,
        });
      } else {
        res = await createPartner({ data: sendData });
      }
      setIsDisabled(true);
      handleSuccessResponse(res, !!partnerData.id);
      return true;
    } catch (error) {
      onSubmitClose();
      toast.error(
        'Não foi possível criar o parceiro. Tente novamente mais tarde.'
      );
      return false;
    } finally {
      methods.formState.isSubmitting = false;
    }
  };

  const handleSubmit = handleSubmitAndCloseModal<I.PartnersModel>({
    methods,
    submitFunction: submitForm,
    onSubmitClose,
  });

  const handleDescart = async (): Promise<void> => {
    setIsLoaded(false);
    loadInfo!().then(() => {
      methods.reset(partnerData);
      onDescartClose();
      setIsLoaded(true);
    });
  };

  const handleTagSubmit = ({ data, type }: I2.TagSubmitType) => {
    const sendData = handleTagsFormToScopeModel({
      scope: partnerData,
      data,
      type,
    }) as I.PartnersModel;

    return editPartner({
      id: partnerData?.id,
      data: sendData,
      version: partnerData?.version,
    }).finally(() => {
      loadInfo!();
    });
  };

  if (!isLoaded) {
    return (
      <AbsoluteCenter>
        <LoadingSpinner />
      </AbsoluteCenter>
    );
  }

  return (
    <>
      {partnerData.id && (
        <BondTags
          scope={partnerData}
          scopeName="partner"
          onSubmitScope={handleTagSubmit}
        />
      )}
      <Stack spacing="regular">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <SubmenuPartners partner={partnerData} isDisabled={isDisabled} />
            <Contacts partner={partnerData} isDisabled={isDisabled} />
            <GenericFooter
              publishNewText="Publicar parceiro"
              publishUpdateText="Publicar alterações"
              alertText="Revise as informações antes de publicar o parceiro."
              onDescartOpen={onDescartOpen}
              onOpen={onSubmitOpen}
              hasID={Boolean(partnerData.id)}
              isEditing={!isDisabled}
            />

            <DoubleCheckModal
              title="Publicar novo parceiro"
              description="Ao publicar as alterações, elas serão vinculadas ao parceiro."
              isOpen={isSubmitOpen}
              onClose={onSubmitClose}
              modal={{ size: 'xl' }}
              primaryButton={{
                colorScheme: 'green',
                text: 'Publicar parceiro',
                action: handleSubmit,
              }}
              isLoading={methods.formState.isSubmitting}
            />

            <DoubleCheckModal
              title="Descartar alterações do parceiro"
              description="Ao descartar as alterações que você está fazendo agora, o formulário voltará ao estado da última atualização, eliminando todas as modificações que você fez na edição atual. Tem certeza que deseja prosseguir?"
              isOpen={isDescartOpen}
              onClose={onDescartClose}
              modal={{ size: 'xl' }}
              primaryButton={{
                colorScheme: 'green',
                text: 'Descartar alterações',
                action: handleDescart,
              }}
              isLoading={!isLoaded}
            />
          </form>
        </FormProvider>
      </Stack>
    </>
  );
};

export default PartnerUpsertForm;
