const colors = {
  blue: '#191D32',
  red: '#F05D5E',
  light: '#FFFFFF',
  softLight: '#FCFCFC',
  softGray: '#f5f5f5',
  dark: '#3A3A3C',
  softDark: '#757576',
};

export default colors;
