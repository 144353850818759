import React from 'react';

import { InputGroup } from '@chakra-ui/react';
import * as I from '../../../../interfaces/components/base/input';
import * as S from './styles';
import FormControl from '../inputs/formControl';

export default function InputRegion({
  formcontrol,
  label,
  name,
  country,
  value,
  handleChange,
}: Readonly<I.InputRegionProps>): React.ReactElement {
  return (
    <FormControl {...formcontrol}>
      <InputGroup>
        <S.InputRegion
          defaultOptionLabel="Selecione um Estado"
          countryValueType="short"
          valueType="short"
          id={name}
          name={name}
          country={country}
          showDefaultOption="-"
          value={value}
          onChange={handleChange}
          disabled={formcontrol?.isDisabled}
        />

        <S.ActiveFormLabel htmlFor={name} data-focus>
          {label}
        </S.ActiveFormLabel>
      </InputGroup>
    </FormControl>
  );
}
