import { TagFormType } from './interfaces';

export const handleFormToTagsModel = (data: TagFormType) => {
  return {
    scopes: data.scopes,
    value: data.name,
  };
};

export const scopeOptions = [
  { label: 'Cliente', value: 'client' },
  { label: 'Grupo', value: 'group' },
  { label: 'Fluxo', value: 'flow' },
  { label: 'Modelo', value: 'model' },
  { label: 'Parceiro', value: 'partner' },
];
