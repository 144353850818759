/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Button from '../base/v2/button';

export const TagsContainer = styled.div`
  width: calc(100% - 200px);
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0 48px;
  margin: 24px 0 0 0;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const StyledButton = styled(Button)`
  width: 150px !important;
  height: 30px !important;
  border-radius: 12px !important;
  box-shadow: none !important;
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.v2.gray[200]};
`;
