import React from 'react';
import { HiOutlineTag } from 'react-icons/hi';
import Card from '../../../components/base/v2/card';
import TagsView from './section';

import * as I from '../interfaces';

export const ViewTags = ({
  reloadTable,
  tableRef,
}: I.ViewTagProps): React.ReactElement => {
  return (
    <Card title="Tags" icon={HiOutlineTag}>
      <>
        <TagsView
          reloadTable={reloadTable.client}
          tableRef={tableRef.client}
          item={{
            title: 'Cliente',
            scope: 'client',
          }}
        />
        <TagsView
          reloadTable={reloadTable.group}
          tableRef={tableRef.group}
          item={{
            title: 'Grupo',
            scope: 'group',
          }}
        />
        <TagsView
          reloadTable={reloadTable.flow}
          tableRef={tableRef.flow}
          item={{
            title: 'Fluxo',
            scope: 'flow',
          }}
        />
        <TagsView
          reloadTable={reloadTable.model}
          tableRef={tableRef.model}
          item={{
            title: 'Modelo',
            scope: 'model',
          }}
        />
        <TagsView
          reloadTable={reloadTable.partner}
          tableRef={tableRef.partner}
          item={{
            title: 'Parceiro',
            scope: 'partner',
          }}
        />
      </>
    </Card>
  );
};

export default ViewTags;
