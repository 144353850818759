/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-throw-literal */
import { AxiosError } from 'axios';
import { Query } from '@material-table/core';
import { trexApi } from '../../../services/api';
import { GroupModel } from '../../../interfaces/groups';
import { SETID } from '../../../services/storage';
import * as I from '../interfaces';

const useGroups = () => {
  const UUID = localStorage.getItem(SETID);
  const prefix = 'groups';

  const getAllGroups = async (query: Query<GroupModel>) => {
    try {
      const response = await trexApi.get(prefix, {
        params: {
          page: query.page + 1,
          'page-size': query.pageSize,
          'sort-by': query.orderBy?.field
            ? `${query.orderBy.field.toString()},${query.orderDirection}`
            : null,
          search: query.search ? query.search : null,
        },
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const createGroup = async (data: GroupModel) => {
    try {
      const response = await trexApi.post(prefix, data, {
        headers: {
          'content-type': 'application/json',
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const updateGroup = async ({ id, data, version }: I.UpdateGroupType) => {
    try {
      const response = await trexApi.patch(`groups/${id}`, data, {
        headers: {
          'content-type': 'application/json',
          'If-Match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const getOneGroup = async (id: string | undefined) => {
    try {
      const response = await trexApi.get(`groups/${id}`, {
        headers: {
          'content-type': 'application/json',
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const bindGroupsModel = async ({
    id,
    scopeID,
    version,
  }: I.GenericBondRequestType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${id}/models/${scopeID}`,
        null,
        {
          headers: {
            'content-type': 'application/json',
            'If-Match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const bindGroupsFlow = async ({
    id,
    scopeID,
    version,
  }: I.GenericBondRequestType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${id}/flows/${scopeID}`,
        null,
        {
          headers: {
            'content-type': 'application/json',
            'If-Match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const bindGroupsActions = async ({
    id,
    scopeID,
    version,
  }: I.GenericBondRequestType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${id}/action-lists/${scopeID}`,
        null,
        {
          headers: {
            'content-type': 'application/json',
            'If-Match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  return {
    getAllGroups,
    createGroup,
    updateGroup,
    getOneGroup,
    bindGroupsModel,
    bindGroupsFlow,
    bindGroupsActions,
  };
};

export default useGroups;
