import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export default function ActionButton({
  children,
  ...rest
}: ButtonProps): React.ReactElement {
  return <Button {...rest}>{children}</Button>;
}

ActionButton.defaultProps = {
  type: 'button',
  color: '#fff',
  backgroundColor: '#191d32',
};
