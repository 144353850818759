import * as I from './interfaces';

import { trexApi } from '../../../services/api';
import { SETID } from '../../../services/storage';
import checkValid from '../../../utils/check_valid';
import { handleApiRequest } from '../utils';

const useBlocks = () => {
  const UUID = localStorage.getItem(SETID);
  const prefix = 'blocks';

  const createBlock = async ({ data }: Pick<I.BlocksAPIsProps, 'data'>) => {
    const response = await handleApiRequest(
      trexApi.post(prefix, data, {
        headers: { 'X-SetID': UUID },
      })
    );

    return response;
  };

  const updateBlock = async ({ id, data, version }: I.BlocksAPIsProps) => {
    const response = await handleApiRequest(
      trexApi.put(`${prefix}/${id}`, data, {
        headers: {
          'if-match': version,
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getBlocks = async ({
    page,
    pageSize,
    orderBy,
    orderDirection,
    search,
  }: I.BlocksAPIsQueryType) => {
    const response = await handleApiRequest(
      trexApi.get(prefix, {
        params: {
          page: page + 1,
          'page-size': pageSize,
          'sort-by': orderBy?.field
            ? `${orderBy.field.toString()},${orderDirection}`
            : null,
          search: checkValid(search),
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getOneBlock = async ({ id }: Pick<I.BlocksAPIsProps, 'id'>) => {
    const response = await handleApiRequest(
      trexApi.get(`${prefix}/${id}`, {
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  return {
    createBlock,
    updateBlock,
    getOneBlock,
    getBlocks,
  };
};
export default useBlocks;
