import React from 'react';

import * as I from './interfaces';
import InputText from './text';
import InputNumber from './number';
import InputSelect from './select';
import InputSearchSelect from './searchselect';
import InputDuration from './duration';
import { getError } from './utils';

export default function InputFactory({
  name,
  path,
  type,
  isDisabled,
  isRequired,
  multiple,
  values,
  defaultValue,
  methods,
  fetchByAPI,
}: Readonly<I.InputFactoryProps>): React.ReactElement {
  if (type === 'text') {
    return (
      <InputText
        label={name}
        defaultValue={defaultValue}
        activeLabel
        {...methods.register(`${path}`)}
        formcontrol={{
          isRequired,
          isDisabled,
          error: getError({ errors: methods.formState.errors, path }),
        }}
      />
    );
  }

  if (type === 'select') {
    if (multiple && values) {
      return (
        <InputSearchSelect
          isMulti
          control={methods.control}
          label={name}
          name={path}
          formcontrol={{
            isRequired,
            isDisabled,
            error: getError({ errors: methods.formState.errors, path }),
          }}
          initialOptions={values.map((value) => ({ label: value, value }))}
          fetchByAPI={fetchByAPI}
        />
      );
    }

    return (
      <>
        {values && (
          <InputSelect
            label={name}
            defaultValue={defaultValue}
            options={values.map((value) => ({ option: value, value }))}
            {...methods.register(`${path}`)}
            formcontrol={{
              isRequired,
              isDisabled,
              error: getError({ errors: methods.formState.errors, path }),
            }}
          />
        )}
      </>
    );
  }

  if (type === 'autocomplete') {
    return (
      <InputSearchSelect
        isMulti
        isClearable
        control={methods.control}
        label={name}
        name={path}
        formcontrol={{
          isRequired,
          isDisabled,
          error: getError({ errors: methods.formState.errors, path }),
        }}
        initialOptions={values?.map((value) => ({ label: value, value }))}
        fetchByAPI={fetchByAPI}
      />
    );
  }

  if (type === 'boolean') {
    if (!isRequired) {
      return (
        <InputSelect
          label={name}
          defaultValue={defaultValue}
          options={[
            { option: ' ', value: 'null' },
            { option: 'Sim', value: 'true' },
            { option: 'Não', value: 'false' },
          ]}
          {...methods.register(`${path}`)}
          formcontrol={{
            isRequired,
            isDisabled,
            error: getError({ errors: methods.formState.errors, path }),
          }}
        />
      );
    }

    return (
      <InputSelect
        label={name}
        defaultValue={defaultValue}
        options={[
          { option: 'Sim', value: 'true' },
          { option: 'Não', value: 'false' },
        ]}
        {...methods.register(`${path}`)}
        formcontrol={{
          isRequired,
          isDisabled,
          error: getError({ errors: methods.formState.errors, path }),
        }}
      />
    );
  }

  if (type === 'number') {
    return (
      <InputNumber
        label={name}
        {...methods.register(`${path}`, {
          setValueAs: (v) => (v ? parseInt(v, 10) : null),
        })}
        defaultValue={defaultValue}
        formcontrol={{
          isRequired,
          isDisabled,
          error: getError({ errors: methods.formState.errors, path }),
        }}
      />
    );
  }

  if (type === 'float') {
    return (
      <InputNumber
        label={name}
        precision={3}
        step={0.001}
        {...methods.register(`${path}`, {
          setValueAs: (v) => (v ? parseFloat(v) : null),
        })}
        defaultValue={defaultValue}
        formcontrol={{
          isRequired,
          isDisabled,
          error: getError({ errors: methods.formState.errors, path }),
        }}
      />
    );
  }

  if (type === 'duration') {
    return (
      <InputDuration
        label={name}
        defaultValue={defaultValue}
        activeLabel
        {...methods.register(`${path}`)}
        formcontrol={{
          isRequired,
          isDisabled,
          error: getError({ errors: methods.formState.errors, path }),
        }}
      />
    );
  }

  return <>erro em um type</>;
}

InputFactory.defaultProps = {
  defaultValue: '',
  description: '',
  isDisabled: false,
  isRequired: false,
  multiple: false,
  values: [],
  formvalues: '',
};
