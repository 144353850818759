import React from 'react';

import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import {
  initialDataAtom,
  isEditingAtom,
} from '../../../components/rulesFlowInfo/stores';
import Info from '../../../components/rulesFlowInfo';
import GenericForm from '../../../components/base/v2/genericForm';
import CloneModal from '../../../components/rulesFlowInfo/clone';

const RulesFlowInfo: React.FC = () => {
  const [initialData] = useAtom(initialDataAtom);
  const [isEditing, setIsEditing] = useAtom(isEditingAtom);

  const { push } = useHistory();

  const handleDirectToList = () => {
    push(`/rules-flow/info/clients?id=${initialData.id}`);
  };

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Fluxo de Regras', href: '/rules_flow' },
        { label: initialData.description },
      ]}
      title={initialData.description}
      showBackButton
      backButtonUrl="/rules_flow"
      handleAction={handleEditing}
      actionText="Editar Fluxo"
      isDisabled={!isEditing}
      secondAction={handleDirectToList}
      secondActionText="Clientes"
      menuItem={<CloneModal />}
    >
      <Info />
    </GenericForm>
  );
};

export default RulesFlowInfo;
