import React from 'react';

import Node from '../nodes';
import { isEditingBlocksAtom, isEditingNodeBlocksAtom } from '../stores';
import Form from '../../rulesFlowInfo/form/base';

export default function FormFlows(): React.ReactElement {
  return (
    <Form
      isEditingAtom={isEditingBlocksAtom}
      isEditingNodeAtom={isEditingNodeBlocksAtom}
      NodeComponent={Node}
    />
  );
}
