import { atom } from 'jotai';
import { BlocksModel } from '../../../interfaces/blocks';
import { CommonRuleModel } from '../../../interfaces/common';
import { defaultFlow } from '../../rulesFlowInfo/utils';

interface RulesNames {
  label: string;
  value: string;
}

export const rulesDataAtom = atom<{
  rules: CommonRuleModel[];
  rulesNames: RulesNames[];
}>({
  rules: [],
  rulesNames: [],
});

export const initialDataBlocksAtom = atom<BlocksModel>(defaultFlow);

export const loadingBlocksAtom = atom(false);

export const disabledBlocksAtom = atom(false);

export const isEditingBlocksAtom = atom(false);

export const isEditingNodeBlocksAtom = atom(false);

export const isLoadedBlocksAtom = atom(false);

export const erroredAccordionIndexBlocksAtom = atom<number | null>(null);
