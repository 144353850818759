/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-throw-literal */
import { AxiosError } from 'axios';

import * as I from './interfaces';

import { trexApi } from '../../../services/api';
import { SETID } from '../../../services/storage';
import { handleApiRequest } from '../utils';
import checkValid from '../../../utils/check_valid';
import { GenericBondRequestType } from '../interfaces';

const usePartners = () => {
  const UUID = localStorage.getItem(SETID);
  const prefix = 'partners';

  const getPartnersList = async ({
    page,
    pageSize,
    orderBy,
    orderDirection,
    search,
  }: I.PartnersAPIsQueryType) => {
    const response = await handleApiRequest(
      trexApi.get(prefix, {
        params: {
          page: page + 1,
          'page-size': pageSize,
          'sort-by': orderBy?.field
            ? `${orderBy.field.toString()},${orderDirection}`
            : null,
          search: checkValid(search),
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const createPartner = async ({ data }: Pick<I.PartnersAPIsProps, 'data'>) => {
    const response = await handleApiRequest(
      trexApi.post(prefix, data, {
        headers: { 'X-SetID': UUID },
      })
    );

    return response;
  };

  const postBondPartnersClients = async ({
    id,
    scopeID,
    version,
  }: GenericBondRequestType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${scopeID}/clients/${id}`,
        null,
        {
          headers: {
            'If-Match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const deleteBondPartnersClients = async ({
    id,
    scopeID,
    version,
  }: GenericBondRequestType) => {
    try {
      const response = await trexApi.delete(
        `${prefix}/${scopeID}/clients/${id}`,
        {
          headers: {
            'If-Match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const editPartner = async ({ id, data, version }: I.PartnersAPIsProps) => {
    const response = await handleApiRequest(
      trexApi.patch(`${prefix}/${id}`, data, {
        headers: {
          'if-match': version,
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getOnePartner = async ({ id }: Pick<I.PartnersAPIsProps, 'id'>) => {
    const response = await handleApiRequest(
      trexApi.get(`${prefix}/${id}`, {
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  return {
    createPartner,
    editPartner,
    getPartnersList,
    postBondPartnersClients,
    deleteBondPartnersClients,
    getOnePartner,
  };
};

export default usePartners;
