import React, { useState } from 'react';
import { toast } from 'react-toastify';

import GenericForm from '../../../components/base/v2/genericForm';
import useClients from '../../../hooks/api/clients';
import {
  ClientHandleSubmitType,
  ContactModel,
} from '../../../interfaces/client';
import ClientUpsertForm from '../../../sections/client/form';

const ClientsCreate: React.FC = () => {
  const initialValues = {
    id: '',
    name: '',
    analysis: 'auto',
    document: '',
    partners: [],
    contacts: new Array<ContactModel>(),
  };
  const [client, setClient] = useState(initialValues);
  const { createClient } = useClients();

  const handleSubmit = async ({ data }: ClientHandleSubmitType) => {
    return createClient(data)
      .then((res) => {
        if ([200, 201, 204].includes(res.request.status)) {
          toast.success('Cliente criado com sucesso!');
          localStorage.setItem('client_create', res.data.private_key);
          setClient(res.data);
          return res;
        }

        const { reason } = JSON.parse(res.request.response);
        toast.warn(`Não foi possível criar o cliente ${reason}`);
        return res;
      })
      .catch(() => {
        toast.warn(`Não foi possível criar o cliente`);
      });
  };

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Cliente', href: '/client' },
        { label: 'Cadastro' },
      ]}
      title="Cadastro de Cliente"
      showBackButton
      backButtonUrl="/client"
    >
      <ClientUpsertForm
        clientData={client}
        handleSubmit={handleSubmit}
        isDisabled={false}
      />
    </GenericForm>
  );
};

export default ClientsCreate;
