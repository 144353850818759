import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { H3 } from '../../../components/base/Headings';
import InputText from '../../../components/base/v2/inputs/text';
import InputSelect from '../../../components/base/v2/inputs/select';
import { useRulesModels } from '../../../providers/models';

const Submenu = (): React.ReactElement => {
  const { isDisabled } = useRulesModels();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack direction="column">
      <H3>Informações do modelo</H3>

      <Stack direction="row" spacing="md" style={{ marginTop: '24px' }}>
        <InputText
          label="Nome"
          {...register('description')}
          formcontrol={{
            isDisabled,
            isRequired: true,
            error: errors.description,
            style: { width: '25%' },
          }}
        />

        <InputText
          label="URI"
          {...register('external_uri')}
          formcontrol={{
            isDisabled,
            isRequired: true,
            error: errors.external_uri,
            style: { width: '30%' },
          }}
        />

        <InputSelect
          label="Backend"
          {...register('backend')}
          options={[
            { option: 'knative', value: 'knative' },
            { option: 'ai-platform', value: 'ai-platform' },
          ]}
          formcontrol={{
            isDisabled,
            isRequired: true,
            error: errors.backend,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default Submenu;
