/* eslint-disable import/prefer-default-export */
import {
  InputGroup,
  Stack as StyledStack,
  Wrap,
  WrapItem as StyledWrapItem,
} from '@chakra-ui/react';
import styled from 'styled-components';
import FormControl from '../inputs/formControl';

export const ModWrapper = styled(Wrap)({
  '& ul': {
    gap: 12,
  },
});

export const ModInputGroup = styled(InputGroup)({
  width: '100%',
  '& > div': {
    width: '100%',
  },
});

export const Container = styled.div`
  .MuiPaper-root {
    box-shadow: none;
    background: none;

    > div:nth-of-type(2) {
      background: ${({ theme }) => theme.colors.squanchyGray[0]};
      border-radius: 0.625rem 0.625rem 0 0;

      table {
        th {
          color: ${({ theme }) => theme.colors.neutral[500]};
          font-weight: 600;
          text-transform: uppercase;
          padding: 0.817rem 1.25rem;
        }

        td {
          color: ${({ theme }) => theme.colors.neutral[700]};
          padding: 0.879rem 1.25rem;
        }
      }
    }
    .MuiTable-root {
      background: ${({ theme }) => theme.colors.squanchyGray[0]};
      border-radius: 0 0 0.625rem 0.625rem;

      .MuiTablePagination-root {
        display: flex;
        position: relative;
        justify-content: center;
        padding: 0.5rem 1.563rem !important;

        .MuiTablePagination-spacer {
          display: none;
        }

        .MuiTablePagination-caption {
          display: block;
        }

        .MuiToolbar-root > p:nth-of-type(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .MuiToolbar-root > :last-child {
          margin-left: auto;
        }
      }

      .MuiTableRow-root .MuiInputBase-root select,
      .MuiTableRow-root .MuiInputBase-root .MuiSelect-root {
        box-sizing: border-box;
        min-width: ${({ theme }) => theme.sizes['bigger-plus']};
        width: ${({ theme }) => theme.sizes['bigger-plus']};
        padding: 0.375rem 1.25rem 0.375rem 0;
        background: ${({ theme }) => theme.colors.neutral[200]};
        border: none;
        margin-left: ${({ theme }) => theme.sizes.smallest};
      }
    }

    .MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

export const Stack = styled(StyledStack).attrs(() => ({
  direction: 'row',
  spacing: 'smaller',
}))`
  width: 100%;
  gap: 12px;
`;

export const WrapItem = styled(StyledWrapItem)`
  flex-grow: 1;
`;

export const WrapButton = styled(StyledWrapItem)`
  flex-grow: 1;
  gap: 12px;
`;

export const FilterContainer = styled(StyledStack).attrs(() => ({
  spacing: 'smaller',
  background: 'white',
}))`
  display: flex;
  flex-direction: row;
  flex-wrap: 'wrap';
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledFormControl = styled(FormControl)`
  width: 100% !important;
`;
