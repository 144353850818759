import { isEditingBlocksAtom, rulesDataAtom } from '../stores';
import * as I from '../../rulesFlowInfo/terms/interfaces';
import TermsBase from '../../rulesFlowInfo/terms/base';

export default function TermsBlocks(props: I.TermsProps) {
  return (
    <TermsBase
      {...props}
      isEditingAtom={isEditingBlocksAtom}
      rulesDataAtom={rulesDataAtom}
    />
  );
}
