import React, { useEffect, useState } from 'react';
import { Tooltip, Box } from '@chakra-ui/react';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useOrder } from '../../providers/order';
import Badge from '../badge';
import Stat from '../stat';
import AnalysisDetail from './analysisDetail';
import { checkFullDate, checkString } from '../../utils/check_values';
import { TimeDisplaySLA } from '../../utils/format_date';
import handleSLA from '../../utils/sla';
import handleDisplayDelayedTime from '../../utils/delayedTime';
import { getStatusDetails } from '../../utils/handle_status';
import { handleScore } from './utils';

import * as S from './styles';

export const SubMenu = (): React.ReactElement => {
  const [loading, setLoading] = useState(true);
  const { orderContext } = useOrder();

  const hasChargeback =
    orderContext &&
    orderContext.order.payments.filter((item) => item.status === 'chargeback')
      .length >= 1;

  useEffect(() => {
    if (orderContext) {
      setLoading(false);
    }
  }, [orderContext]);

  if (loading) {
    return <S.Container data-testid="loading" />;
  }

  const status = getStatusDetails({
    status: orderContext?.status ?? 'default-status',
    recommendation: orderContext?.recommendation ?? 'default-recommendation',
    reviewDetail: orderContext?.review_detail?.state ?? 'default-review',
    suspensionDetail: orderContext?.suspension_detail?.state,
  });

  return (
    <S.Container data-testid="submenu_container">
      <Stat
        title="Pedido"
        fontWeight={700}
        text={orderContext?.client_name}
        fontSize="larger"
        identifier="client_name"
        subtitle={`ID: ${orderContext?.client_id?.substring(
          0,
          6
        )}...${orderContext?.client_id?.substring(
          orderContext?.client_id?.length - 6,
          orderContext?.client_id?.length
        )}`}
        copy={orderContext?.client_id}
      />

      {orderContext?.id && (
        <Stat
          title="ID"
          fontWeight={700}
          text={`${orderContext.id.substring(
            0,
            6
          )}...${orderContext.id.substring(
            orderContext.id.length - 6,
            orderContext.id.length
          )}`}
          fontSize="larger"
          identifier="order_id"
          copy={orderContext.id}
        />
      )}

      <Stat
        title="Compra"
        fontWeight={700}
        text={checkString(orderContext?.order?.['payments_sum_amount@display'])}
        fontSize="larger"
        subtitle={checkFullDate(orderContext?.order?.date)}
        identifier="order_buy_info"
        copy={checkString(orderContext?.order?.['payments_sum_amount@display'])}
      />

      {orderContext?.review_detail?.seconds_until_sla && (
        <Stat title="SLA" identifier="sla">
          <Badge
            label={TimeDisplaySLA(orderContext.review_detail.seconds_until_sla)}
            schema={handleSLA(orderContext.review_detail.seconds_until_sla)}
            fontSize="large"
            icon={RiErrorWarningLine}
            transparent
          />
        </Stat>
      )}

      <Stat
        title="Referência do Pedido"
        fontWeight={700}
        text={`${checkString(
          orderContext?.order?.order_reference?.slice(0, 8)
        )}...`}
        fontSize="larger"
        identifier="order_reference"
        copy={checkString(orderContext?.order?.order_reference)}
      />

      <Stat
        title="Score"
        identifier="score"
        subtitle={checkString(orderContext?.analysis_detail?.flow?.node?.name)}
      >
        <Badge
          label={
            orderContext?.score
              ? `${(orderContext.score * 100).toFixed(1)}%`
              : 'Não Informado'
          }
          schema={
            orderContext?.score ? handleScore(orderContext.score) : 'gray'
          }
          fontSize="large"
          icon={RiErrorWarningLine}
          information={
            orderContext?.analysis_detail ? (
              <>
                <AnalysisDetail
                  flow={orderContext?.analysis_detail.flow}
                  model={orderContext?.analysis_detail.model}
                />
              </>
            ) : (
              ''
            )
          }
          transparent
        />
      </Stat>

      {/* Status do pagamento */}
      {hasChargeback && (
        <Stat title="Gerou" identifier="statusPag">
          <Box flexDirection="row">
            <Badge label="Chargeback" schema="red" fontSize="large" />
          </Box>
        </Stat>
      )}

      <Stat title="Status do Pedido" identifier="status">
        <Tooltip label={handleDisplayDelayedTime(orderContext?.review_detail)}>
          <Badge
            label={status.label}
            color={status.color}
            background={status.background}
            icon={status.icon}
            fontSize="large"
          />
        </Tooltip>
      </Stat>
    </S.Container>
  );
};

export default SubMenu;
