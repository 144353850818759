import React from 'react';
import { useAtom } from 'jotai';
import { Stack } from '@chakra-ui/react';

import { initialDataBlocksAtom } from '../stores';
import Breadcrumb from '../../base/v2/breadcrumb';

export default function Header(): React.ReactElement {
  const [initialData] = useAtom(initialDataBlocksAtom);

  const breadcrumbText = initialData.id
    ? initialData.description
    : 'Novo bloco';

  return (
    <Stack spacing="medium" pt="extra-large">
      <Breadcrumb
        items={[
          { label: 'Início', href: '/' },
          { label: 'Blocos', href: '/blocks' },
          { label: breadcrumbText },
        ]}
      />
    </Stack>
  );
}
