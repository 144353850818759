import React from 'react';
import { Grid, GridItem, Stack } from '@chakra-ui/react';
import { formatDate } from '../../../../utils/format_date';
import { documentMask } from '../../../../utils/mask';
import Stat from '../../../stat';
import Badge from '../../../badge';

import * as I from '../interfaces';

const InformationBase = ({
  dataTestId,
  identifierPrefix,
  name,
  partner,
}: I.InformationBaseProps): React.ReactElement => {
  return (
    <Stack
      data-testid={dataTestId}
      spacing="medium"
      background="white"
      direction="row"
      p="medium"
      mb={3}
      borderRadius="extra-large"
    >
      <Grid templateColumns="repeat(6, 1fr)" gap="medium" width="100%">
        <GridItem colSpan={1}>
          <Stat
            title={name}
            text={partner.name}
            identifier={`${identifierPrefix}_name`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <Stat
            title="Documento"
            text={documentMask(partner.document)}
            identifier={`${identifierPrefix}_document`}
          />
        </GridItem>

        {partner.site ? (
          <GridItem colSpan={1}>
            <Stat
              title="Site"
              text={partner.site}
              identifier={`${identifierPrefix}_site`}
            />
          </GridItem>
        ) : null}

        <GridItem colSpan={1}>
          <Stat
            title="Última modificação"
            text={formatDate(partner.modified_at)}
            identifier={`${identifierPrefix}_modified-at`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <Stat
            title="Modificado por"
            text={partner.modified_by}
            identifier={`${identifierPrefix}_modified-by`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <Stat title="Status" identifier={`${identifierPrefix}_status`}>
            <Badge
              label={partner.status === 'enabled' ? 'Ativo' : 'Inativo'}
              schema={partner.status === 'enabled' ? 'green' : 'red'}
              transparent
            />
          </Stat>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default InformationBase;
