import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Button, Icon, Stack } from '@chakra-ui/react';
import { CgAddR } from 'react-icons/cg';
import { useRulesModels } from '../../../providers/models';
import { getStyle } from './utils';
import Card from './card';

const CardsGroup = (): React.ReactElement => {
  const { isDisabled } = useRulesModels();
  const methods = useFormContext();

  const {
    fields: features,
    append: appendFeature,
    remove: removeFeature,
    move: moveFeature,
  } = useFieldArray({
    control: methods.control,
    name: 'features',
  });

  const handleOnDragEnd = ({ source, destination }: DropResult): void => {
    if (destination) {
      moveFeature(source.index, destination.index);
    }
  };

  const handleAppendFeature = (): void => {
    appendFeature({
      name: '',
    });
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="flow-dnd-droppable">
        {(dropProvided): React.ReactElement => (
          <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
            <Stack pb="regular" spacing="smallest">
              {features.map((feature, index) => {
                const key = `node_${feature.id}-${index}`;

                return (
                  <Draggable
                    key={key}
                    draggableId={`model-draggable-${feature.id}`}
                    index={index}
                    isDragDisabled={isDisabled}
                  >
                    {(dragProvider, snapshot): React.ReactElement => (
                      <div
                        key={`model-dnd-card-${feature.id}`}
                        ref={dragProvider.innerRef}
                        style={getStyle(
                          snapshot,
                          dragProvider.draggableProps.style
                        )}
                        {...dragProvider.draggableProps}
                      >
                        <Card
                          appendFeature={appendFeature}
                          dragProvider={dragProvider}
                          removeFeature={removeFeature}
                          moveFeature={moveFeature}
                          featureIndex={index}
                          featuresLength={features.length}
                          features={features}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}

              {!isDisabled ? (
                <Stack
                  direction="row"
                  background="white"
                  borderRadius="extra-large"
                  p="medium"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    leftIcon={<Icon as={CgAddR} />}
                    onClick={handleAppendFeature}
                    isDisabled={isDisabled}
                  >
                    Adicionar feature
                  </Button>
                </Stack>
              ) : null}
            </Stack>

            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CardsGroup;
