import React, { useEffect, useState } from 'react';
import { Icon } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { TbCirclePlus } from 'react-icons/tb';
import { useKeycloak } from '@react-keycloak/web';
import { toast } from 'react-toastify';
import useTag from '../../../hooks/api/tags';
import Card from '../../../components/base/v2/card';
import InputText from '../../../components/base/v2/inputs/text';
import { handleFormToTagsModel, scopeOptions } from '../utils';
import { Role } from '../../../components/base/menu/interfaces';
import checkMenuRoles from '../../../utils/check_menu_roles';
import InputSearchSelect from '../../../components/base/v2/inputs/searchselect';

import * as S from '../styles';
import * as I from '../interfaces';

const CreateTagsForm = ({
  reloadTable,
}: I.CreateTagProps): React.ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [roles, setRoles] = useState<Role[]>();

  const { keycloak } = useKeycloak();
  const methods = useForm();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty, isValid },
    control,
  } = methods;
  const { createTags } = useTag();

  useEffect(() => {
    if (keycloak.authenticated) {
      setRoles(checkMenuRoles(keycloak));
    }
  }, [keycloak, keycloak.authenticated]);

  const isNameEmpty = !watch('name');

  const onSubmit = async (data: I.TagFormType) => {
    setIsSubmitting(true);

    await createTags(handleFormToTagsModel(data))
      .then((res) => {
        if ([200, 201, 204].includes(res.request.status)) {
          if (data.scopes.includes('client')) {
            reloadTable.client();
          }
          if (data.scopes.includes('group')) {
            reloadTable.group();
          }
          if (data.scopes.includes('flow')) {
            reloadTable.flow();
          }
          if (data.scopes.includes('model')) {
            reloadTable.model();
          }

          toast.success('Tag criada com sucesso!');

          setValue('name', '');
          setValue('scopes', []);
          setIsSubmitting(false);

          return;
        }

        const { reason } = JSON.parse(res.request.response);
        toast.error(`Não foi possível criar a tag: ${reason}`);
      })
      .catch(() => {
        toast.error('Não foi possível criar a tag.');
      });
    setIsSubmitting(false);
  };

  const hasPermission = roles?.includes('create_tags');

  if (!hasPermission) {
    return <></>;
  }

  return (
    <Card
      icon={TbCirclePlus}
      title="Criar nova tag"
      data-testid="create-tag-container"
    >
      <FormProvider {...methods}>
        <S.StyledForm onSubmit={handleSubmit(onSubmit)}>
          <InputSearchSelect
            control={control}
            name="scopes"
            label="Selecione o contexto"
            initialOptions={scopeOptions}
            formcontrol={{
              style: { width: '45%', marginRight: '2%' },
              isRequired: true,
            }}
            width="100%"
            isMulti
          />
          <InputText
            label="Escreva o nome da tag"
            {...register(`name`)}
            formcontrol={{
              style: { width: '45%', marginRight: '2%' },
              isRequired: true,
            }}
          />

          <S.StyledButton
            type="submit"
            disabled={!isDirty || !isValid || isNameEmpty || isSubmitting}
            isLoading={isSubmitting}
          >
            <Icon as={TbCirclePlus} mr="8px" />
            Criar Nova Tag
          </S.StyledButton>
        </S.StyledForm>
      </FormProvider>
    </Card>
  );
};

export default CreateTagsForm;
