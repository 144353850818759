import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import * as S from '../styles';
import * as I from '../interface';

const CommentsForm = ({
  onSubmit,
}: I.CommentsFormProps): React.ReactElement => {
  const { register, handleSubmit } = useForm();

  return (
    <S.FormContainer
      onSubmit={handleSubmit(onSubmit)}
      data-testid="comment-form-container"
    >
      <Text fontWeight={700} color="#032E1B">
        Adicionar Novo Comentário
      </Text>
      <S.TextArea
        rows={5}
        placeholder="Fale sobre seu pedido aqui."
        data-testid="form-text-area"
        {...register('message', { required: true })}
      />
      <S.ButtonContainer>
        <Button type="submit" data-testid="submit-button">
          <Text>Publicar comentário</Text>
        </Button>
      </S.ButtonContainer>
    </S.FormContainer>
  );
};

export default CommentsForm;
