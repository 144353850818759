import React, { useEffect, useState } from 'react';
import { Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import * as S from './styles';

import LighthouseMetrics from '../../components/metrics/lighthouse';
import WebVitalsMetrics from '../../components/metrics/webvitals';

import { LighthouseMetricsProps } from '../../components/metrics/lighthouse/interfaces';
import { WebVitalsMetricsData } from '../../components/metrics/webvitals/interfaces';

const SystemAnalyzer: React.FC = () => {
  const [webVitalsMetrics, setWebVitalsMetrics] =
    useState<WebVitalsMetricsData | null>(null);
  const [lighthouseMetrics, setLighthouseMetrics] =
    useState<LighthouseMetricsProps | null>(null);

  useEffect(() => {
    fetch('/metrics/web-vitals-results.json')
      .then((response) => response.json())
      .then((data) => {
        setWebVitalsMetrics(data);
      })
      .catch((error) => {
        toast.warn('Erro ao buscar web-vitals-results:', error);
      });

    fetch('/metrics/lighthouse-results.json')
      .then((response) => response.json())
      .then((data) => {
        const result = {
          metrics: {
            performance: data.categories.performance,
            accessibility: data.categories.accessibility,
            bestPractices: data.categories['best-practices'],
            seo: data.categories.seo,
          },
          audits: data.audits,
          url: data.finalDisplayedUrl,
        };
        setLighthouseMetrics(result);
      })
      .catch((error) => {
        toast.warn('Erro ao buscar lighthouse-results:', error);
      });
  }, []);

  return (
    <S.Main>
      <Heading as="h1" marginTop="extra-large" fontSize="extra-large">
        Métricas do sistema
      </Heading>

      <Text as="span">
        Tiradas da pagina: proprio_wix BF 2022 (ID: 1d02cb...)
      </Text>
      <Grid templateColumns="repeat(3, 1fr)" gap="medium">
        {lighthouseMetrics && (
          <GridItem w="100%">
            <LighthouseMetrics
              audits={lighthouseMetrics.audits}
              metrics={lighthouseMetrics.metrics}
            />
          </GridItem>
        )}
        {webVitalsMetrics && (
          <GridItem w="100%">
            <WebVitalsMetrics metrics={webVitalsMetrics} />
          </GridItem>
        )}
      </Grid>
    </S.Main>
  );
};

export default SystemAnalyzer;
