import styled from 'styled-components';
import { Grid, TabPanel as ChakraTabPanel } from '@chakra-ui/react';
import { styledTheme, theme } from '../../../../styles/theme';

export const Card = styled.div`
  width: 100%;
  min-height: ${styledTheme.spacing(10)}px;
  border-radius: ${styledTheme.spacing(2)}px;
  word-break: break-word;
  background-color: ${theme.colors.v2.white[100]};
`;

export const GridContainer = styled(Grid).attrs(() => ({
  templateColumns: 'repeat(5, 1fr)',
  gap: 3,
}))`
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  word-break: break-word;
`;

export const CreditCardContainer = styled(Grid).attrs(() => ({
  bg: theme.colors.v2.green[300],
  templateColumns: 'repeat(5, 1fr)',
  gap: 3,
}))`
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  height: 100%;
  border-radius: ${styledTheme.spacing(2)}px;
  padding: ${styledTheme.spacing(2)}px;
  word-break: break-word;
`;

export const CreditCardInfo = styled(Grid).attrs(() => ({
  templateColumns: 'repeat(4, 1fr)',
  gap: 3,
}))`
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  height: 100%;
  padding: ${styledTheme.spacing(2)}px;
  word-break: break-word;
`;

export const TabPanel = styled(ChakraTabPanel)`
  padding: 0 !important;
`;
