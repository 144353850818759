import styled from 'styled-components';
import { Grid } from '@chakra-ui/react';

export const GridContainer = styled(Grid).attrs(() => ({
  templateColumns: 'repeat(5, 1fr)',
  gap: 3,
}))`
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  word-break: break-word;
`;

export const Container = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
