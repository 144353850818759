/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable import/prefer-default-export */
import * as I from './interfaces';

export const handleScore = (score: number) => {
  const thresholds = [
    { limit: 0.33, color: 'blue' },
    { limit: 0.66, color: 'orange' },
    { limit: Infinity, color: 'red' },
  ] as I.ThresholdsType[];

  const { color } = thresholds.find(({ limit }) => score < limit)!;

  return color;
};
