import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { AxiosRequestConfig } from 'axios';
import { trexApi, trexApiV2 } from '.';

export default function Interceptor(): React.ReactElement {
  const { keycloak, initialized } = useKeycloak();

  const updateHeaders = (
    request: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    return keycloak
      .updateToken(300)
      .then(() => {
        request.headers.Authorization = `Bearer ${keycloak.token}`;
        request.headers['X-SessionID'] = keycloak.sessionId;
        return keycloak.loadUserProfile();
      })
      .then((response) => {
        request.headers['X-Email'] = response.email;
        return request;
      });
  };

  const requestInterceptor = () => {
    trexApi.interceptors.request.use(updateHeaders);
    trexApiV2.interceptors.request.use(updateHeaders);
  };

  const responseInterceptor = () => {
    trexApi.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          if (!keycloak.authenticated) {
            keycloak.logout();
          }
        }
        return error;
      }
    );

    trexApiV2.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          if (!keycloak.authenticated) {
            keycloak.logout();
          }
        }
        return error;
      }
    );
  };

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      requestInterceptor();
      responseInterceptor();
    }
  }, [keycloak, keycloak.onTokenExpired]);

  return <></>;
}
