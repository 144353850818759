/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-throw-literal */
import { AxiosError } from 'axios';
import { trexApi } from '../../../services/api';
import { SETID } from '../../../services/storage';
import * as I from '../interfaces';

const useAnalysis = () => {
  const UUID = localStorage.getItem(SETID);
  const prefix = 'analysis';

  const getAllAnalysis = async ({ id, query }: I.GetAllAnalysisType) => {
    try {
      const response = await trexApi.get(`${prefix}/${id}/cross-reference`, {
        params: {
          page: query.page,
          'page-size': query.pageSize,
          'sort-by': query.orderBy?.field
            ? `${query.orderBy.field},${query.orderDirection}`
            : null,
          search: query.search || null,
        },
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const updateOrderReviewStatus = async ({
    id,
    data,
    version,
  }: I.UpdateOrderReviewStatusType) => {
    try {
      const response = await trexApi.post(`${prefix}/${id}/reviews`, data, {
        headers: {
          'If-Match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const updateOrderSuspensionStatus = async ({
    id,
    data,
    version,
  }: I.UpdateOrderSuspensionStatusType) => {
    try {
      const response = await trexApi.post(`${prefix}/${id}/resolve`, data, {
        headers: {
          'If-Match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const postRetentionReview = async ({ id, data, version }: I.PostHeldType) => {
    try {
      const response = await trexApi.post(
        `${prefix}/${id}/resolve-withhold`,
        data,
        {
          headers: {
            'If-Match': version,
            'X-SetID': UUID,
          },
        }
      );

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const postAnalysisComments = async ({
    id,
    data,
    version,
  }: I.PostAnalysisCommentsType) => {
    try {
      const response = await trexApi.post(`${prefix}/${id}/comments`, data, {
        headers: {
          'If-Match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  return {
    getAllAnalysis,
    postAnalysisComments,
    updateOrderReviewStatus,
    updateOrderSuspensionStatus,
    postRetentionReview,
  };
};

export default useAnalysis;
