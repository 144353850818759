import axios from 'axios';

export const trexApi = axios.create({
  baseURL: process.env.REACT_APP_TREX_API,
  headers: {
    'content-type': 'application/json',
  },
});

export const trexApiV2 = axios.create({
  baseURL: process.env.REACT_APP_TREX_API_V2,
  headers: {
    'content-type': 'application/json',
  },
});
