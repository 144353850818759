/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  min-height: 100vh;
  padding-right: 2.5rem;
  padding-left: 1.875rem;
  position: relative;
  padding-top: ${({ theme }) => theme.spacing(6.5)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
