/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Grid } from '@chakra-ui/react';

export const GridContainer = styled(Grid).attrs(() => ({
  templateColumns: 'repeat(4, 1fr)',
  gap: 3,
  paddingBottom: 'regular',
}))`
  grid-auto-rows: minmax(0px, auto);
  width: 100%;
  height: 100%;
`;
