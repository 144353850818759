import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
  border-radius: 12px;
`;

export const CardHeader = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.v2.gray[100]};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardBody = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const TitleText = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.v2.gray[200]};
`;
