/* eslint-disable react-hooks/rules-of-hooks */
import { atomWithReset, useResetAtom } from 'jotai/utils';
import * as I from './interfaces';

export const filterByAtom = atomWithReset('');
export const searchTextAtom = atomWithReset('');
export const filtersAtom = atomWithReset<I.FilterAtom>({});

export const cleanFilters = () => {
  const resetsearchText = useResetAtom(searchTextAtom);
  const resetfilterBy = useResetAtom(filterByAtom);
  const resetfilters = useResetAtom(filtersAtom);

  return {
    resetsearchText,
    resetfilterBy,
    resetfilters,
  };
};
