import React from 'react';
import { NavLink } from 'react-router-dom';
import { Column, EditComponentProps, Options } from '@material-table/core';
import { Tooltip, Icon } from '@chakra-ui/react';
import { BiLinkAlt, BiUnlink } from 'react-icons/bi';

import { ClientModel } from '../../../../interfaces/client';
import CreateIcon from '../../../../components/icons/create';
import AutocompleteActions from '../../../../components/base/autocomplete_actions';
import { ActionsListModel } from '../../../../interfaces/actions_list';

export const tableColumns: Column<ClientModel>[] = [
  {
    title: 'Nome',
    field: 'name',
    width: 300,
    render: (rowData: ClientModel): React.ReactNode => {
      return (
        <Tooltip label="Visualizar" placement="bottom">
          <NavLink to={`/actions-list/${rowData.id}`}>
            <strong>
              {rowData.name}{' '}
              <span style={{ color: '#f05d5e' }}>
                (ID: {rowData.id?.substring(0, 6)}...)
              </span>
            </strong>
          </NavLink>
        </Tooltip>
      );
    },
    editComponent: (editProps): React.ReactElement => (
      <div className="default--input">
        <AutocompleteActions
          action={(e: object): void => editProps.onChange(e)}
        />
      </div>
    ),
  },
];

export const nameAndIDColumn = (pathname: string) => {
  return {
    title: 'Nome',
    field: 'name',
    cellStyle: { minWidth: 220 },
    render: (rowData: { id: string; name: string }): React.ReactNode => {
      return (
        <Tooltip
          title="Visualizar"
          placement="bottom"
          style={{ width: 'fit-content' }}
        >
          <NavLink
            to={{
              pathname,
              search: `id=${rowData.id}`,
            }}
          >
            {rowData.name}
          </NavLink>
        </Tooltip>
      );
    },
    editComponent: (
      editProps: EditComponentProps<ActionsListModel>
    ): React.ReactElement => (
      <div className="default--input">
        <AutocompleteActions
          action={(e: object): void => {
            editProps.onChange(e);
          }}
        />
      </div>
    ),
  };
};

export const clientOptions: Options<ClientModel> = {
  search: true,
  selection: false,
  addRowPosition: 'first',
  pageSizeOptions: [10, 25, 50],
  pageSize: 10,
  emptyRowsWhenPaging: false,
  debounceInterval: 500,
  actionsColumnIndex: -1,
  actionsCellStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '75px',
    padding: '8px 15px',
  },
};

export const clientIcons = {
  Add: (): React.ReactElement => (
    <CreateIcon img={<Icon as={BiLinkAlt} />} text="Vincular nova lista" />
  ),
  Delete: (): React.ReactElement => <Icon as={BiUnlink} />,
};

export const clientLocalization = {
  pagination: {
    labelDisplayedRows: '{from} - {to} de {count}',
    labelRowsSelect: 'Linhas',
    firstAriaLabel: 'Primeira página',
    firstTooltip: 'Primeira página',
    nextAriaLabel: 'Próxima página',
    nextTooltip: 'Próxima página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    lastAriaLabel: 'Ultima página',
    lastTooltip: 'Ultima página',
  },
  toolbar: {
    nRowsSelected: '{0} linhas selecionadas',
    exportName: 'Exportar para CSV',
    exportTitle: 'Exportar',
    searchPlaceholder: 'Digite aqui',
  },
  header: {
    actions: ' ',
  },
  body: {
    emptyDataSourceMessage: 'Nenhum registro encontrado',
    filterRow: {
      filterTooltip: 'Filter',
    },
    editRow: {
      deleteText: 'Tem certeza que deseja remover esse vínculo?',
      saveTooltip: 'Sim',
      cancelTooltip: 'Cancelar',
    },
    editTooltip: 'Editar',
    deleteTooltip: 'Desvincular Cliente',
    addTooltip: 'Vincular Cliente',
  },
};
