import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import { Icon, MenuItem, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { FaRegClone } from 'react-icons/fa';

import { initialDataAtom } from '../stores';
import useRulesFlows from '../../../hooks/api/rules_flows';
import { useToastFunctions } from '../../../utils/useToastFunction';
import DoubleCheckModal from '../../base/v2/DoubleCheckModal';
import InputText from '../../base/v2/inputs/text';
import InputSelect from '../../base/v2/inputs/select';

export default function CloneModal(): React.ReactElement {
  const { push } = useHistory();
  const { createFlow } = useRulesFlows();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { showToast } = useToastFunctions();
  const [initialData] = useAtom(initialDataAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [cloneData, setCloneData] = useState({
    description: '',
    allowReview: false,
  });

  const onClone = async (): Promise<void> => {
    setIsLoading(true);
    const sendData = {
      allow_review: cloneData.allowReview,
      description: cloneData.description,
      nodes: initialData.nodes,
    };

    await createFlow(sendData)
      .then((res) => {
        if (res) {
          if ([200, 201, 204].includes(res.request.status)) {
            showToast({
              title: 'Fluxo clonado',
              status: 'success',
            });
            setIsLoading(false);
            onClose();
            push(`/rules-flow/info?id=${res.data.id}`);
            return;
          }
          const { reason } = JSON.parse(res.request.response);
          onClose();
          showToast({
            title: 'Erro ao clonar fluxo',
            description: `Não foi possível clonar o fluxo: ${reason}`,
            status: 'error',
          });
          setIsLoading(false);
        }
      })
      .catch(() => {
        onClose();
        showToast({
          title: 'Erro ao clonar fluxo',
          description:
            'Não foi possível clonar o fluxo. Tente novamente mais tarde.',
          status: 'error',
        });
        setIsLoading(false);
      });
  };

  const handleDescription = (e: React.ChangeEvent<{ value: string }>) => {
    setCloneData({ ...cloneData, description: e.target.value });
  };

  const handleAllowReview = (e: React.ChangeEvent<{ value: string }>) => {
    setCloneData({
      ...cloneData,
      allowReview: e.target.value === 'true',
    });
  };

  return (
    <>
      <MenuItem icon={<Icon as={FaRegClone} />} onClick={onOpen}>
        Clonar fluxo
      </MenuItem>

      <DoubleCheckModal
        title="Clonar fluxo existente"
        description={`Ao clonar o fluxo ${initialData.description}, é importante lembrar que quaisquer alterações não salvas não serão incluídas na cópia.`}
        isOpen={isOpen}
        onClose={onClose}
        modal={{ size: 'xl' }}
        primaryButton={{
          colorScheme: 'green',
          text: 'Clonar fluxo',
          action: onClone,
        }}
        isLoading={isLoading}
      >
        <Stack
          spacing="medium"
          background="white"
          p="medium"
          borderRadius="extra-large"
        >
          <Text fontWeight="bold">Informações do fluxo</Text>
          <Stack direction="row" spacing="medium">
            <InputText
              label="Fluxo"
              value={cloneData.description}
              onChange={handleDescription}
              formcontrol={{}}
            />

            <InputSelect
              label="Permitir revisão"
              options={[
                { option: 'Sim', value: 'true' },
                { option: 'Não', value: 'false' },
              ]}
              value={cloneData.allowReview.toString()}
              onChange={handleAllowReview}
              formcontrol={{}}
            />
          </Stack>
        </Stack>
      </DoubleCheckModal>
    </>
  );
}
