import React from 'react';

import * as S from '../styles';
import * as I from '../interface';
import { HintTreeNodesModel } from '../../../../boxReview/interface';

const ItemRow = ({ data, color }: I.ItemRowType): React.ReactElement => {
  const handleNodesOptions = () => {
    return data.nodes
      .map((item: HintTreeNodesModel) => {
        return item.name;
      })
      .join(', ');
  };

  return (
    <S.Row>
      <S.ItemText color={color}>
        <b>{data.name}: </b>
        {handleNodesOptions()}
      </S.ItemText>
    </S.Row>
  );
};

export default ItemRow;
