import { TabPanel as ChakraTabPanel } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  border-top: 2px solid ${({ theme }) => theme.colors.v2.dark[100]};
  padding: ${({ theme }) => theme.spacing(3)}px
    ${({ theme }) => theme.spacing(6)}px;
`;

export const TabPanel = styled(ChakraTabPanel)`
  min-height: 40vh;
  padding: 0 !important;
`;
