import React, { useEffect, useState } from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';
import { Button, GridItem, Text, useDisclosure } from '@chakra-ui/react';
import * as S from './styles';
import * as S2 from '../styles';
import { useOrder } from '../../../../../providers/order';
import BaseModal from '../../../../baseModal';
import LoadingSpinner from '../../../../loadingSpinner';
import PhonesRow from './phonesRow';

import * as I from '../interface';
import * as I2 from './interface';
import { sortPhonesArr } from './utils';

import ImageWithText from '../../../../base/image_with_text';
import Phonebook from '../../../../../assets/icons/phonebook.svg';

const PhonesInfo = ({ similarity }: I.SimilarityType): React.ReactElement => {
  const [loading, setLoading] = useState(true);
  const [phonesArr, setPhonesArr] = useState<I2.SortedPhonesArr[]>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { birotContext, orderContext } = useOrder();

  useEffect(() => {
    if (
      birotContext?.document?.phones &&
      birotContext?.document?.phones?.length > 0
    ) {
      const similarityArray = sortPhonesArr({
        birotContext,
        orderContext,
        similarity,
      });
      setPhonesArr(similarityArray);
    }
    setLoading(!birotContext);
  }, [birotContext, similarity]);

  const phoneslength = birotContext?.document?.phones
    ? birotContext.document.phones.length
    : 0;

  return (
    <S.Container data-testid="phone-info-container">
      <S2.Text mt="16px" mb="16px">
        Telefones de Contato ({phoneslength})
      </S2.Text>
      {loading && <LoadingSpinner />}
      {!loading &&
        (phonesArr && phonesArr.length > 0 ? (
          <>
            <S.GridContainer data-testid="grid-container">
              {phonesArr?.map((item, index) => {
                if (index < 4) {
                  const key = `birot_document_phones_${index}`;
                  return <PhonesRow key={key} identifier={index} {...item} />;
                }

                return null;
              })}
              {phoneslength > 4 && (
                <GridItem colSpan={7} justifySelf="center">
                  <Button
                    colorScheme="gray"
                    variant="ghost"
                    onClick={() => onOpen()}
                  >
                    <Text fontSize="small" style={{ fontWeight: 600 }}>
                      Mais {phoneslength - 4} telefones
                    </Text>
                    <BsChevronCompactDown style={{ marginLeft: '8px' }} />
                  </Button>
                </GridItem>
              )}
            </S.GridContainer>

            <BaseModal
              title={`Telefones de Contato (${phoneslength})`}
              titleColor="#032E1B"
              isOpen={isOpen}
              onClose={onClose}
              scrollBehavior="inside"
              size="4xl"
            >
              <S.GridContainer>
                {phonesArr.map((item, index) => {
                  const key = `birot_document_phones_${index + phoneslength}`;
                  return (
                    <PhonesRow
                      key={key}
                      identifier={index + phoneslength}
                      {...item}
                    />
                  );
                })}
              </S.GridContainer>
            </BaseModal>
          </>
        ) : (
          <ImageWithText
            title="Nenhum telefone vinculado"
            text="Não recebemos informações de telefones vinculados com o titular desse pedido."
            image={{
              src: Phonebook,
              alt: 'Nenhum telefone vinculado',
            }}
          />
        ))}
    </S.Container>
  );
};

export default PhonesInfo;
