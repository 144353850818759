/* eslint-disable import/prefer-default-export */
import { useToast, UseToastOptions } from '@chakra-ui/react';

export const useToastFunctions = () => {
  const toast = useToast();

  const showToast = ({
    title,
    description,
    status,
    position = 'top-right',
    duration = 5000,
    isClosable = true,
  }: UseToastOptions) => {
    toast({
      title,
      position,
      description,
      status,
      duration,
      isClosable,
    });
  };

  return {
    showToast,
  };
};
