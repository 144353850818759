import React from 'react';
import MaterialTable from '@material-table/core';
import * as S from './styles';
import * as I from '../base/table/interfaces';
import { baseTableOptions, baseTablePagination } from './utils';

const BaseTable = ({
  data,
  icons,
  ...rest
}: I.DefaultTableProps): React.ReactElement => {
  return (
    <S.Container data-testid="baseTable">
      <MaterialTable data={data} icons={icons} {...rest} />
    </S.Container>
  );
};

BaseTable.defaultProps = {
  title: '',
  columns: [],
  options: baseTableOptions,
  localization: {
    pagination: baseTablePagination,
  },
  actions: [],
};

export default BaseTable;
