import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router-dom';

import { Avatar, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import * as I from '../../../../interfaces/user';
import * as S from './styles';

export default function Profile(): React.ReactElement {
  const { keycloak } = useKeycloak();
  const [user, setUser] = useState<I.UserModel>();
  const [isLoaded, setIsLoaded] = useState(false);
  const { push } = useHistory();

  useEffect(() => {
    let cleanUp = true;

    if (cleanUp && keycloak.authenticated) {
      keycloak.loadUserProfile().then((response) => {
        localStorage.setItem('user', JSON.stringify(response));
        setUser(response);
        setIsLoaded(true);
      });
    }

    return () => {
      cleanUp = false;
    };
  }, [keycloak.authenticated]);

  const logout = async (): Promise<void> => {
    localStorage.clear();
    await keycloak.clearToken();
    await keycloak.logout();
  };

  return (
    <>
      {isLoaded && (
        <Menu>
          <MenuButton
            height="extra-big"
            paddingX="small"
            borderRadius="large"
            background="rgba(255, 255, 255, 0.1);"
            color="white"
            _hover={{
              background: 'rgba(255, 255, 255, 0.1);',
            }}
            _disabled={{
              opacity: 1,
            }}
          >
            <S.TextContainer>
              Olá, {user?.firstName} {user?.lastName}
              <Avatar
                name={`${user?.firstName} ${user?.lastName}`}
                h="big"
                w="big"
                marginLeft="smallest"
              />
            </S.TextContainer>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => push('/profile')}>Profile</MenuItem>
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );
}
