import React, { useEffect, useState } from 'react';
import { GridItem, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import * as S from './styles';
import * as S2 from '../styles';
import CreditCardInfo from './creditCardInfo';
import CreditCard from './creditCard';
import { useOrder } from '../../../../providers/order';
import StoreImg from '../../../../assets/icons/store.svg';
import LoadingSpinner from '../../../loadingSpinner';
import ImageWithText from '../../../base/image_with_text';
import { Tab } from '../../../tabs/styles';

const PaymentInfo = () => {
  const [loading, setLoading] = useState(true);
  const { orderContext } = useOrder();

  const image = { alt: 'store-image', src: StoreImg };

  useEffect(() => {
    if (orderContext) {
      setLoading(false);
    }
  });

  return (
    <Tabs data-testid="payment-tab-container">
      <S2.TitleContainer data-testid="payment-title-container">
        <S2.Text>Pagamento</S2.Text>
        <TabList data-testid="payment-tabList-container">
          {orderContext?.order?.payments?.map((_, index) => {
            const key = `payment_tabList_item_${index}`;
            return <Tab key={key}>Pagamento {index + 1}</Tab>;
          })}
        </TabList>
      </S2.TitleContainer>
      <S.Card>
        {loading && (
          <GridItem colSpan={5}>
            <LoadingSpinner />
          </GridItem>
        )}
        {!loading && (
          <>
            {orderContext?.order?.payments &&
            orderContext.order.payments.length > 0 ? (
              <TabPanels data-testid="payment-tabPanels-container">
                {orderContext.order.payments.map((item, index) => {
                  const paymentKey = `payment_item_${item.fingerprint}_${index}`;
                  return (
                    <S.TabPanel key={paymentKey}>
                      <S.GridContainer>
                        <GridItem colSpan={3}>
                          <CreditCard payment={item} />
                        </GridItem>
                        <GridItem colSpan={2}>
                          <CreditCardInfo payment={item} />
                        </GridItem>
                      </S.GridContainer>
                    </S.TabPanel>
                  );
                })}
              </TabPanels>
            ) : (
              <S.GridContainer>
                <GridItem
                  data-testid="purchase-container-with-no-content"
                  colSpan={5}
                  rowSpan={2}
                >
                  <ImageWithText
                    title="Nenhum carrinho informado"
                    text="Não recebemos as informações do carrinho desta compra."
                    image={image}
                  />
                </GridItem>
              </S.GridContainer>
            )}
          </>
        )}
      </S.Card>
    </Tabs>
  );
};

export default PaymentInfo;
