export const buyer = {
  type: 'buyer',
  email: 'order.buyer.email',
  document: 'order.buyer.document',
  name: 'order.buyer.name',
  full_number: 'order.buyer.phones[0].full_number',
  full_address: 'order.buyer.address.full_address',
  city: 'order.buyer.address.city',
  state: 'order.buyer.address.state',
  street: 'order.buyer.address.street',
  number: 'order.buyer.address.number',
  postal_code: 'order.buyer.address.postal_code',
};

export const payment = {
  type: 'payment',
  email: 'order.payments[0].email',
  document: 'order.payments[0].document',
  name: 'order.payments[0].holder_name',
  full_number: 'order.payments[0].phones[0].full_number',
  full_address: 'order.payments[0].address.full_address',
  city: 'order.payments[0].address.city',
  state: 'order.payments[0].address.state',
  street: 'order.payments[0].address.street',
  number: 'order.payments[0].address.number',
  postal_code: 'order.payments[0].address.postal_code',
};

export const shipping = {
  type: 'shipping',
  email: 'order.shippings[0].email',
  document: 'order.shippings[0].receiver.document',
  name: 'order.shippings[0].receiver.name',
  full_number: 'order.shippings[0].phones[0].full_number',
  full_address: 'order.shippings[0].address.full_address',
  city: 'order.shippings[0].address.city',
  state: 'order.shippings[0].address.state',
  street: 'order.shippings[0].address.street',
  number: 'order.shippings[0].address.number',
  postal_code: 'order.shippings[0].address.postal_code',
};
