import React from 'react';
import { Spinner, SpinnerProps } from '@chakra-ui/react';
import * as S from './styles';

const LoadingSpinner = ({
  thickness = '8px',
  emptyColor = 'v2.gray.50',
  color = 'v2.green.300',
  height = '4rem',
  width = '4rem',
  ...rest
}: SpinnerProps) => {
  return (
    <S.Container data-testid="loading-spinner">
      <Spinner
        {...rest}
        thickness={thickness}
        emptyColor={emptyColor}
        color={color}
        height={height}
        width={width}
      />
    </S.Container>
  );
};

export default LoadingSpinner;
