import { useHistory } from 'react-router-dom';
import * as I from './interfaces';

export default function useNavegate(): I.UseNavegateModel {
  const history = useHistory();
  const storageName = 'oldPath';

  const savePage = (): void => {
    localStorage.setItem(storageName, history.location.pathname);
  };

  const BackPage = (): void => {
    if (localStorage.getItem(storageName)) {
      const oldPath = localStorage.getItem(storageName) as string;
      return history.push(oldPath);
    }
    return history.push('/orders');
  };

  return { savePage, BackPage };
}
