/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export async function handleApiRequest<T>(apiCall: Promise<T>): Promise<T> {
  try {
    const response = await apiCall;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err;
    } else {
      throw new Error(String(err));
    }
  }
}
