import styled from 'styled-components';
import { Grid } from '@chakra-ui/react';

export const GridContainer = styled(Grid).attrs(() => ({
  templateColumns: 'repeat(4, 1fr)',
  gap: 3,
}))`
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  word-break: break-word;
  min-height: 0;
  min-width: 0;
`;

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid gray;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
