/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AiOutlineCheckCircle,
  AiOutlineClockCircle,
  AiOutlineCloseCircle,
  AiOutlineExclamationCircle,
  AiOutlineInfoCircle,
  AiOutlineLoading,
} from 'react-icons/ai';
import { ReactComponent as ReloadClockIcon } from '../../assets/icons/reloadClock.svg';
import { theme } from '../../styles/theme';

import * as I from './interfaces';
import * as I2 from '../../interfaces/status';

export const allStatus: { [key: string]: I2.StatusResponse } = {
  pending: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.orange[400],
    label: 'Pendente',
    icon: AiOutlineClockCircle,
  },
  authorized: {
    color: theme.colors.v2.green[200],
    background: theme.colors.v2.blue[100],
    label: 'Autorizado',
    icon: AiOutlineCheckCircle,
  },
  approved: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.green[200],
    label: 'Aprovado',
    icon: AiOutlineCheckCircle,
  },
  captured: {
    color: theme.colors.v2.green[200],
    background: theme.colors.v2.green[100],
    label: 'Capturado',
    icon: AiOutlineCheckCircle,
  },
  chargeback: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.blue[300],
    label: 'Chargeback',
    icon: AiOutlineExclamationCircle,
  },
  'timed-out': {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.orange[400],
    label: 'Tempo expirado',
    icon: AiOutlineExclamationCircle,
  },
  reject: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.red[400],
    label: 'Rejeitado',
    icon: AiOutlineCloseCircle,
  },
  'not-authorized': {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.red[400],
    label: 'Não Autorizado',
    icon: AiOutlineCloseCircle,
  },
  suspended: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.orange[400],
    label: 'Suspenso',
    icon: AiOutlineCloseCircle,
  },
  finished: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.blue[300],
    label: 'Finalizado',
    icon: AiOutlineInfoCircle,
  },
  withheld: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.orange[400],
    label: 'Retido',
    icon: AiOutlineCloseCircle,
  },
  'in-review': {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.dark[200],
    label: 'Em andamento',
    icon: ReloadClockIcon,
  },
  delayed: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.blue[300],
    label: 'Agendado',
    icon: AiOutlineLoading,
  },
  'not-analyzed': {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.dark[200],
    label: 'Não Analisado',
    icon: AiOutlineClockCircle,
  },
};

// TODO: Remover o any e tipar corretamente a funcao
const allPaymentsStatus: { [key: string]: I2.StatusResponse } | any = {
  authorized: {
    color: theme.colors.v2.white[100],
    label: 'Autorizado',
    background: theme.colors.v2.green[200],
  },
  captured: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.green[200],
    label: 'Capturado',
  },
  chargeback: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.red[400],
    label: 'Chargeback',
  },
  expired: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.orange[400],
    label: 'Expirado',
  },
  failed: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.orange[400],
    label: 'Falha',
  },
  'not-authorized': {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.orange[400],
    label: 'Não autorizado',
  },
  paid: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.green[200],
    label: 'Pago',
  },
  pending: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.dark[200],
    label: 'Pendente',
  },
  refunded: {
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.blue[200],
    label: 'Estornado',
  },
  default: (label: string) => ({
    color: theme.colors.v2.white[100],
    background: theme.colors.v2.dark[200],
    label,
  }),
};

export const getStatusDetails = ({
  status,
  recommendation,
  reviewDetail,
  suspensionDetail,
  isPaymentStatus,
}: I.StatusDetailsParams): I2.StatusResponse => {
  if (isPaymentStatus) {
    return allPaymentsStatus[status] || allPaymentsStatus.default(status);
  }

  if (reviewDetail === 'withheld') {
    return allStatus.withheld;
  }

  if (recommendation === 'approve') {
    return allStatus.approved;
  }

  if (recommendation === 'reject') {
    return allStatus.reject;
  }

  if (reviewDetail === 'in-review') {
    return allStatus['in-review'];
  }

  if (reviewDetail === 'delayed') {
    return allStatus.delayed;
  }

  if (status === 'not-analyzed') {
    return allStatus['not-analyzed'];
  }

  if (suspensionDetail === 'suspended') {
    return allStatus.suspended;
  }

  return allStatus[status] || allStatus.approved;
};
