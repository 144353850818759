import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { BiUserCircle } from 'react-icons/bi';

import * as I from '../interface';

import { useOrder } from '../../../../../providers/order';
import LoadingSpinner from '../../../../loadingSpinner';
import ImageWithText from '../../../../base/image_with_text';

import * as S from '../styles';
import EmailsInfoGrid from './emailsGrid';

export default function EmailsInfo({
  similarity,
}: I.SimilarityType): React.ReactElement {
  const [isLoaded, setIsLoaded] = useState(false);
  const { birotContext } = useOrder();

  useEffect(() => {
    setIsLoaded(Boolean(birotContext));
  }, [birotContext]);

  return (
    <Box backgroundColor="white">
      <Stack spacing="regular" borderBottom="1px solid gray">
        <S.Text>E-mail</S.Text>
        <Stack>
          {!isLoaded && <LoadingSpinner />}
          {isLoaded && (
            <>
              {birotContext?.email ? (
                <EmailsInfoGrid
                  similarity={similarity}
                  birotEmail={birotContext.email}
                />
              ) : (
                <ImageWithText
                  title="Nenhum dado de E-mail informado"
                  text="Não recebemos as informações dos dados de e-mails desta compra."
                  icon={BiUserCircle}
                />
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
