import React from 'react';
import { Icon } from '@chakra-ui/react';
import * as S from './styles';
import * as I from './interface';

const Card = ({ icon, title, children }: I.CardProps) => {
  return (
    <S.Container>
      <S.CardHeader>
        <Icon as={icon} color="#303742" fontSize="28px" mr="8px" />
        <S.TitleText>{title}</S.TitleText>
      </S.CardHeader>
      <S.CardBody>{children}</S.CardBody>
    </S.Container>
  );
};

export default Card;
