import React, { useEffect, useState } from 'react';

import { GridItem, Stack } from '@chakra-ui/react';
import IdDocument from '../../../../../assets/icons/idDocument.svg';
import { checkDate, checkString } from '../../../../../utils/check_values';

import * as I from '../interface';

import { useOrder } from '../../../../../providers/order';
import fetchFromObject from '../../../../../utils/fetchFromObject';
import jaro from '../../../../../utils/jaro';

import LoadingSpinner from '../../../../loadingSpinner';
import ImageWithText from '../../../../base/image_with_text';
import Stat from '../../../../stat';
import BondsText from '../../../../bonds';

import * as S from './styles';
import * as S2 from '../styles';
import { sortPersonalInfoEmailsArr } from './utils';
import * as I2 from './interface';
import IconButton from '../../../../icons/iconButton';
import { theme } from '../../../../../styles/theme';
import useGenericCallback from '../../../../../utils/callbacks';

export default function PersonalData({
  similarity,
}: I.SimilarityType): React.ReactElement {
  const [isLoaded, setIsLoaded] = useState(false);
  const [emailsArr, setEmailsArr] = useState<I2.SortedOrderBirotEmail[]>();
  const { birotContext, orderContext } = useOrder();
  const { handleCopyButtonClick } = useGenericCallback();

  useEffect(() => {
    if (
      birotContext?.document?.emails &&
      birotContext?.document?.emails?.length > 0
    ) {
      const sortedEmailsArr = sortPersonalInfoEmailsArr({
        birotContext,
        orderContext,
        similarity,
      });
      setEmailsArr(sortedEmailsArr);
    }
    setIsLoaded(Boolean(birotContext));
  }, [birotContext, similarity]);

  return (
    <S.Container data-testid="networking-info-container">
      <S2.Text mb="16px">Dados pessoais</S2.Text>
      {!isLoaded && <LoadingSpinner />}
      {isLoaded && (
        <>
          {birotContext?.document ? (
            <S.GridContainer>
              <GridItem colSpan={2}>
                <Stat
                  title="Nome"
                  text=""
                  identifier="bureau_personal_data_name"
                  copy={checkString(birotContext.document.name)}
                >
                  <BondsText
                    text={checkString(birotContext.document.name)}
                    similarity={jaro(
                      birotContext.document.name,
                      fetchFromObject(orderContext, similarity.name)
                    )}
                  />
                </Stat>
              </GridItem>

              <GridItem colSpan={1}>
                <Stat
                  title="Nascimento"
                  text={checkDate(birotContext.document.date_of_birth)}
                  identifier="bureau_personal_data_date_of_birth"
                  copy={checkDate(birotContext.document.date_of_birth)}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <Stat
                  title="Receita Federal"
                  text={birotContext.document.status_federal_revenue}
                  identifier="bureau_personal_data_status_federal_revenue"
                  copy={birotContext.document.status_federal_revenue}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <Stat
                  title="Documento"
                  identifier="bureau_personal_data_document"
                  copy={checkString(birotContext.document.document)}
                >
                  <BondsText
                    text={checkString(birotContext.document.document)}
                    similarity={jaro(
                      birotContext.document.document,
                      fetchFromObject(orderContext, similarity.document)
                    )}
                  />
                </Stat>
              </GridItem>

              <GridItem colSpan={3}>
                <Stat
                  title={`E-mails conhecidos (${emailsArr?.length || '0'})`}
                  identifier="bureau_personal_data_emails"
                >
                  <Stack direction="row" wrap="wrap">
                    {emailsArr && emailsArr?.length > 0
                      ? emailsArr.map((email, index) => {
                          const key = `bureau_personalData_emails${index}`;
                          return (
                            <S.Row key={key}>
                              <BondsText
                                text={email.email}
                                similarity={email.similarity}
                              />
                              <IconButton
                                color={theme.colors.v2.dark[200]}
                                fontSize="small"
                                onClick={handleCopyButtonClick(email.email)}
                              />
                              {index + 1 < emailsArr.length && ','}
                            </S.Row>
                          );
                        })
                      : 'Não informado'}
                  </Stack>
                </Stat>
              </GridItem>
            </S.GridContainer>
          ) : (
            <ImageWithText
              title="Nenhum dado pessoal informado"
              text="Não recebemos as informações dos dados pessoais desta compra."
              image={{ src: IdDocument, alt: 'Nenhum dado pessoal informado' }}
            />
          )}
        </>
      )}
    </S.Container>
  );
}
