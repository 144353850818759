import { TagLabel, Tooltip } from '@chakra-ui/react';
import React from 'react';
import * as I from './interfaces';
import * as S from './styles';
import { handleBadgeColor } from './utils';

const Badge = ({
  label,
  fontSize = 'regular',
  schema,
  color = 'white',
  background = 'gray',
  transparent = false,
  leftIcon,
  icon,
  identifier,
  information,
  children,
  ...rest
}: I.BadgeProps): React.ReactElement => {
  const colors = schema
    ? handleBadgeColor({ schema, transparent })
    : { fontColor: color, backgroundColor: background };

  return (
    <Tooltip hasArrow label={information} borderRadius="large" bg="neutral.800">
      <S.Tag
        data-testid={`badge-container-${identifier}`}
        fontSize={fontSize}
        $backgroundColor={colors.backgroundColor}
        $fontColor={colors.fontColor}
        {...rest}
      >
        {leftIcon && (
          <S.LeftTagIcon
            as={leftIcon}
            fontSize={`${fontSize}`}
            data-testid={`badge-leftIcon-${identifier}`}
          />
        )}
        <TagLabel data-testid={`badge-label-${identifier}`}>{label}</TagLabel>
        {icon && (
          <S.TagIcon
            as={icon}
            fontSize={`${fontSize}`}
            data-testid={`badge-icon-${identifier}`}
          />
        )}
        {children}
      </S.Tag>
    </Tooltip>
  );
};

export default Badge;
