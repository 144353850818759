/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Icon, Stack } from '@chakra-ui/react';
import { FiLink2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Query, QueryResult } from '@material-table/core';
import * as S from './styles';
import { ClientModel } from '../../../interfaces/client';
import { FlowModel } from '../../../interfaces/flow';

import useQuery from '../../../hooks/queryParams';
import useClients from '../../../hooks/api/clients';
import useRulesFlows from '../../../hooks/api/rules_flows';
import Breadcrumb from '../../../components/base/v2/breadcrumb';
import Title from '../../../components/base/v2/title';
import Button from '../../../components/base/v2/button';
import DefaultTable from '../../../components/base/v2/table';
import {
  ExternalIdColumn,
  IdColumn,
  documentColumn,
  nameAndIDColumn,
} from '../../../components/base/v2/table/columns';

const RulesFlowCLientsInfo = (): ReactElement => {
  const { getAllClients, postClientsBonds, deleteClientsFlow } = useClients();
  const { getOneFlow } = useRulesFlows();

  const queryParams = useQuery();
  const flowID = queryParams.get('id');
  const [totalItems, setTotalItems] = useState(0);
  const [flow, setFlow] = useState<FlowModel>();
  const tableRef = useRef<any>();

  const onButtonClick = () => {
    if (tableRef.current) {
      tableRef.current.dataManager?.changeRowEditing();
      tableRef.current.setState({
        ...tableRef.current.dataManager?.getRenderState(),
        showAddRow: true,
      });
    }
  };

  const reloadTable = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, []);

  const loadData = (
    query: Query<ClientModel>
  ): Promise<QueryResult<ClientModel>> =>
    new Promise((resolve, reject) => {
      const data = {
        flow: {
          id: flowID,
        } as FlowModel,
      };
      getAllClients({ data, query })
        .then((result) => {
          if (result.data.items) {
            const total =
              result.data.total_of_items > 9999
                ? 10000
                : result.data.total_of_items;
            setTotalItems(total);
            return resolve({
              data: result.data.items,
              page: result.data.page - 1,
              totalCount: total,
            });
          }
          return resolve({
            data: [],
            page: result.data.page - 1,
            totalCount: result.data.total_of_items,
          });
        })
        .catch(() => {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
          reject(
            toast.error(
              'Erro ao buscar os clientes, tente novamente mais tarde.'
            )
          );
        });
    });

  const rulesFlowColumns = [
    nameAndIDColumn('/client/info'),
    IdColumn,
    documentColumn,
    ExternalIdColumn,
  ];

  const clientTableEdit = {
    onRowAdd: async (data: { name: ClientModel }): Promise<void> => {
      try {
        const client = data.name;

        if (client?.id === undefined) {
          toast.warn(
            'Não foi possível vincular o cliente ao fluxo. È preciso selecionar um cliente para realizar o vínculo.'
          );
          return;
        }

        const resultPostClientBonds = await postClientsBonds({
          url: `clients/${client.id}/flows/${flowID}`,
          version: client.version,
        });

        const { request } = resultPostClientBonds;

        if (![200, 201, 204].includes(request.status)) {
          const { reason } = JSON.parse(request.response);
          toast.warn(`Não foi possível vincular o cliente ao fluxo. ${reason}`);
          return;
        }

        toast.success('O cliente foi vinculado ao fluxo com sucesso!');
        reloadTable();
      } catch (e) {
        toast.warn(`Não foi possível vincular o cliente ao fluxo. ${e}`);
      }
    },
    onRowDelete: async (client: ClientModel): Promise<void> => {
      try {
        const objClient = {
          id: client.id,
          flowID: client.flow_id!,
          version: client.version!,
        };
        const resultDeleteClientsFlow = await deleteClientsFlow(objClient);
        const { request } = resultDeleteClientsFlow;

        if (![200, 201, 204].includes(request.status)) {
          const { reason } = JSON.parse(request.response);
          toast.warn(
            `Não foi possível desvincular o cliente do fluxo. ${reason}`
          );
          return;
        }

        toast.success('O cliente foi desvinculado do fluxo com sucesso!');
        reloadTable();
      } catch (e) {
        toast.warn(`Não foi possível desfazer o vínculo. ${e}`);
      }
    },
  };

  useEffect(() => {
    let cleanUp = true;

    const handleGetFlow = async (): Promise<void> => {
      try {
        const response = await getOneFlow(flowID ?? '');
        if (response?.data) {
          setFlow(response.data);
        }
      } catch (error) {
        toast.error('Erro ao buscar o fluxo, tente novamente mais tarde.');
      }
    };

    if (cleanUp) {
      handleGetFlow();
    }

    return () => {
      cleanUp = false;
    };
  }, []);

  return (
    <S.Main>
      <Stack spacing="medium" py="extra-large">
        <Breadcrumb
          items={[
            { label: 'Início', href: '/' },
            { label: 'Fluxos de regras', href: '/rules-flow' },
            {
              label: flow?.description ?? 'carregando...',
              href: `/rules-flow/info?id=${flowID}`,
            },
            { label: 'Clientes' },
          ]}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title showBackButton text="Clientes" quantity={totalItems} />
          <Button
            onClick={onButtonClick}
            colorScheme="primary"
            leftIcon={<Icon as={FiLink2} />}
            p="13px 20px"
            fontSize="medium"
            borderRadius="large"
          >
            Vincular novo cliente
          </Button>
        </Stack>
      </Stack>

      <DefaultTable
        columns={rulesFlowColumns}
        data={loadData}
        searchLabel="Busque por nome ou ID"
        accessURL="/client/info"
        editable={clientTableEdit}
        tableRef={tableRef}
      />
    </S.Main>
  );
};

export default RulesFlowCLientsInfo;
