import React from 'react';

import { formatTime, getColor, getProgressValue } from './utils';

import * as I from './interfaces';
import * as S from './styles';

export default function ProgressBar({
  initialTime,
}: Readonly<I.ProgressBarProps>): React.ReactElement {
  const progressValue = getProgressValue(initialTime);
  const progressBarWidth = `${progressValue}%`;
  const displayLabel =
    initialTime < 0 ? `-${formatTime(initialTime)}` : formatTime(initialTime);

  return (
    <S.Progressbar>
      <S.Progressline width={progressBarWidth} bg={getColor(initialTime)} />

      <S.Progresslabel
        left={progressBarWidth}
        transform={`translate(-${progressBarWidth}, -50%)`}
      >
        <S.Label bg={getColor(initialTime)}>{displayLabel}</S.Label>
      </S.Progresslabel>
    </S.Progressbar>
  );
}
