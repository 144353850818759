import React from 'react';
import { TabList, TabPanels, Tabs } from '@chakra-ui/react';
import * as S from './styles';
import * as I from './interface';

const BaseTabs = ({ titles, children }: I.TabsProps): React.ReactElement => {
  return (
    <Tabs data-testid="tabs-container" isLazy>
      <TabList>
        {titles.map((item, index) => {
          const key = `tab-${item}-${index}`;
          return <S.Tab key={key}>{item}</S.Tab>;
        })}
      </TabList>
      <TabPanels>{children}</TabPanels>
    </Tabs>
  );
};

export default BaseTabs;
