import React from 'react';
import { useAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import { Stack, Wrap, WrapItem } from '@chakra-ui/react';

import InputSearchSelect from '../../../base/v2/inputs/searchselect';
import {
  isQueuesManagementEditingAtom,
  initialQueuesManagementDataAtom,
} from './stores';
import Stat from '../../../stat';
import Badge from '../../../badge';

export default function Analystis(): React.ReactElement {
  const [isEditing] = useAtom(isQueuesManagementEditingAtom);
  const [initialData] = useAtom(initialQueuesManagementDataAtom);

  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <Stack background="white" borderRadius="extra-large" p="medium">
      {!isEditing ? (
        <WrapItem>
          <Stat title="Analistas" identifier="queue-form-users">
            <Wrap spacing="smallest">
              {initialData.users?.map((value, index) => {
                const key = `queue_form_users--${index}_${value}`;
                return (
                  <WrapItem key={key}>
                    <Badge
                      label={value?.toString()}
                      schema="gray"
                      transparent
                    />
                  </WrapItem>
                );
              })}
            </Wrap>
          </Stat>
        </WrapItem>
      ) : (
        <InputSearchSelect
          isMulti
          isClearable
          control={control}
          label="Analistas"
          name="users"
          formcontrol={{
            error: errors.users,
          }}
        />
      )}
    </Stack>
  );
}
