/* eslint-disable react/prop-types */
import React from 'react';
import { IconProps } from '@chakra-ui/react';
import { BiCopy } from 'react-icons/bi';
import * as S from './styles';

export default function IconButton({ ...rest }: IconProps): React.ReactElement {
  return <S.StyledIcon {...rest} />;
}

IconButton.defaultProps = {
  as: BiCopy,
};
