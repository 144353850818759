import React, { useEffect, useState } from 'react';
import { Button, GridItem, Text, useDisclosure } from '@chakra-ui/react';
import { BsChevronCompactDown } from 'react-icons/bs';
import { useOrder } from '../../../../../providers/order';
import { sortAddressArr } from './utils';
import BaseModal from '../../../../baseModal';
import LoadingSpinner from '../../../../loadingSpinner';
import AddressRow from './addressRow';

import * as S from './styles';
import * as S2 from '../styles';
import * as I from '../interface';
import * as I2 from './interface';

import ImageWithText from '../../../../base/image_with_text';
import NotebookWithMapMarker from '../../../../../assets/icons/notebookWithMapMarker.svg';

const AddressInfo = ({ similarity }: I.SimilarityType): React.ReactElement => {
  const [loading, setLoading] = useState(true);
  const [sortedAddressArr, setSortedAddressArr] =
    useState<I2.SortedAddressArr[]>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { birotContext, orderContext } = useOrder();

  useEffect(() => {
    if (
      birotContext?.document?.addresses &&
      birotContext?.document?.addresses?.length > 0
    ) {
      const similarityArray = sortAddressArr({
        birotContext,
        orderContext,
        similarity,
      });
      setSortedAddressArr(similarityArray);
    }
    setLoading(!birotContext);
  }, [birotContext, similarity]);

  const addresslength = birotContext?.document?.addresses
    ? birotContext.document.addresses.length
    : 0;

  return (
    <S2.Container data-testid="address-info-container">
      <S2.Text mt="16px" mb="16px">
        Endereços ({addresslength})
      </S2.Text>
      {loading && <LoadingSpinner />}
      {!loading &&
        (birotContext?.document?.addresses && sortedAddressArr ? (
          <>
            <S.GridContainer data-testid="grid-container">
              {sortedAddressArr?.map((item, index) => {
                if (index < 10) {
                  const key = `birot_document_addresses_${index}`;
                  return <AddressRow key={key} identifier={index} {...item} />;
                }

                return null;
              })}
              {addresslength > 10 && (
                <GridItem colSpan={7} justifySelf="center">
                  <Button
                    colorScheme="gray"
                    variant="ghost"
                    onClick={() => onOpen()}
                  >
                    <Text fontSize="small" style={{ fontWeight: 600 }}>
                      Mais {addresslength - 10} endereços
                    </Text>
                    <BsChevronCompactDown style={{ marginLeft: '8px' }} />
                  </Button>
                </GridItem>
              )}
            </S.GridContainer>

            <BaseModal
              title={`Endereços (${addresslength})`}
              titleColor="#032E1B"
              isOpen={isOpen}
              onClose={onClose}
              scrollBehavior="inside"
              size="4xl"
            >
              <S.GridContainer>
                {sortedAddressArr.map((item, index) => {
                  const key = `birot_document_addresses_${
                    index + addresslength
                  }`;
                  return (
                    <AddressRow
                      key={key}
                      identifier={index + addresslength}
                      {...item}
                    />
                  );
                })}
              </S.GridContainer>
            </BaseModal>
          </>
        ) : (
          <ImageWithText
            title="Nenhum endereço vinculado"
            text="Não recebemos informações de endereços vinculados com o titular desse pedido."
            image={{
              src: NotebookWithMapMarker,
              alt: 'Nenhum endereço vinculada',
            }}
          />
        ))}
    </S2.Container>
  );
};

export default AddressInfo;
