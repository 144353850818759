import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Image, HStack, Text } from '@chakra-ui/react';

import ArrowLeft from '../../../../assets/icons/newArrowLeft.svg';
import * as I from './interface';

export default function GoBack({ link }: I.GoBack): React.ReactElement {
  const { push } = useHistory();

  const handleGoBack = useCallback(() => {
    push(link);
  }, []);

  return (
    <HStack width="fit-content">
      <Button
        data-testid="menu-button-go-back"
        variant="ghost"
        onClick={handleGoBack}
        fontSize="larger"
        color="white"
        fontWeight="bold"
        _hover={{ background: 'none' }}
      >
        <Image alt="back" src={ArrowLeft} width="larger" height="larger" />
      </Button>
      <Text fontSize="larger" color="white" fontWeight="bold">
        TREX
      </Text>
    </HStack>
  );
}
