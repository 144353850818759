import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { theme } from '../../styles/theme';
import * as I from './interfaces';

const handleBondStatus = (similarity?: number): I.BondsStatusReturn => {
  if (!similarity || similarity <= 0.7)
    return { color: theme.colors.v2.green[400] };

  return similarity === 1
    ? { icon: AiFillCheckCircle, color: theme.colors.v2.green[300] }
    : { icon: AiFillWarning, color: theme.colors.v2.orange[200] };
};

export default handleBondStatus;
