import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container .Toastify__toast {
    background: ${({ theme }) => theme.colors.neutral[800]} !important;
    padding: 1rem;
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    display: flex;
    align-items: center;
  }
  &&&.Toastify__toast-container .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.v2.red[200]} !important;
  }
  &&&.Toastify__toast-container
    .Toastify__toast--error
    .Toastify__close-button:before {
    content: 'Fechar';
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white[700]};
  }
  &&&.Toastify__toast-container .Toastify__toast--warning {
    background: ${({ theme }) => theme.colors.v2.orange[700]} !important;
  }
  &&&.Toastify__toast-container
    .Toastify__toast--warning
    .Toastify__close-button:before {
    content: 'Fechar';
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white[700]};
  }
  &&&.Toastify__toast-container .Toastify__close-button {
    display: flex;
    align-self: center;
  }
  &&&.Toastify__toast-container .Toastify__close-button:before {
    content: 'Fechar';
    font-weight: 700;
    color: ${({ theme }) => theme.colors.v2.green[600]};
  }
  &&&.Toastify__toast-container .Toastify__close-button svg {
    display: none;
  }
`;
