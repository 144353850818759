import React from 'react';
import { useAtom } from 'jotai';

import { isQueuesManagementEditingAtom } from '../stores';
import InformationBase from './baseInformation';
import InformationBaseEditBase from './baseInformationEdit';

export default function SubmenuPartners(): React.ReactElement {
  const [isEditing] = useAtom(isQueuesManagementEditingAtom);

  return (
    <>
      {isEditing ? (
        <InformationBaseEditBase
          dataTestId="queue-submenu-edit"
          titleText="Informações da fila"
        />
      ) : (
        <InformationBase
          dataTestId="queue-submenu"
          name="Fila"
          identifierPrefix="queue"
        />
      )}
    </>
  );
}
