import React from 'react';
import BaseBox from '..';
import useAnalysis from '../../../../hooks/api/analysis';
import { BoxSubmitProps } from '../../interface';
import {
  handleFormToHeldModel,
  heldSelectStatus,
  heldSelectStatusValues,
} from '../../utils';

const HeldBox = () => {
  const { postRetentionReview } = useAnalysis();

  const handleSubmit = ({ id, values, version }: BoxSubmitProps) => {
    const data = handleFormToHeldModel(values);

    return postRetentionReview({ id, data, version });
  };

  return (
    <BaseBox
      roleName="resolve_analysis_withhold"
      link="/filas"
      textMessage="Qualidade"
      dataTestId="held"
      selectOptions={heldSelectStatus}
      valueOptions={heldSelectStatusValues}
      handleSubmitForm={handleSubmit}
    />
  );
};

export default HeldBox;
