import { ReviewDetailEvents } from '../../interfaces/order';
import { HintTreeNodesModel } from '../boxReview/interface';
import { handleTreeObjects } from '../boxReview/utils';
import ItemRow from '../ordersInfo/tabInfo/commentsInfo/components/ItemRow';

export const baseTableOptions = {
  search: false,
  toolbar: false,
  emptyRowsWhenPaging: false,
  exportButton: false,
  selection: false,
  numberOfPagesAround: true,
  showFirstLastPageButtons: true,
  sorting: false,
  pageSizeOptions: [5, 10, 20],
  pageSize: 5,
  debounceInterval: 500,
  actionsColumnIndex: -1,
  addRowPosition: 'first',
  paginationType: 'normal',
  paginationAlignment: 'center',
};

export const baseTablePagination = {
  labelDisplayedRows: `{from} - {to} de {count}`,
  labelRowsSelect: 'Linhas',
  firstAriaLabel: 'Primeira página',
  firstTooltip: 'Primeira página',
  nextAriaLabel: 'Próxima página',
  nextTooltip: 'Próxima página',
  previousAriaLabel: 'Página anterior',
  previousTooltip: 'Página anterior',
  lastAriaLabel: 'Ultima página',
  lastTooltip: 'Ultima página',
};

export const handleJaro = (number: number): React.CSSProperties => {
  if (number === 1) {
    return {
      background: '#008E5A',
      minWidth: 200,
      border: '2px solid white',
    };
  }
  if (number > 0.7) {
    return {
      background: '#D95E00',
      minWidth: 200,
      border: '2px solid white',
    };
  }
  return {};
};

export const handleColor = (number: number): string | undefined => {
  if (number > 0.7) {
    return 'white';
  }
  return undefined;
};

export const findFinalizedReview = (
  events: ReviewDetailEvents[] | undefined
): ReviewDetailEvents | null => {
  if (!events) return null;

  const review = events
    .reverse()
    .find((item) => item.event_type === 'review' && item.action === 'finalize');

  return review || null;
};

export const handleHintTreeOptions = (
  review: ReviewDetailEvents | null
): string | JSX.Element[] => {
  if (review?.hint_tree) {
    const selectedItems = handleTreeObjects(review?.hint_tree);
    const ObjEntries: [string, HintTreeNodesModel][] =
      Object.entries(selectedItems);

    return ObjEntries.map(([key, value]) => {
      return <ItemRow key={key} color="#FFF" data={value} />;
    });
  }

  return '';
};
