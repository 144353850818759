import { TagsinOrderModel } from '../../interfaces/order';
import { OrderedTagsType } from './interface';

export const handleTagsByOrigin = (tags?: TagsinOrderModel[]) => {
  const orderedTags = {} as OrderedTagsType;

  tags?.forEach((item) => {
    if (orderedTags[item.origin]) {
      orderedTags[item.origin].push(item);
    } else {
      orderedTags[item.origin] = [item];
    }
  });

  return orderedTags;
};

export const handleTitleTranslation = (title: string) => {
  switch (title) {
    case 'client':
      return 'Cliente';

    case 'partner':
      return 'Parceiro';

    case 'group':
      return 'Grupo';

    case 'flow':
      return 'Fluxo';

    case 'model':
      return 'Modelo';

    default:
      return title;
  }
};
