import styled from 'styled-components';
import {
  Box,
  Stack,
  Tab as ChakraTab,
  Tabs as ChakraTabs,
  TabPanels as ChakraTabPanels,
  TabPanel as ChakraTabPanel,
  TabList as ChakraTabList,
} from '@chakra-ui/react';

export const ChildrenContainer = styled(Box)`
  padding-bottom: ${({ theme }) => theme.space.medium};
`;

export const HeaderContainer = styled(Stack).attrs(() => ({
  spacing: 'medium',
  pt: 'extra-large',
  pb: 'medium',
}))``;

export const TitleContainer = styled(Stack).attrs(() => ({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}))``;

export const ActionsContainer = styled(Stack).attrs(() => ({
  direction: 'row',
  spacing: 'medium',
}))``;

export const Tab = styled(ChakraTab).attrs(() => ({
  _selected: {
    color: 'v2.green.300',
    borderBottomColor: 'v2.green.300',
    boxShadow: 'none',

    '.notification-container': {
      backgroundColor: 'v2.green.300',
    },
  },
}))`
  text-transform: uppercase;
  min-width: ${({ theme }) => theme.sizes.hugest};
  color: ${({ theme }) => theme.colors.v2.dark[200]};
  border-bottom-color: ${({ theme }) => theme.colors.v2.dark[200]};
  font-weight: 600;

  .notification-container {
    background-color: ${({ theme }) => theme.colors.v2.dark[200]};
  }
`;

export const Tabs = styled(ChakraTabs)``;

export const TabPanels = styled(ChakraTabPanels)``;

export const TabPanel = styled(ChakraTabPanel)`
  padding: 0.3rem 0;
`;

export const TabList = styled(ChakraTabList)``;
