import styled from 'styled-components';
import { Text as ChakraText } from '@chakra-ui/react';
import { styledTheme, theme } from '../../../../styles/theme';

export const Container = styled.div`
  width: 100%;
`;

export const LeftContainer = styled.div`
  width: 60%;
  margin-right: ${styledTheme.spacing(1.5)}px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  width: 40%;
  margin-left: ${styledTheme.spacing(1.5)}px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const Text = styled(ChakraText).attrs(() => ({
  color: theme.colors.v2.green[400],
  fontWeight: 600,
  fontSize: 'larger',
}))``;
