import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  padding: 0 ${({ theme }) => theme.spacing(6)}px;
`;

export const LeftContainer = styled.div`
  width: 60%;
  margin-right: ${({ theme }) => theme.spacing(1.5)}px;
  transition: all 0.2s linear;

  @media (max-width: 1366px) {
    width: 50%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  width: 40%;
  margin-left: ${({ theme }) => theme.spacing(1.5)}px;
  transition: all 0.2s linear;

  @media (max-width: 1366px) {
    width: 50%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;
