import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { buyer } from '../../../utils/bonsMocks';
import { OrderProvider } from '../../../providers/order';
import { ANALISYS_PREFERENCE } from '../../../services/storage';

import SubMenu from '../../../components/submenu';
import Header from '../../../components/base/v2/menu/header';
import Info from '../../../components/ordersInfo';
import TabInfo from '../../../components/ordersInfo/tabInfo';
import HandleBox from '../../../components/boxReview/handleBox';
import OrderTags from '../../../components/orderTags';

import * as S from './styles';

const OrdersInfoV3: React.FC = () => {
  const [similarity, setSimilarity] = useState(buyer);

  const useSimilarity = {
    similarity,
    setSimilarity,
  };
  const { location } = useHistory();
  localStorage.setItem(ANALISYS_PREFERENCE, location.pathname);

  return (
    <OrderProvider>
      <S.Container>
        <Header link="/filas" />
        <SubMenu />
        <OrderTags />
        <Info />
        <TabInfo similarities={useSimilarity} />
        <HandleBox />
      </S.Container>
    </OrderProvider>
  );
};

export default OrdersInfoV3;
