import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Progress,
  Icon,
  Stack,
  Grid,
  GridItem,
  Tooltip,
} from '@chakra-ui/react';

import { BsFillCheckCircleFill } from 'react-icons/bs';

import * as I from './interfaces';
import * as S from '../styles';
import Stat from '../../stat';
import BondsText from '../../bonds';
import { formatValue, handleProgressColor, handleTitleColor } from '../utils';

export default function LighthouseMetrics({
  metrics,
  audits,
}: I.LighthouseMetricsProps): React.ReactElement {
  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>Lighthouse Metrics</S.Title>
      </S.TitleContainer>

      <Stack backgroundColor="white" padding="regular" borderRadius="huge">
        <Accordion allowMultiple width="100%">
          {Object.entries(metrics).map(([key, value]) => (
            <AccordionItem key={key} border="none">
              <AccordionButton>
                <Text
                  textAlign="left"
                  w="100%"
                  fontSize="medium"
                  fontWeight="bold"
                  color={handleTitleColor(value.score)}
                >
                  {value.score === 1 && (
                    <Icon as={BsFillCheckCircleFill} boxSize={4} mr={2} />
                  )}

                  {value.score === null
                    ? `${
                        key.charAt(0).toUpperCase() + key.slice(1)
                      }: Indeterminado`
                    : `${key.charAt(0).toUpperCase() + key.slice(1)}: ${(
                        value.score * 100
                      ).toFixed(0)}%`}

                  <Progress
                    colorScheme={handleProgressColor(value.score)}
                    size="xs"
                    value={value.score * 100}
                    borderRadius="regular"
                    isIndeterminate={!value.score}
                  />
                </Text>

                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="medium">
                <Grid templateColumns="repeat(2, 1fr)" gap="medium">
                  {value.auditRefs.map((item: I.AuditRef) => (
                    <Tooltip
                      key={`audits_${audits?.[item.id]?.title}`}
                      label={audits?.[item.id]?.description ?? 'N/A'}
                    >
                      <GridItem rowSpan={1}>
                        <Stat
                          title={audits?.[item.id]?.title ?? 'N/A'}
                          identifier={`lighthouse_metrics_${item.id}`}
                        >
                          <BondsText
                            text={
                              formatValue(
                                audits?.[item.id]?.scoreDisplayMode ===
                                  'numeric'
                                  ? audits?.[item.id].numericValue
                                  : audits?.[item.id].score
                              ) ?? 'N/A'
                            }
                            similarity={audits?.[item.id]?.score ?? 0}
                          />
                        </Stat>
                      </GridItem>
                    </Tooltip>
                  ))}
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Stack>
    </S.Container>
  );
}
