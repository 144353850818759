import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { FormLabel, Input as ChakraInput, InputGroup } from '@chakra-ui/react';

import * as I from './interfaces';
import FormControl from './formControl';

const InputDuration: ForwardRefRenderFunction<
  HTMLInputElement,
  I.InputTextProps
> = ({ activeLabel, formcontrol, label, name, ...rest }, ref) => {
  return (
    <FormControl {...formcontrol}>
      <InputGroup>
        <ChakraInput
          type="datetime-local"
          ref={ref}
          id={name}
          name={name}
          placeholder=" "
          height="2.75rem"
          minWidth="10rem"
          borderRadius="large"
          variant="outline"
          fontSize="medium"
          borderColor="neutral.500"
          isDisabled={formcontrol.isDisabled}
          {...rest}
        />

        <FormLabel htmlFor={name} className={activeLabel ? 'active' : ''}>
          {label}
        </FormLabel>
      </InputGroup>
    </FormControl>
  );
};

export default forwardRef(InputDuration);
