import React, { useEffect, useState } from 'react';
import BaseTable from '../../../baseTable';
import { useOrder } from '../../../../providers/order';
import LoadingSpinner from '../../../loadingSpinner';
import { birotTableColumns } from '../../../baseTable/tableColumns';
import { loadHistory } from './utils';
import { DefaultTableProps } from '../../../base/v2/table/interfaces';
import { OrderModel } from '../../../../interfaces/order';
import { theme } from '../../../../styles/theme';

export const TransactionsHistory = () => {
  const [loading, setLoading] = useState(true);
  const { orderContext } = useOrder();

  useEffect(() => {
    if (orderContext) {
      setLoading(false);
    }
  }, [orderContext]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const tableOptions: DefaultTableProps['options'] = {
    rowStyle: (rowData: OrderModel) => {
      if (!rowData.order) return {};
      const hasChargeback = rowData.order.payments.some(
        (payment) => payment.status === 'chargeback'
      );
      return {
        backgroundColor: hasChargeback
          ? theme.colors.v2.yellow['100']
          : 'inherit',
      };
    },
  };

  return (
    <BaseTable
      columns={birotTableColumns(orderContext)}
      data={(query) => loadHistory(query, orderContext)}
      options={tableOptions}
    />
  );
};

export default TransactionsHistory;
