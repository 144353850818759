import React, { useEffect } from 'react';

import { GroupModel } from '../../interfaces/groups';

import useGroups from '../../hooks/api/groups';
import {
  IdColumn,
  descriptionColumn,
  nameAndIDColumn,
} from '../../components/base/v2/table/columns';
import GenericListPage from '../genericList';
import useDocumentTitle from '../../hooks/documentTitle';
import { cleanFilters } from '../../components/base/v2/table/stores';

const GroupsList: React.FC = () => {
  useDocumentTitle('Trex - Grupos');
  const { getAllGroups } = useGroups();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  return (
    <GenericListPage<GroupModel>
      actionButton={{
        pushPath: '/groups/create',
        text: ' Criar novo grupo',
      }}
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Grupos' }],
      }}
      title="Grupos"
      table={{
        fetch: {
          apiCall: getAllGroups,
          title: 'grupos',
        },
        body: {
          columns: [
            nameAndIDColumn('/groups/info'),
            IdColumn,
            descriptionColumn,
          ],
          searchLabel: 'Busque por nome, descrição ou ID de um grupo',
          accessURL: '/groups/info',
        },
      }}
    />
  );
};

export default GroupsList;
