/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { RegionDropdown } from 'react-country-region-selector';
import { FormLabel } from '@chakra-ui/react';

export const InputRegion = styled(RegionDropdown)`
  width: 100%;
  min-width: 10rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  height: 2.75rem;
  border-radius: 0.5rem;
  border: 1px solid;
  border-color: #767f8d;
  background: inherit;
  padding: 0 16px;

  :hover {
    border-color: #cbd5e0;
  }
`;

export const ActiveFormLabel = styled(FormLabel)`
  line-height: 1rem;
  -webkit-transform: translateY(-0.9rem) scale(0.9);
  -moz-transform: translateY(-0.9rem) scale(0.9);
  -ms-transform: translateY(-0.9rem) scale(0.9);
  transform: translateY(-0.9rem) scale(0.9);
  background: white;
  color: var(--chakra-colors-neutral-400);
  -webkit-padding-start: var(--chakra-space-1);
  padding-inline-start: var(--chakra-space-1);
  -webkit-padding-end: var(--chakra-space-1);
  padding-inline-end: var(--chakra-space-1);
`;
