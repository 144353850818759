/* eslint-disable import/prefer-default-export */
import * as I from './interfaces';

export const handleTagsFormToScopeModel = ({
  scope,
  data,
  type,
}: I.FormToScopeModelType) => {
  if (type === 'add') {
    scope.tags_to_add = [data.tag_id];
    return scope;
  }

  scope.tags_to_remove = [data.tag_id];
  return scope;
};
