/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { PrimitiveAtom, useAtom } from 'jotai';
import { Icon, Stack, Wrap, WrapItem } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FaRegClone } from 'react-icons/fa';
import { HiOutlineTrash } from 'react-icons/hi';

import * as I from './interfaces';
import { RuleModel } from '../../../interfaces/flow';

import InputSearchSelect from '../../base/v2/inputs/searchselect';
import InputSelect from '../../base/v2/inputs/select';
import handleType from '../../../utils/handleType';
import Button from '../../base/v2/button';
import clone from '../../../utils/clone';
import Information from './termsInformation';
import ParamsInformation from './paramsInformation';
import InputFactory from '../../base/v2/inputs/factory';
import TooltipWrapper from '../../base/tooltip/tooltip_wrapper';

interface RulesNames {
  readonly label: string;
  readonly value: string;
}

interface BaseTermsProps extends I.TermsProps {
  readonly isEditingAtom: PrimitiveAtom<boolean>;
  readonly rulesDataAtom: PrimitiveAtom<{
    rules: RuleModel[];
    rulesNames: RulesNames[];
  }>;
}

export default function TermsBase({
  term,
  terms,
  termIndex,
  nodeIndex,
  appendTerms,
  removeTerms,
  isEditing,
  isEditingAtom,
  rulesDataAtom,
}: BaseTermsProps): React.ReactElement {
  const methods = useFormContext();
  const [isEditingStatus] = useAtom(isEditingAtom);
  const [rulesData] = useAtom(rulesDataAtom);
  const {
    register,
    getValues,
    formState: { errors },
    setValue,
    control,
  } = methods;

  const ruleName = useWatch({
    control,
    name: `nodes.${nodeIndex}.terms.${termIndex}.rule.name`,
  });

  const handleCloneTerms = (): void => {
    const termToClone = clone(
      getValues(`nodes.${nodeIndex}.terms.${termIndex}`)
    );
    appendTerms(termToClone);
    setValue(`nodes.${nodeIndex}.terms.${terms.length}`, termToClone);
  };

  const handleRemoveTerms = (): void => {
    removeTerms(termIndex);
  };

  const hasOnlyTerm = terms.length <= 1;

  return (
    <Stack padding="medium" spacing="medium">
      <Stack direction="row" spacing="regular">
        {isEditing ? (
          <Wrap spacing="regular">
            <WrapItem>
              <InputSearchSelect
                control={control}
                label="Regra"
                name={`nodes.${nodeIndex}.terms.${termIndex}.rule.name`}
                formcontrol={{
                  isRequired: true,
                  error:
                    errors.nodes?.[nodeIndex]?.terms?.[termIndex]?.rule?.name,
                }}
                initialOptions={rulesData.rulesNames}
                isClearable={false}
              />
            </WrapItem>

            <WrapItem>
              <InputSelect
                label="Complemento"
                options={[
                  { option: 'Sim', value: 'true' },
                  { option: 'Não', value: 'false' },
                ]}
                {...register(
                  `nodes.${nodeIndex}.terms.${termIndex}.complement`
                )}
                formcontrol={{
                  isRequired: true,
                  error:
                    errors.nodes?.[nodeIndex]?.terms?.[termIndex]?.complement,
                }}
              />
            </WrapItem>

            <WrapItem>
              <InputSelect
                label="Política de erro"
                options={[
                  { option: 'Ignorar', value: 'ignore' },
                  { option: 'Fail-if-needed', value: 'fail-if-needed' },
                  { option: 'Falhar', value: 'fail' },
                ]}
                {...register(
                  `nodes.${nodeIndex}.terms.${termIndex}.error_policy`
                )}
                formcontrol={{
                  isRequired: true,
                  error:
                    errors.nodes?.[nodeIndex]?.terms?.[termIndex]?.error_policy,
                }}
              />
            </WrapItem>
          </Wrap>
        ) : (
          <Information
            nodeIndex={nodeIndex}
            term={term}
            termIndex={termIndex}
          />
        )}

        <Stack direction="row" marginLeft="auto !important">
          <Button onClick={handleCloneTerms} isDisabled={!isEditing}>
            <Icon as={FaRegClone} />
          </Button>
          <Button
            onClick={handleRemoveTerms}
            isDisabled={hasOnlyTerm || !isEditingStatus}
          >
            <Icon as={HiOutlineTrash} />
          </Button>
        </Stack>
      </Stack>

      {isEditing ? (
        <Wrap
          spacing="regular"
          borderBottom="1px solid"
          borderColor="neutral.400"
          paddingBottom="medium"
        >
          {rulesData?.rules
            ?.find((item) => item.name === ruleName)
            ?.parameters?.map((item, index) => {
              const key = `nodes[${nodeIndex}].terms[${termIndex}].rule.parameters[${item.name}]-${index}`;

              return (
                <WrapItem key={key}>
                  <TooltipWrapper
                    label={item.name}
                    description={item.description}
                  >
                    {InputFactory({
                      name: item.name,
                      path: `nodes.${nodeIndex}.terms.${termIndex}.rule.parameters.${item.name}`,
                      type: handleType(item),
                      defaultValue: item.default,
                      isRequired: item.mandatory,
                      multiple: item.type === 'array of strings',
                      description: item.description,
                      values: item.one_of,
                      isDisabled: false,
                      methods,
                    })}
                  </TooltipWrapper>
                </WrapItem>
              );
            })}
        </Wrap>
      ) : (
        <ParamsInformation
          nodeIndex={nodeIndex}
          term={term}
          termIndex={termIndex}
          rules={rulesData.rules}
        />
      )}
    </Stack>
  );
}
