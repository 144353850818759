/* eslint-disable camelcase */
import React from 'react';
import { GridItem } from '@chakra-ui/react';
import { checkPhone, checkString } from '../../../../../utils/check_values';
import BondsText from '../../../../bonds';
import Stat from '../../../../stat';
import * as I from './interface';

const PhonesRow = ({
  full_number,
  type,
  carrier,
  identifier,
  similarity,
}: I.PhonesInfoType) => {
  return (
    <>
      <GridItem colSpan={3} data-testid={`phone-info-phone-${identifier}`}>
        <Stat
          title="Telefone"
          copy={checkPhone({ value: full_number, removeFormat: true })}
        >
          <BondsText
            text={checkPhone({ value: full_number })}
            similarity={similarity}
          />
        </Stat>
      </GridItem>
      <GridItem colSpan={1} data-testid={`phone-info-type-${identifier}`}>
        <Stat title="Tipo" copy={checkString(type)}>
          <BondsText text={checkString(type)} />
        </Stat>
      </GridItem>
      <GridItem colSpan={1} data-testid={`phone-info-carrier-${identifier}`}>
        <Stat title="Operadora" copy={checkString(carrier)}>
          <BondsText text={checkString(carrier)} />
        </Stat>
      </GridItem>
    </>
  );
};

export default PhonesRow;
