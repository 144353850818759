import { ContactModel } from '../../../interfaces/client';
import { PartnersModel } from '../../../interfaces/partners';
import { documentMask, phoneMask, resetNumbersMask } from '../../../utils/mask';

export const FormToPartnersModel = (data: PartnersModel) => {
  data.contacts?.forEach((item: ContactModel) => {
    if (item.phone === '') {
      delete item.phone;
    }

    if (item.email === '') {
      delete item.email;
    }

    if (item.phone) {
      item.phone = resetNumbersMask(item.phone);
    }
  });

  if (data.document) {
    data.document = resetNumbersMask(data.document);
  }

  return data;
};

export const PartnersModelToForm = (data: PartnersModel) => {
  if (data.document) {
    data.document = documentMask(data.document);
  }

  data.contacts?.forEach((item: ContactModel) => {
    if (item.phone) {
      item.phone = phoneMask({ value: item.phone });
    }
  });

  return data;
};

export const defaultPartner: PartnersModel = {
  name: '',
  document: '',
  contacts: new Array<ContactModel>(),
};
