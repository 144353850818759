import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack, Wrap, WrapItem } from '@chakra-ui/react';

import * as I from './interfaces';

import InputSearchSelect from '../../../../base/v2/inputs/searchselect';
import { queuesFiltersAtom } from '../stores';
import InputFactory from '../../../../base/v2/inputs/factory';
import handleType from '../../../../../utils/handleType';
import useClients from '../../../../../hooks/api/clients';

const MultiInputs = React.memo(function MultiInputs({
  MultiInputsIndex,
}: Readonly<I.QueueManagementFilterProps>): React.ReactElement {
  const [queueFilters] = useAtom(queuesFiltersAtom);
  const methods = useFormContext();
  const { getClients, getOneClient } = useClients();

  const {
    control,
    formState: { errors },
    setValue,
  } = methods;

  const filterName = useWatch({
    control,
    name: `filters.${MultiInputsIndex}.name`,
  });

  useEffect(() => {
    const allParameterNames = queueFilters.filters
      .flatMap((filter) => filter.parameters)
      .map((parameter) => parameter.name);

    const currentFilterParameters = queueFilters.filters
      .find((filter) => filter.name === filterName)
      ?.parameters.map((parameter) => parameter.name);

    allParameterNames.forEach((parameterName) => {
      if (!currentFilterParameters?.includes(parameterName)) {
        setValue(
          `filters.${MultiInputsIndex}.parameters.${parameterName}`,
          undefined
        );
      }
    });
  }, [filterName, MultiInputsIndex, setValue, queueFilters.filters]);

  return (
    <Stack spacing="md" direction="row" flexWrap="wrap">
      <InputSearchSelect
        control={control}
        label="Filtro"
        name={`filters.${MultiInputsIndex}.name`}
        formcontrol={{
          isRequired: true,
          error: errors.filters?.[MultiInputsIndex]?.name,
        }}
        initialOptions={queueFilters.filtersName}
        isClearable={false}
      />

      <Wrap spacing="regular" paddingBottom="medium">
        {queueFilters?.filters
          ?.find((item) => item.name === filterName)
          ?.parameters?.map((item, index) => {
            const key = `filters.[${MultiInputsIndex}].parameters[${index}]--${item.name}`;

            return (
              <WrapItem key={key}>
                {InputFactory({
                  name: item.name,
                  path: `filters.${MultiInputsIndex}.parameters.${item.name}`,
                  type: handleType(item),
                  defaultValue: item.default,
                  isRequired: item.mandatory,
                  multiple: item.type === 'array of strings',
                  description: item.description,
                  values: item.one_of,
                  isDisabled: false,
                  methods,
                  fetchByAPI: { getOptions: getClients, getByID: getOneClient },
                })}
              </WrapItem>
            );
          })}
      </Wrap>
    </Stack>
  );
});

export default MultiInputs;
