import React, { useEffect, useState } from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';
import { Button, GridItem, Text, useDisclosure } from '@chakra-ui/react';
import * as S from './styles';
import * as S2 from '../styles';
import { useOrder } from '../../../../../providers/order';
import BaseModal from '../../../../baseModal';
import LoadingSpinner from '../../../../loadingSpinner';
import NetworkingRow from './networkingRow';
import { KnowsDocumentBirotModel } from '../../../../../interfaces/order';
import * as I from '../interface';
import { sortNetworkingInfoArr } from './utils';

import ImageWithText from '../../../../base/image_with_text';
import Papers from '../../../../../assets/icons/papers.svg';

const NetworkingInfo = ({ similarity }: I.SimilarityType) => {
  const [loading, setLoading] = useState(true);
  const [networkingArr, setNetworkingArr] =
    useState<KnowsDocumentBirotModel[]>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { birotContext, orderContext } = useOrder();

  useEffect(() => {
    if (
      birotContext?.document?.knows &&
      birotContext?.document?.knows?.length > 0
    ) {
      const similarityArray = sortNetworkingInfoArr({
        birotContext,
        orderContext,
        similarity,
      });

      setNetworkingArr(similarityArray);
    }
    setLoading(!birotContext);
  }, [birotContext, similarity]);

  const networkingLength = networkingArr ? networkingArr.length : 0;

  return (
    <S.Container data-testid="network-info-container">
      <S2.Text mb="16px">Pessoas Relacionadas ({networkingLength})</S2.Text>
      {loading && <LoadingSpinner />}
      {!loading &&
        (networkingArr ? (
          <>
            <S.GridContainer data-testid="grid-container">
              {networkingArr.map((item, index) => {
                if (index < 4) {
                  const key = `birot_document_knows_${index}`;
                  return (
                    <NetworkingRow
                      key={key}
                      identifier={index}
                      {...item}
                      similarity={similarity}
                    />
                  );
                }

                return null;
              })}
              {networkingLength > 4 && (
                <GridItem colSpan={7} justifySelf="center">
                  <Button
                    colorScheme="gray"
                    variant="ghost"
                    onClick={() => onOpen()}
                  >
                    <Text fontSize="small" style={{ fontWeight: 600 }}>
                      Mais {networkingLength - 4} pessoas relacionadas
                    </Text>
                    <BsChevronCompactDown style={{ marginLeft: '8px' }} />
                  </Button>
                </GridItem>
              )}
            </S.GridContainer>

            <BaseModal
              title={`Pessoas Relacionadas (${networkingLength})`}
              titleColor="#032E1B"
              isOpen={isOpen}
              onClose={onClose}
              scrollBehavior="inside"
              size="4xl"
            >
              <S.GridContainer>
                {networkingArr.map((item, index) => {
                  const key = `birot_document_knows_${
                    index + networkingLength
                  }`;
                  return (
                    <NetworkingRow
                      key={key}
                      identifier={index + networkingLength}
                      {...item}
                      similarity={similarity}
                    />
                  );
                })}
              </S.GridContainer>
            </BaseModal>
          </>
        ) : (
          <>
            <ImageWithText
              title="Nenhuma pessoa vinculada"
              text="Não recebemos informações de pessoas vinculadas ao titular desse pedido."
              image={{ src: Papers, alt: 'Nenhuma pessoa vinculada' }}
            />
          </>
        ))}
    </S.Container>
  );
};

export default NetworkingInfo;
