import React from 'react';
import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';

import * as S from './styles';
import * as I from '../../../../interfaces/order';

import { buyer, payment, shipping } from '../../../../utils/bonsMocks';
import AddressInfo from './addressInfo';
import PersonalData from './personalInfo';
import NetworkingInfo from './networkingInfo';
import PhonesInfo from './phonesInfo';
import EmailsInfo from './emailsInfo';

const Bureau = ({ similarities }: I.SimilarityProps): React.ReactElement => {
  const { similarity, setSimilarity } = similarities;

  const handleBondChange = (value: string): void => {
    if (value === 'buyer') {
      return setSimilarity(buyer);
    }

    if (value === 'payment') {
      return setSimilarity(payment);
    }

    return setSimilarity(shipping);
  };

  return (
    <S.Container>
      <RadioGroup
        defaultValue={similarity.type}
        mb="regular"
        onChange={handleBondChange}
      >
        <Stack spacing="regular" direction="row">
          <Text fontWeight="600">Defina o vínculo:</Text>
          <Radio colorScheme="green" value="buyer">
            Compra
          </Radio>
          <Radio colorScheme="green" value="payment">
            Pagamento
          </Radio>
          <Radio colorScheme="green" value="shipping">
            Entrega
          </Radio>
        </Stack>
      </RadioGroup>

      <S.Row>
        <S.LeftContainer>
          <PersonalData similarity={similarity} />
          <AddressInfo similarity={similarity} />
        </S.LeftContainer>
        <S.RightContainer>
          <EmailsInfo similarity={similarity} />
          <NetworkingInfo similarity={similarity} />
          <PhonesInfo similarity={similarity} />
        </S.RightContainer>
      </S.Row>
    </S.Container>
  );
};

export default Bureau;
