import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { CUIAutoComplete } from 'chakra-ui-autocomplete';
import { FormErrorMessage, Input, FormControl } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { ActionsListModel } from '../../../../interfaces/actions_list';
import * as I from '../../../../interfaces/components/base/autocomplete';
import { useGroups } from '../../../../providers/groups';
import { trexApi } from '../../../../services/api';
import { SETID } from '../../../../services/storage';

export default function ChakraAutocompleteActions({
  label,
  placeholder,
  methods,
  name,
  error,
  defaultValue,
  isDisabled,
}: I.ChakraAutocompleteModel): React.ReactElement {
  const UUID = localStorage.getItem(SETID);
  const { register, setValue } = methods;
  const { dataContext, loadData } = useGroups();

  const [pickerItems, setPickerItems] = useState<I.Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<I.Item[]>([]);
  const [itemsString, setItemsString] = useState<string[]>([]);

  const handleDefaultValues = useCallback((): void => {
    if (defaultValue) {
      setItemsString(defaultValue);

      defaultValue.forEach((path) => {
        trexApi
          .get(`action-lists/${path}`, {
            headers: {
              'X-SetID': UUID,
            },
          })
          .then((response) => {
            if (response?.data) {
              const item: I.Item = {
                label: response.data.name,
                value: response.data.id,
              };

              setSelectedItems((curr) => [...curr, item]);
              setPickerItems((curr) => [...curr, item]);
            }
          })
          .catch((err) => err);
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    handleDefaultValues();
  }, []);

  useMemo(() => {
    if (itemsString) {
      setValue(name, itemsString);
    }
  }, [itemsString, name]);

  const callActions = useCallback(async (e?: string) => {
    const item: I.Item[] = [];
    const response = await trexApi.get(`action-lists`, {
      params: { name: e },
      headers: {
        'X-SetID': UUID,
      },
    });

    response.data.items?.map((action: ActionsListModel) => {
      const value = { label: '', value: '' };
      if (action.name && action.id) {
        value.label = action.name;
        value.value = action.id;
      }

      return item.push(value);
    });

    setPickerItems(item);
  }, []);

  const handleSelectedItemsChange = (selected?: I.Item[]): void => {
    if (selected) {
      const values: string[] = [];
      selected.forEach((item) => {
        values.push(item.value);
      });

      setSelectedItems(selected);
      setItemsString(values);
    }
  };

  const handleStateChanges = (value: string): void => {
    if (dataContext) {
      trexApi
        .delete(`groups/${dataContext.id}/action-lists/${value}`, {
          headers: {
            'If-Match': dataContext.version,
            'X-SetID': UUID,
          },
        })
        .then(() => {
          toast.success('Lista removida com sucesso!');
          loadData();
        })
        .catch(() => {
          toast.warn('Não foi possível remover a lista');
        });
    }
  };

  return (
    <FormControl
      isInvalid={!!error}
      isDisabled={isDisabled}
      cursor={isDisabled ? 'not-allowed' : 'inherit'}
      paddingRight="10px"
    >
      <Input
        readOnly
        defaultValue={defaultValue}
        value={itemsString}
        {...register(`${name}`)}
        style={{
          display: 'none',
          opacity: 0,
        }}
      />

      <CUIAutoComplete
        disableCreateItem
        label={label || ''}
        inputStyleProps={
          isDisabled
            ? {
                size: 'sm',
                margin: 0,
                pointerEvents: 'none',
                cursor: 'not-allowed',
                height: '2.75rem',
                borderRadius: '0.5rem',
                border: '1px solid',
                borderColor: '#767f8d',
                backgroundColor: 'rgba(239, 239, 239, 0.3)',
              }
            : {
                size: 'sm',
                margin: 0,
                height: '2.75rem',
                borderRadius: '0.5rem',
                border: '1px solid',
                borderColor: '#767f8d',
              }
        }
        labelStyleProps={{ fontSize: 'xs' }}
        tagStyleProps={
          isDisabled
            ? {
                color: 'white',
                bg: 'gray.500',
                opacity: 0.4,
                pointerEvents: 'none',
                cursor: 'not-allowed',
                margin: 0,
                paddingY: 2,
                paddingX: 3,
              }
            : {
                color: 'white',
                bg: 'gray.500',
                margin: 0,
                paddingY: 2,
                paddingX: 3,
              }
        }
        hideToggleButton
        placeholder={placeholder || ''}
        items={pickerItems}
        selectedItems={selectedItems}
        onSelectedItemsChange={(changes): void =>
          handleSelectedItemsChange(changes.selectedItems)
        }
        optionFilterFunc={(item, value): I.Item[] => {
          callActions(value);
          return item;
        }}
        getA11yRemovalMessage={(target): string => {
          handleStateChanges(target.removedSelectedItem.value);
          return '';
        }}
      />

      <div>
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </div>
    </FormControl>
  );
}
