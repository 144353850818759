import React from 'react';
import { useAtom } from 'jotai';

import { initialDataAtom, isEditingAtom } from '../stores';
import InformationBase from './baseInformation';
import InformationBaseEditBase from './baseInformationEdit';

export default function SubmenuBlocks(): React.ReactElement {
  const [isEditing] = useAtom(isEditingAtom);

  return (
    <>
      {isEditing ? (
        <InformationBaseEditBase
          dataTestId="flow-submenu-edit"
          titleText="Informações do fluxo"
          label="Fluxo"
          isEditingAtom={isEditingAtom}
          initialDataAtom={initialDataAtom}
        />
      ) : (
        <InformationBase
          dataTestId="flow-submenu"
          name="Fluxo"
          identifierPrefix="flow"
          initialDataAtom={initialDataAtom}
        />
      )}{' '}
    </>
  );
}
