import styled from 'styled-components';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Button,
} from '@chakra-ui/react';

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(1.75)}px
    ${({ theme }) => theme.spacing(2.5)}px;
  background-color: ${({ theme }) => theme.colors.v2.green[200]} !important;
  color: ${({ theme }) => theme.colors.v2.white[100]};
`;

export const StyledAccordion = styled(Accordion)`
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[100]};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledAccordionItem = styled(AccordionItem)`
  border: none;
`;

export const AccordionHeader = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.spacing(7.5)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(3)}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.v2.gray[100]};
`;

export const AccordionTitle = styled.span`
  font-weight: 600;
  font-size: ${({ theme }) => theme.spacing(2.25)}px;
  color: ${({ theme }) => theme.colors.v2.gray[200]};
`;

export const StyledAccordionButton = styled(AccordionButton)`
  width: ${({ theme }) => theme.spacing(5.5)}px !important;
  height: ${({ theme }) => theme.spacing(5.5)}px !important;
  background-color: ${({ theme }) => theme.colors.v2.gray[100]};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  color: ${({ theme }) => theme.colors.v2.gray[200]};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const HeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
