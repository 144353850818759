import React, { useState } from 'react';

import GenericForm from '../../../components/base/v2/genericForm';
import PartnerUpsertForm from '../../../components/forms/partners';
import { ContactModel } from '../../../interfaces/client';
import { PartnersModel } from '../../../interfaces/partners';

const PartnersCreate: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues: PartnersModel = {
    name: '',
    document: '',
    contacts: new Array<ContactModel>(),
  };

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Parceiro', href: '/partners' },
        { label: 'Cadastro' },
      ]}
      title="Cadastro de Parceiro"
      showBackButton
      backButtonUrl="/partners"
    >
      <PartnerUpsertForm
        partner={initialValues}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />
    </GenericForm>
  );
};

export default PartnersCreate;
