import React from 'react';
import { PrimitiveAtom, useAtom } from 'jotai';
import { Accordion, Icon, Stack } from '@chakra-ui/react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CgAddR } from 'react-icons/cg';
import Button from '../../base/v2/button';
import { NodeProps } from '../nodes/interfaces';

interface FormProps {
  isEditingAtom: PrimitiveAtom<boolean>;
  isEditingNodeAtom: PrimitiveAtom<boolean>;
  NodeComponent: React.ComponentType<NodeProps>;
}

export default function Form({
  isEditingAtom,
  isEditingNodeAtom,
  NodeComponent,
}: FormProps): React.ReactElement {
  const [isEditing] = useAtom(isEditingAtom);
  const [, setIsNodeEditing] = useAtom(isEditingNodeAtom);
  const methods = useFormContext();
  const { control } = methods;

  const {
    fields: nodes,
    move: moveNode,
    append: appendNode,
    remove: removeNode,
  } = useFieldArray({
    control,
    name: 'nodes',
  });

  const handleDragEnd = ({ source, destination }: DropResult): void => {
    if (destination) {
      moveNode(source.index, destination.index);
    }
  };

  const addNode = (): void => {
    setIsNodeEditing(true);
    appendNode({
      title: '',
      operator: '',
      action: 'approve',
      error_policy: '',
      terms: [
        {
          complement: false,
          error_policy: '',
          rule: {
            name: '',
          },
        },
      ],
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="node-list-dnd-droppable">
        {(dropProvided): React.ReactElement => (
          <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
            <Accordion allowToggle allowMultiple>
              <Stack pb="regular" spacing="smallest">
                {nodes.map((node, index) => {
                  const key = `node_${node.id}-${index}`;
                  return (
                    <NodeComponent
                      key={key}
                      node={node}
                      nodeIndex={index}
                      nodes={nodes}
                      appendNode={appendNode}
                      moveNode={moveNode}
                      removeNode={removeNode}
                    />
                  );
                })}
              </Stack>
            </Accordion>
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>

      {isEditing ? (
        <Stack
          direction="row"
          background="white"
          borderRadius="extra-large"
          p="medium"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            leftIcon={<Icon as={CgAddR} />}
            onClick={addNode}
            isDisabled={!isEditing}
          >
            Adicionar nó
          </Button>
        </Stack>
      ) : null}
    </DragDropContext>
  );
}
