/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import copyText from './copy_text';

const useGenericCallback = () => {
  const handleCopyButtonClick = useCallback(
    (text) => (): void => text && copyText(text),
    []
  );

  return {
    handleCopyButtonClick,
  };
};

export default useGenericCallback;
