import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { datadogRum } from '@datadog/browser-rum';
import * as packageJson from '../package.json';

import App from './App';

const tagManagerArgs = {
  gtmId: 'GTM-WMVMJ83',
};

TagManager.initialize(tagManagerArgs);

if (process.env.NODE_ENV === 'production') {
  const applicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID ?? '';
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '';
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'mudi',
    env: 'production',
    version: packageJson.version,
    sessionSampleRate: 50,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(<App />, document.getElementById('root'));
