import React from 'react';

import { theme } from '../../../../../styles/theme';
import CommentItem from './commentItem';
import ImageWithText from '../../../../base/image_with_text';
import CommentImg from '../../../../../assets/icons/commentsIcon.svg';

import * as I from '../interface';

const CommentsContainer = ({
  review,
  delays,
  comments,
  suspensionComments,
  held,
}: I.CommentsContainerProps): React.ReactElement => {
  const image = { alt: 'store-image', src: CommentImg };

  return (
    <>
      {!review && !delays && !comments && !suspensionComments && !held ? (
        <ImageWithText
          title="Nenhum comentário"
          text="Esta transação ainda não teve nenhum comentário, caso necessário, adicione-o no campo de texto ao lado."
          image={image}
        />
      ) : (
        <>
          {review?.map((item, index) => {
            const key = `comment-item-review-${index}`;
            return (
              <CommentItem
                identifier={`review-${index}`}
                key={key}
                commentInfo={item}
                event="Revisão"
                backgroundColor={
                  item.recommendation === 'approve'
                    ? theme.colors.v2.green[100]
                    : theme.colors.v2.red[100]
                }
              />
            );
          })}

          {delays?.map((item, index) => {
            const key = `comment-item-delays-${index}`;
            return (
              <CommentItem
                identifier={`delays-${index}`}
                key={key}
                commentInfo={item}
                event="Agendado"
                backgroundColor={theme.colors.v2.blue[100]}
              />
            );
          })}

          {comments?.map((item, index) => {
            const key = `comment-item-${index}`;
            return (
              <CommentItem
                identifier={index}
                key={key}
                commentInfo={item}
                backgroundColor={theme.colors.v2.white[200]}
              />
            );
          })}

          {suspensionComments?.map((item, index) => {
            const key = `suspension-comment-item-${index}`;

            return (
              <CommentItem
                identifier={index}
                key={key}
                suspensionInfo={item}
                event="Suspensão"
                backgroundColor={theme.colors.v2.white[200]}
              />
            );
          })}

          {held?.map((item, index) => {
            const key = `held-comment-item-${index}`;
            return (
              <CommentItem
                identifier={index}
                key={key}
                commentInfo={item}
                event="Retenção"
                backgroundColor={theme.colors.v2.white[200]}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default CommentsContainer;
