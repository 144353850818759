import React from 'react';
import { GridItem, Stack, Text } from '@chakra-ui/react';

import * as I from './interface';
import * as S from './styles';

import Stat from '../../../../stat';
import BondsText from '../../../../bonds';
import {
  checkBoolean,
  checkDate,
  checkStatus,
  checkString,
} from '../../../../../utils/check_values';
import jaro from '../../../../../utils/jaro';
import SocialMidias from '../../../../socialMidias';
import Badge from '../../../../badge';
import { getRiskLevel, colorMode } from './utils';
import fetchFromObject from '../../../../../utils/fetchFromObject';
import { useOrder } from '../../../../../providers/order';

export default function EmailsInfoGrid({
  birotEmail,
  similarity,
}: I.EmailsInfoGridProps) {
  const { orderContext } = useOrder();

  const { risk } = birotEmail;
  const name = checkString(birotEmail.owner?.name);
  const document = checkString(birotEmail.owner?.document);
  const email = checkString(birotEmail.email);
  const exists = checkBoolean(birotEmail.domain?.exists);
  const status = checkStatus(birotEmail.status);
  const domain = checkString(birotEmail.domain?.name);
  const registration = checkDate(birotEmail.domain?.registration_date);
  const firstSeen = checkDate(birotEmail.first_seen);
  const lastSeen = checkDate(birotEmail.last_seen);
  const flagged = checkDate(birotEmail.flagged_on);

  const nameSimilarity = jaro(
    name,
    fetchFromObject(orderContext, similarity.name)
  );
  const documentSimilarity = jaro(
    document,
    fetchFromObject(orderContext, similarity.document)
  );
  const emailSimilarity = jaro(
    email,
    fetchFromObject(orderContext, similarity.email)
  );
  const existsSimilarity = birotEmail.domain?.exists ? 1 : 0;
  const statusSimilarity = birotEmail.status === 'Verified' ? 1 : 0;

  return (
    <S.GridContainer>
      <GridItem colSpan={3}>
        <Stat title="Nome" identifier="bureau_email_info_data_name" copy={name}>
          <BondsText text={name} similarity={nameSimilarity} />
        </Stat>
      </GridItem>

      <GridItem colSpan={1}>
        <Stat
          title="Documento"
          identifier="bureau_email_info_data_document"
          copy={document}
        >
          <BondsText text={document} similarity={documentSimilarity} />
        </Stat>
      </GridItem>

      <GridItem colSpan={1}>
        <Stat
          title="E-mail"
          identifier="bureau_email_info_data_email"
          copy={email}
        >
          <BondsText text={email} similarity={emailSimilarity} />
        </Stat>
      </GridItem>

      <GridItem colSpan={1}>
        <Stat title="Válido" identifier="bureau_email_info_data_exists">
          <BondsText text={exists} similarity={existsSimilarity} />
        </Stat>
      </GridItem>

      <GridItem colSpan={1}>
        <Stat title="Verificado" identifier="bureau_email_info_data_status">
          <BondsText text={status} similarity={statusSimilarity} />
        </Stat>
      </GridItem>

      <GridItem colSpan={1}>
        <Stat
          title="Domínio"
          text={domain}
          identifier="bureau_email_info_data_domain"
          copy={domain}
        />
      </GridItem>

      <GridItem colSpan={1}>
        <Stat
          title="Registrado"
          text={registration}
          identifier="bureau_email_info_data_registration"
          copy={registration}
        />
      </GridItem>

      <GridItem colSpan={1}>
        <Stat
          title="Primeira visita"
          text={firstSeen}
          identifier="bureau_email_info_data_firstSeen"
          copy={firstSeen}
        />
      </GridItem>

      <GridItem colSpan={1}>
        <Stat
          title="Primeira visita"
          text={lastSeen}
          identifier="bureau_email_info_data_lastSeen"
          copy={lastSeen}
        />
      </GridItem>

      <GridItem colSpan={1}>
        <Stat
          title="Última marcação"
          text={flagged}
          identifier="bureau_email_info_data_flagged"
          copy={flagged}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Stat title="Risco" identifier="bureau_email_info_data_risk">
          <>
            {risk ? (
              <Stack spacing="smaller" direction="row">
                <Text fontWeight="600">{risk * 1000}/1000</Text>
                <Badge
                  label={getRiskLevel(risk)}
                  schema={colorMode[getRiskLevel(risk)]}
                  marginRight="regular"
                />
              </Stack>
            ) : (
              'Não informado'
            )}
          </>
        </Stat>
      </GridItem>

      <GridItem colSpan={2}>
        <SocialMidias />
      </GridItem>
    </S.GridContainer>
  );
}
