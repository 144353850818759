import React from 'react';
import { useAtom } from 'jotai';
import { Grid, GridItem, Stack } from '@chakra-ui/react';

import * as I from './interfaces';

import Stat from '../../../../stat';
import Badge from '../../../../badge';
import { formatDate } from '../../../../../utils/format_date';
import { initialQueuesManagementDataAtom } from '../stores';

export default function InformationBase({
  dataTestId,
  identifierPrefix,
  name,
}: Readonly<I.InformationBaseProps>): React.ReactElement {
  const [initialData] = useAtom(initialQueuesManagementDataAtom);

  return (
    <Stack
      data-testid={dataTestId}
      spacing="medium"
      background="white"
      direction="row"
      p="medium"
      borderRadius="extra-large"
    >
      <Grid templateColumns="repeat(6, 1fr)" gap="medium" width="100%">
        <GridItem colSpan={1}>
          <Stat
            title={name}
            text={initialData.name}
            identifier={`${identifierPrefix}_name`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <Stat
            title="Descrição"
            text={initialData.description}
            identifier={`${identifierPrefix}_description`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <Stat
            title="Prioridade"
            text={initialData['priority@display']}
            identifier={`${identifierPrefix}_priority`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <Stat
            title="Última modificação"
            text={formatDate(initialData.modified_at)}
            identifier={`${identifierPrefix}_modified-at`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <Stat
            title="Modificado por"
            text={initialData.modified_by}
            identifier={`${identifierPrefix}_modified-by`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <Stat title="Status" identifier={`${identifierPrefix}_status`}>
            <Badge
              label={initialData.status === 'active' ? 'Ativo' : 'Inativo'}
              schema={initialData.status === 'active' ? 'green' : 'red'}
              transparent
            />
          </Stat>
        </GridItem>
      </Grid>
    </Stack>
  );
}
