import React from 'react';
import {
  Text,
  Progress,
  Icon,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

import { BsFillCheckCircleFill } from 'react-icons/bs';

import * as S from '../styles';
import * as I from './interfaces';
import { webVitalsDescriptions } from './i18n';
import { formatValue, handleProgressColor, handleTitleColor } from '../utils';

export default function WebVitalsMetrics({
  metrics,
}: I.WebVitalsMetricsProps): React.ReactElement {
  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>Web Vitals Metrics</S.Title>
      </S.TitleContainer>

      <Stack backgroundColor="white" padding="regular" borderRadius="huge">
        <Accordion allowMultiple width="100%">
          {Object.entries(metrics).map(([key, value]) => (
            <AccordionItem key={`web_vitals_metrics_${key}`} border="none">
              <AccordionButton>
                <Text
                  textAlign="left"
                  w="100%"
                  fontSize="medium"
                  fontWeight="bold"
                  color={handleTitleColor(value, 'time')}
                >
                  {value === 1 && (
                    <Icon as={BsFillCheckCircleFill} boxSize={4} mr={2} />
                  )}
                  {key.charAt(0).toUpperCase() + key.slice(1)}:{' '}
                  {formatValue(value)}
                  <Progress
                    colorScheme={handleProgressColor(value, 'time')}
                    size="xs"
                    value={Number((100 - (value / 3000) * 100).toFixed(0))}
                    borderRadius="regular"
                  />
                </Text>

                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Text>
                  {webVitalsDescriptions[key as I.WebVitalsDescriptions]}
                </Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Stack>
    </S.Container>
  );
}
