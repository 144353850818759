import { Query } from '@material-table/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ModelsModel } from '../../../interfaces/models';
import { trexApi } from '../../../services/api';
import { SETID } from '../../../services/storage';
import { UpdateModelsType } from '../interfaces';

const useModels = () => {
  const UUID = localStorage.getItem(SETID);
  const prefix = 'models';

  const getAllFeatures = async () => {
    try {
      const response = await trexApi.get('features', {
        params: { 'page-size': 100 },
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        toast.warn(`Erro na chamada: GET/features`);
        throw error;
      }
    }
  };

  const getAllModels = async (query: Query<ModelsModel>) => {
    try {
      const response = await trexApi.get(prefix, {
        params: {
          page: query.page + 1,
          'page-size': query.pageSize,
          'sort-by': query.orderBy?.field
            ? `${query.orderBy.field.toString()},${query.orderDirection}`
            : null,
          search: query.search ? query.search : null,
        },
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        toast.warn(`Erro na chamada: GET/${prefix}`);
        throw error;
      }
    }
  };

  const getOneModel = async (id: string | undefined) => {
    try {
      const response = await trexApi.get(`${prefix}/${id}`, {
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        toast.warn(`Erro na chamada: GET/${prefix}/${id}`);
        throw error;
      }
    }
  };

  const updateModels = async ({ id, data, version }: UpdateModelsType) => {
    try {
      const response = await trexApi.put(`${prefix}/${id}`, data, {
        headers: {
          'if-match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        toast.warn(`Erro na chamada: PUT/${prefix}`);
        throw error;
      }
    }
  };

  const createModel = async (data: ModelsModel) => {
    try {
      const response = await trexApi.post(prefix, data, {
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        toast.warn(`Erro na chamada: POST/${prefix}`);
        throw error;
      }
    }
  };

  return {
    updateModels,
    createModel,
    getAllFeatures,
    getAllModels,
    getOneModel,
  };
};
export default useModels;
