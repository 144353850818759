/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  OrderModel,
  BirotModel,
  OrderBirotModel,
  OrderBirotReport,
} from '../../interfaces/order';
import { trexApi, trexApiV2 } from '../../services/api';
import { SETID } from '../../services/storage';

interface ContextModel {
  orderContext: OrderModel | null;
  birotContext: BirotModel | null;
  loadOrder(): Promise<void>;
}

const OrderContext = createContext<ContextModel>({} as ContextModel);

const OrderProvider: React.FC = ({ children }) => {
  const UUID = localStorage.getItem(SETID);
  const params: { id: string } = useParams();
  const [data, setData] = useState<OrderModel | null>(null);
  const [birot, setBirot] = useState<BirotModel | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  async function loadOrder(): Promise<void> {
    const orderResponse = await trexApi.get(`analysis/${params.id}`, {
      headers: { 'X-SetID': UUID },
    });
    await setData(orderResponse.data);
    const birotResponse = await trexApiV2.get(`analysis/${params.id}/reports`, {
      headers: { 'X-SetID': UUID },
    });

    if (birotResponse.data) {
      const addresses = [];
      const buyerAddress = birotResponse.data.filter((item: OrderBirotModel) =>
        item.path.includes('order.buyer.address')
      )?.[0]?.report;
      const buyerShippings = birotResponse.data
        .filter((item: OrderBirotModel) =>
          item.path.startsWith('order.shippings')
        )
        ?.map((path: any) => {
          const index = Number(path.path.match(/\d/g));
          return {
            geo: path.report,
            name: orderResponse.data.order.shippings[index].address
              .full_address,
          };
        });
      if (buyerAddress)
        addresses.push({
          type: 'home',
          geo: buyerAddress,
          name: orderResponse.data.order.buyer.address?.full_address,
        } as OrderBirotReport);
      if (buyerShippings)
        buyerShippings.forEach((item: any) => {
          item.geo &&
            addresses.push({
              type: 'shipping',
              geo: item.geo,
              name: item.name,
            } as OrderBirotReport);
        });
      await setBirot({
        address: addresses,
        document: birotResponse.data.filter((item: OrderBirotModel) =>
          item.path.includes('order.buyer.document')
        )?.[0]?.report,
        email: birotResponse.data.filter((item: OrderBirotModel) =>
          item.path.includes('order.buyer.email')
        )?.[0]?.report,
        device: birotResponse.data.filter((item: OrderBirotModel) =>
          item.path.includes('order.platform.ip')
        )?.[0]?.report,
      });
    }

    setIsLoaded(true);
  }

  useEffect(() => {
    loadOrder();
  }, []);

  return (
    <OrderContext.Provider
      value={useMemo(
        () => ({
          orderContext: data,
          loadOrder,
          birotContext: birot,
        }),
        [isLoaded, data, birot]
      )}
    >
      {children}
    </OrderContext.Provider>
  );
};

function useOrder(): ContextModel {
  const context = useContext(OrderContext);
  const { orderContext, loadOrder, birotContext } = context;
  return { orderContext, loadOrder, birotContext };
}

export { OrderProvider, useOrder };
