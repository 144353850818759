import React, { useEffect, useState } from 'react';
import { GridItem } from '@chakra-ui/react';
import * as S from './styles';
import * as S2 from '../styles';
import { useOrder } from '../../../../providers/order';
import Stat from '../../../stat';
import ImageWithText from '../../../base/image_with_text';
import StoreImg from '../../../../assets/icons/store.svg';
import LoadingSpinner from '../../../loadingSpinner';
import { removePhoneFormat } from '../../../../utils/removePhoneFormat';
import {
  checkString,
  checkDate,
  handlePhones,
} from '../../../../utils/check_values';
import IconButton from '../../../icons/iconButton';
import useGenericCallback from '../../../../utils/callbacks';
import { theme } from '../../../../styles/theme';

const BuyersInfo = () => {
  const [loading, setLoading] = useState(true);
  const { orderContext } = useOrder();
  const { handleCopyButtonClick } = useGenericCallback();
  const image = { alt: 'store-image', src: StoreImg };

  const phonesMasked = handlePhones(orderContext?.order?.buyer?.phones);

  useEffect(() => {
    if (orderContext) {
      setLoading(false);
    }
  });

  return (
    <S2.Container data-testid="buyer-container">
      <S2.TitleContainer>
        <S2.Text>Comprador</S2.Text>
      </S2.TitleContainer>
      <S.Card>
        {loading && (
          <GridItem colSpan={4} rowSpan={2}>
            <LoadingSpinner />
          </GridItem>
        )}

        {!loading &&
          (orderContext?.order?.buyer ? (
            <>
              <GridItem colSpan={1}>
                <Stat
                  title="Name"
                  text={checkString(orderContext.order.buyer.name)}
                  identifier="name"
                  copy={checkString(orderContext.order.buyer.name)}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Stat
                  title="Nascimento"
                  text={checkDate(orderContext.order.buyer.date_of_birth)}
                  identifier="birthdate"
                  copy={checkDate(orderContext.order.buyer.date_of_birth)}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Stat
                  title="Documento"
                  text={checkString(orderContext.order.buyer.document)}
                  identifier="document"
                  copy={checkString(orderContext.order.buyer.document)}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Stat
                  title="E-mail"
                  text={checkString(orderContext.order.buyer.email)}
                  identifier="email"
                  copy={checkString(orderContext.order.buyer.email)}
                />
              </GridItem>
              <GridItem colSpan={3}>
                <Stat
                  title="Endereço"
                  text={checkString(
                    orderContext.order.buyer.address?.full_address
                  )}
                  identifier="address"
                  copy={checkString(
                    orderContext.order.buyer.address?.full_address
                  )}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Stat
                  title={`Telefones (${phonesMasked.length})`}
                  identifier="phoneNumbers"
                >
                  {phonesMasked && (
                    <>
                      {phonesMasked.map((phone, index) => {
                        const key = `stat-text-phone-${index}`;
                        return (
                          <S.Row key={key}>
                            <S.ContentText
                              data-testid="stat-text-phoneNumbers"
                              fontWeight={600}
                            >
                              {phone}
                            </S.ContentText>
                            <IconButton
                              color={theme.colors.v2.dark[200]}
                              fontSize="small"
                              onClick={handleCopyButtonClick(
                                removePhoneFormat(phone)
                              )}
                            />
                          </S.Row>
                        );
                      })}
                    </>
                  )}
                </Stat>
              </GridItem>
            </>
          ) : (
            <GridItem colSpan={4} rowSpan={2}>
              <ImageWithText
                title="Nenhum comprador informado"
                text="Não recebemos as informações do comprador desta compra."
                image={image}
              />
            </GridItem>
          ))}
      </S.Card>
    </S2.Container>
  );
};

export default BuyersInfo;
