import styled from 'styled-components';
import { AccordionButton, Button } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import * as I from '../../menu/interfaces';
import { ContainerProps, RowItemIconProps } from './interface';

export const Container = styled.div<ContainerProps>`
  width: ${({ $hasOnlyIcons }) => ($hasOnlyIcons ? '60px' : '220px')};
  margin-top: 72px;
  height: calc(100vh - 72px);
  background: ${({ theme }) => theme.colors.v2.gray[100]};
  transition: 0.5s;
  z-index: 1999;
  padding: ${({ $hasOnlyIcons }) =>
    $hasOnlyIcons ? '24px 0 0 8px' : '24px 12px 12px 12px'};
  overflow: hidden;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #aeaeb2;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #008e5a;
  }
`;

export const RowItem = styled(NavLink)`
  background: transparent;
  width: 100% !important;
  height: 40px !important;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  color: ${({ theme }) => theme.colors.v2.dark[200]};
  font-weight: 400 !important;
  padding: 0 16px !important;
  transition: 0.4s !important;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: 1px;

  &.active {
    background: ${({ theme }) => theme.colors.v2.greenAlpha[100]} !important;
    color: ${({ theme }) => theme.colors.v2.green[200]} !important;
  }
  :hover {
    background: ${({ theme }) => theme.colors.v2.greenAlpha[100]} !important;
    color: ${({ theme }) => theme.colors.v2.green[200]} !important;
  }

  :focus {
    box-shadow: none !important;
  }
`;

export const RowItemIcon = styled(RowItem)<RowItemIconProps>`
  padding: 0 16px 0 ${({ $hasOnlyIcons }) => ($hasOnlyIcons ? '16px' : '32px')} !important;
`;

export const DropdownContainer = styled.div`
  width: 100% !important;
  position: relative;
`;

export const Row = styled.div`
  width: 100% !important;
`;

export const StyledAccondionBtn = styled(AccordionButton)<{
  $active?: boolean;
}>`
  width: 100% !important;
  color: ${({ theme }) => theme.colors.v2.dark[200]};
  font-weight: 400 !important;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: 1px;

  background: ${({ $active, theme }) =>
    $active && theme.colors.v2.greenAlpha[100]} !important;
  color: ${({ $active, theme }) =>
    $active && theme.colors.v2.green[200]} !important;

  :hover {
    background: ${({ theme }) => theme.colors.v2.greenAlpha[100]} !important;
    color: ${({ theme }) => theme.colors.v2.green[200]} !important;
  }
  :focus {
    box-shadow: none !important;
  }
`;

export const Navegation = styled.div<I.Navegation>`
  .active {
    color: ${({ theme }) => theme.colors.red[500]};
    background-color: ${({ theme }) => theme.colors.blue[500]};
    svg {
      fill: ${({ theme }) => theme.colors.red[500]};
      stroke: ${({ theme }) => theme.colors.red[500]};
      stroke-width: 0;
    }
  }

  .onlyIcons {
    aside {
      transition: width 0.5s;
      width: 60px;

      p {
        transition: 0.5s;
        display: none !important;
      }

      .arrow-icon {
        transition: 0.5s;
        display: none;
      }

      .avatar {
        .MuiAvatar-root {
          transition: 0.5s;
          height: 45px;
          width: 45px;
        }
      }
      ul {
        li {
          transition: 0.5s;
          text-align: center;

          svg {
            transition: 0.5s;
            height: 35px;
            margin-right: 0;
            margin-bottom: 0;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  .MuiSkeleton-root {
    background: rgba(255, 255, 255, 0.06);
    margin: 0 auto !important;
    width: 100%;
  }

  ~ main {
    transition: margin-left 0.5s;
    padding-top: 50px;
    margin-left: ${({ marginLeft }) => marginLeft};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const HamburguerButton = styled(Button).attrs(() => ({
  type: 'button',
  p: 0,
}))`
  background: none !important;

  :focus {
    box-shadow: none !important;
  }
  :hover {
    background: ${({ theme }) => theme.colors.v2.green[200]} !important;
  }
`;
