import { useEffect, useState } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';
import { OrderOptions, sidebarOptions, RuleOptions } from '../utils';
import { Role } from '../../../menu/interfaces';
import bagIcon from '../../../../../assets/icons/bagIcon.svg';
import squareInfoIcon from '../../../../../assets/icons/squareInfoIcon.svg';
import SidebarDropdown from './dropdown';
import checkMenuRoles from '../../../../../utils/check_menu_roles';
import * as S from '../styles';
import * as I from '../interface';

const Sidebar = ({ onlyIcons }: I.SidebarProps) => {
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [roles, setRoles] = useState<Role[]>();

  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak.authenticated) {
      setRoles(checkMenuRoles(keycloak));
    }
  }, [keycloak, keycloak.authenticated]);

  useEffect(() => {
    if (pathname.includes('orders/info')) {
      return setShowMenu(false);
    }
    return setShowMenu(true);
  }, [pathname]);

  return (
    <>
      {showMenu ? (
        <S.Container $hasOnlyIcons={onlyIcons} data-testid="sidebar-container">
          {roles?.includes('view_analysis') && (
            <SidebarDropdown
              title="Pedidos"
              icon={bagIcon}
              options={OrderOptions}
              onlyIcons={onlyIcons}
              roles={roles}
            />
          )}

          {sidebarOptions.map((item) => {
            return (
              <S.Row key={item.name}>
                {roles?.includes(item.role) && (
                  <S.RowItem to={item.link}>
                    <Image
                      alt={item.name}
                      src={item.icon}
                      mr={4}
                      width="21px"
                      height="21px"
                    />
                    <Box as="span">{item.name}</Box>
                  </S.RowItem>
                )}
              </S.Row>
            );
          })}
          {roles?.includes('view_flows') && (
            <SidebarDropdown
              title="Regras"
              icon={squareInfoIcon}
              options={RuleOptions}
              onlyIcons={onlyIcons}
              roles={roles}
            />
          )}
        </S.Container>
      ) : null}
    </>
  );
};

export default Sidebar;
