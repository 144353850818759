import styled from 'styled-components';
import {
  Button,
  ModalCloseButton,
  ModalHeader as ChakraHeader,
  Text,
} from '@chakra-ui/react';

export const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.v2.green[200]} !important;
  color: white;

  :hover {
    background-color: ${({ theme }) => theme.colors.v2.green[300]} !important;
  }

  flex: 1;
`;

export const Title = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  color: #303742;
`;

export const ModalHeader = styled(ChakraHeader)`
  border-bottom: 1px solid #d0d5dd;
`;

export const CloseButton = styled(ModalCloseButton)`
  box-shadow: none !important;
`;
