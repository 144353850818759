import React, { useEffect, useState } from 'react';
import * as S from './styles';
import * as I from '../../../../interfaces/order';
import { useOrder } from '../../../../providers/order';
import Maps from '../../../map';
import LoadingSpinner from '../../../loadingSpinner';

const MapInfo = () => {
  const [address, setAddress] = useState<I.OrderBirotReport[]>();
  const [loading, setLoading] = useState(true);
  const { birotContext } = useOrder();

  useEffect(() => {
    if (
      birotContext?.address &&
      birotContext.address.some((item) => item.geo !== undefined)
    ) {
      setAddress(birotContext.address);
    }
    setLoading(false);
  }, [birotContext]);

  return (
    <S.Container data-testid="map-tab-container">
      {loading && <LoadingSpinner />}
      {!loading && <Maps address={address} />}
    </S.Container>
  );
};

export default MapInfo;
