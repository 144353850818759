import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  min-height: ${({ theme }) => theme.spacing(12.5)}px;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
  padding: ${({ theme }) => theme.spacing(11)}px
    ${({ theme }) => theme.spacing(6)}px ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(6)}px;
`;
