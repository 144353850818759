import React from 'react';
import { useAtom } from 'jotai';

import { initialDataBlocksAtom, isEditingBlocksAtom } from '../stores';
import InformationBase from '../../rulesFlowInfo/submenu/baseInformation';
import InformationEditBase from '../../rulesFlowInfo/submenu/baseInformationEdit';

export default function SubmenuBlocks(): React.ReactElement {
  const [isEditing] = useAtom(isEditingBlocksAtom);

  return (
    <>
      {isEditing ? (
        <InformationEditBase
          dataTestId="blocks-submenu-edit"
          titleText="Informações do bloco"
          label="Bloco"
          isEditingAtom={isEditingBlocksAtom}
          initialDataAtom={initialDataBlocksAtom}
        />
      ) : (
        <InformationBase
          dataTestId="blocks-submenu"
          name="Bloco"
          identifierPrefix="block"
          initialDataAtom={initialDataBlocksAtom}
        />
      )}{' '}
    </>
  );
}
