import { atom } from 'jotai';

import {
  QueueManagementAPIFiltersModel,
  QueueManagementModel,
} from '../../../../interfaces/queue_management';
import { defaultQueueManagement } from './utils';

export const initialQueuesManagementDataAtom = atom<QueueManagementModel>(
  defaultQueueManagement
);
export const isQueuesManagementEditingAtom = atom(true);

export const queuesFiltersAtom = atom<{
  filters: QueueManagementAPIFiltersModel[];
  filtersName: { label: string; value: string }[];
}>({
  filters: [],
  filtersName: [],
});
