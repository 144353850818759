import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import IconButton from '../../../components/icons/iconButton';
import useGenericCallback from '../../../utils/callbacks';

export default function PrivateKey(): React.ReactElement {
  const [privateKey, setPrivateKey] = useState<string | null>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleCopyButtonClick } = useGenericCallback();

  useEffect(() => {
    if (localStorage.getItem('client_create')) {
      setPrivateKey(localStorage.getItem('client_create'));
      onOpen();
    }
  }, []);

  const handleClose = () => {
    localStorage.removeItem('client_create');
    onClose();
  };

  return (
    <>
      {privateKey && (
        <Modal onClose={handleClose} isOpen={isOpen} size="3xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Chave privada para a integração direta do cliente
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <strong>Atenção</strong>: essa chave não irá mais aparecer, copie
              o código e guarde em algum lugar seguro!
              <br /> <br />
              <strong>Chave</strong>: {privateKey}
              <IconButton
                data-testid="copy-button"
                onClick={handleCopyButtonClick(privateKey || '')}
              />
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button onClick={handleClose} colorScheme="red">
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
