import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Stack, useToast } from '@chakra-ui/react';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { Query, QueryResult } from '@material-table/core';
import Breadcrumb from '../../../components/base/v2/breadcrumb';
import Title from '../../../components/base/v2/title';
import Button from '../../../components/base/v2/button';
import DefaultTable from '../../../components/base/v2/table';
import useRulesFlows from '../../../hooks/api/rules_flows';
import { FlowModel } from '../../../interfaces/flow';
import {
  IdColumn,
  createdAtColumn,
  descriptionAndIDColumn,
  modifiedAtColumn,
} from '../../../components/base/v2/table/columns';
import useDocumentTitle from '../../../hooks/documentTitle';

import * as S from './styles';

const RulesFlow: React.FC = () => {
  useDocumentTitle('Trex - Fluxos');
  const toast = useToast();

  const [totalItems, setTotalItems] = useState(0);
  const { getFlows } = useRulesFlows();

  const history = useHistory();

  const handleButtonClick = (): void => {
    history.push('/rules-flow/create');
  };

  const loadData = (query: Query<FlowModel>): Promise<QueryResult<FlowModel>> =>
    new Promise((resolve, reject) => {
      getFlows(query)
        .then((result) => {
          if (result.data.items) {
            const total =
              result.data.total_of_items > 9999
                ? 10000
                : result.data.total_of_items;
            setTotalItems(total);
            return resolve({
              data: result.data.items,
              page: result.data.page - 1,
              totalCount: total,
            });
          }
          return resolve({
            data: [],
            page: result.data.page - 1,
            totalCount: result.data.total_of_items,
          });
        })
        .catch(() => {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
          reject(
            toast({
              title: 'Erro ao buscar fluxos',
              position: 'top-right',
              description:
                'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          );
        });
    });

  const rulesFlowColumns = [
    descriptionAndIDColumn('/rules-flow/info'),
    IdColumn,
    createdAtColumn,
    modifiedAtColumn,
  ];

  return (
    <S.Main>
      <Stack spacing="medium" py="extra-large">
        <Breadcrumb
          items={[
            { label: 'Início', href: '/' },
            { label: 'Fluxos de regras' },
          ]}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <Title text="Fluxos de regras" quantity={totalItems} />
          </Stack>
          <Button
            onClick={handleButtonClick}
            colorScheme="primary"
            leftIcon={<Icon as={HiOutlinePlusCircle} />}
            p="13px 20px"
            fontSize="medium"
            borderRadius="large"
          >
            Criar novo fluxo
          </Button>
        </Stack>
      </Stack>

      <DefaultTable
        columns={rulesFlowColumns}
        data={loadData}
        searchLabel="Busque por nome ou ID, regra ou nó"
        accessURL="/rules-flow/info"
      />
    </S.Main>
  );
};

export default RulesFlow;
