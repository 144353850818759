import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Image,
} from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';
import * as S from '../styles';
import * as I from '../interface';
import { isDropdownActive } from '../utils';

const SidebarDropdown = ({
  title,
  options,
  icon,
  onlyIcons,
  roles,
}: I.DropdownType) => {
  const [pagePath, setPagePath] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    setPagePath(location.pathname);
  }, [location]);

  return (
    <S.DropdownContainer>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem border="none" width="100%">
          <S.StyledAccondionBtn $active={isDropdownActive(pagePath, title)}>
            <Image alt={title} src={icon} mr={4} width="21px" height="21px" />
            <Box as="span" flex="1" textAlign="left">
              {title}
            </Box>
            <AccordionIcon />
          </S.StyledAccondionBtn>

          <AccordionPanel p={0} transitionDuration="0.5s">
            {options.map((item) => {
              return item.role ? (
                <div key={item.name}>
                  {roles?.includes(item.role) && (
                    <S.RowItemIcon
                      to={item.link}
                      exact={item.exact}
                      $hasOnlyIcons={onlyIcons}
                    >
                      <Image
                        alt={item.name}
                        src={item.icon}
                        mr={4}
                        width="21px"
                        height="21px"
                      />
                      <Box as="span">{item.name}</Box>
                    </S.RowItemIcon>
                  )}
                </div>
              ) : (
                <S.RowItemIcon
                  to={item.link}
                  key={item.name}
                  exact={item.exact}
                  $hasOnlyIcons={onlyIcons}
                >
                  <Image
                    alt={item.name}
                    src={item.icon}
                    mr={4}
                    width="21px"
                    height="21px"
                  />
                  <Box as="span">{item.name}</Box>
                </S.RowItemIcon>
              );
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </S.DropdownContainer>
  );
};

export default SidebarDropdown;
