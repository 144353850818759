import React from 'react';
import {
  handleFormToSuspensionModel,
  suspensionSelectStatus,
  suspensionSelectStatusValues,
} from '../../utils';
import BaseBox from '..';
import useAnalysis from '../../../../hooks/api/analysis';
import { BoxSubmitProps } from '../../interface';

const SuspensionBox = () => {
  const { updateOrderSuspensionStatus } = useAnalysis();

  const handleSubmit = ({ id, values, version }: BoxSubmitProps) => {
    const data = handleFormToSuspensionModel(values);

    return updateOrderSuspensionStatus({ id, data, version });
  };

  return (
    <BaseBox
      roleName="resolve_suspension"
      link="/suspensions"
      textMessage="Resolução"
      dataTestId="suspension"
      selectOptions={suspensionSelectStatus}
      valueOptions={suspensionSelectStatusValues}
      handleSubmitForm={handleSubmit}
    />
  );
};

export default SuspensionBox;
