import * as Yup from 'yup';

const validateSchema = Yup.object().shape({
  description: Yup.string().required('É preciso informar um Nome'),
  external_uri: Yup.string().required('É preciso informar uma URI'),
  backend: Yup.string().required('É preciso selecionar o backend'),
  features: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('É preciso selecionar a feature'),
    })
  ),
});

export default validateSchema;
