import React from 'react';
import { Text } from '@chakra-ui/react';
import * as I from './interface';
import * as S from './styles';

const NotificationCircle = ({
  size = 'small',
  text,
  backgroundColor,
}: I.NotificationProps) => {
  return (
    <S.Container
      className="notification-container"
      data-testid="notification-container"
      backgroundColor={backgroundColor}
    >
      <Text fontSize={size} data-testid="notification-text">
        {text}
      </Text>
    </S.Container>
  );
};

export default NotificationCircle;
