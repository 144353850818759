import React from 'react';
import { useHistory } from 'react-router-dom';
import { Heading, Stack, Icon } from '@chakra-ui/react';
import { HiArrowLeft } from 'react-icons/hi';

import * as I from './interfaces';

import Button from '../button';
import NotificationCircle from '../../../notificationCircle';
import { theme } from '../../../../styles/theme';
import IconButton from '../../../icons/iconButton';
import useGenericCallback from '../../../../utils/callbacks';

export default function Title({
  text,
  quantity,
  showBackButton,
  backButtonUrl,
  copy,
  ...props
}: I.TitleProps): React.ReactElement {
  const history = useHistory();
  const { handleCopyButtonClick } = useGenericCallback();

  const handleBackButtonClick = (): void => {
    if (backButtonUrl) {
      history.push(backButtonUrl);
    } else {
      history.go(-1);
    }
  };

  return (
    <Stack direction="row" spacing="smaller" alignItems="center">
      {showBackButton && (
        <Button
          data-testid="back-button"
          background="neutral.50"
          borderRadius="large"
          color="neutral.700"
          height="2.25rem"
          width="2.25rem"
          _hover={{
            background: 'v2.green.50',
            color: 'v2.green.500',
          }}
          onClick={handleBackButtonClick}
        >
          <Icon as={HiArrowLeft} width="larger" height="larger" />
        </Button>
      )}

      <Heading as="h1" fontSize="extra-large" color="neutral.700" {...props}>
        {text}
      </Heading>

      {copy ? (
        <IconButton
          data-testid="title_copy--icon"
          color={copy.color ?? theme.colors.neutral[700]}
          fontSize="small"
          onClick={handleCopyButtonClick(copy.text)}
        />
      ) : null}

      {quantity !== undefined && quantity !== null && (
        <NotificationCircle
          text={quantity}
          backgroundColor={theme.colors.neutral[500]}
          size="regular"
        />
      )}
    </Stack>
  );
}
