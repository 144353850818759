/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import L from 'leaflet';
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';
import * as S from './styles';
import homeMarker from '../../assets/icons/homeMarker.svg';
import deliveryMarker from '../../assets/icons/deliveryMarker.svg';
import Main from './Main';
import * as I from './interface';
import { OrderBirotReport } from '../../interfaces/order';

const Maps = ({ address }: I.MapProps) => {
  const [centered, setCentered] = useState<I.LatLng[]>();
  const iconDeliveryMarker = new L.Icon({
    iconUrl: deliveryMarker,
    iconRetinaUrl: deliveryMarker,
    iconAnchor: [20, 39],
    popupAnchor: [0, -20],
    iconSize: [30, 40],
  });
  const iconhomeMarker = new L.Icon({
    iconUrl: homeMarker,
    iconRetinaUrl: homeMarker,
    iconAnchor: [29, 54],
    popupAnchor: [-3, -30],
    iconSize: [45, 55],
  });

  const handleOpenGoogleMaps = (addressOrder: OrderBirotReport[]) => {
    const addressName = addressOrder[addressOrder.length - 1].name || '';
    const newPageUrl = `https://www.google.com/maps/place/${addressName}`;
    window.open(newPageUrl, '_blank');
  };

  useEffect(() => {
    if (address && address.length >= 1) {
      address.forEach((item) => {
        if (item.geo?.latitude) {
          setCentered((oldValue) =>
            oldValue
              ? [...oldValue, [item.geo!.latitude, item.geo!.longitude]]
              : [[item.geo!.latitude, item.geo!.longitude]]
          );
        }
      });
    }
  }, [address]);

  return (
    <Main data-testid="map-container">
      {address && centered ? (
        <>
          <MapContainer zoom={13} bounds={centered} scrollWheelZoom>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {address.map(
              (item, index) =>
                item &&
                item.geo && (
                  <Marker
                    key={`marker${item.geo.latitude}_${index}`}
                    icon={
                      item.type === 'home' ? iconhomeMarker : iconDeliveryMarker
                    }
                    position={[item.geo.latitude, item.geo.longitude]}
                  >
                    <Popup>{item.name}</Popup>
                  </Marker>
                )
            )}
          </MapContainer>

          <S.BtnStreetView
            onClick={() => handleOpenGoogleMaps(address)}
            className="btnStreetView"
            formTarget="_blank"
            _hover={{ bg: 'rgba(0,0,0,.3' }}
          >
            Ver no Google Maps
          </S.BtnStreetView>
        </>
      ) : (
        <Box as="div" textAlign="center" p="25px 0" bg="rgba(0, 0, 0, 0.04)">
          Dados insuficientes para exibição do mapa
        </Box>
      )}
    </Main>
  );
};

export default Maps;
