import * as React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as I from './inteface';

export default function BaseModal({
  isOpen,
  size = 'md',
  onClose,
  action,
  titleColor = '#303742',
  closeText,
  title,
  description,
  actionText,
  children,
  scrollBehavior = 'outside',
}: I.AlertModalProps): React.ReactElement {
  return (
    <Modal
      isOpen={isOpen}
      scrollBehavior={scrollBehavior}
      size={size}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={titleColor}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <span>{description}</span>
          {children}
        </ModalBody>
        <ModalFooter>
          {closeText && (
            <Button variant="ghost" mr={3} onClick={onClose}>
              {closeText}
            </Button>
          )}
          {action && (
            <Button type="submit" onClick={action} colorScheme="blue">
              {actionText || 'ENVIAR'}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
