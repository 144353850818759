/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Grid } from '@chakra-ui/react';

export const GridContainer = styled(Grid).attrs(() => ({
  templateColumns: 'repeat(7, 1fr)',
  gap: 3,
}))`
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  word-break: break-word;
`;
