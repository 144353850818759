/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import * as I from './interface';

export const Container = styled.div<I.NotificationContainerProps>`
  min-width: ${({ theme }) => theme.spacing(3)}px;
  min-height: ${({ theme }) => theme.spacing(2.3)}px;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ?? theme.colors.v2.green[300]};
  color: ${({ theme }) => theme.colors.v2.white[100]};
  border-radius: 100px;
  padding: ${({ theme }) => theme.spacing(0.3)}px
    ${({ theme }) => theme.spacing(1)}px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;
