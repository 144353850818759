import React from 'react';
import { RulesModelsProvider } from '../../../providers/models';
import Body from '../../../sections/models/body';

const ModelsCreate: React.FC = () => {
  return (
    <RulesModelsProvider>
      <Body />
    </RulesModelsProvider>
  );
};

export default ModelsCreate;
