/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { Button, Stack, Icon } from '@chakra-ui/react';
import { HiArrowRight } from 'react-icons/hi';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGroups as useGroup } from '../../../providers/groups';
import GroupsInformations from './container/information';
import ValidateSchema from '../../../validations/groups';
import useGroups from '../../../hooks/api/groups';
import Header from '../../base/v2/formHeader';
import RulesFlow from '../../../sections/client/form/bonds/rulesFlow';
import Model from '../../../sections/client/form/bonds/model';
import GroupsActionsForm from './container/actions';
import BondTags from '../../bondTags';
import ReviewModal from '../../reviewModal';
import { handleTagsFormToScopeModel } from '../../bondTags/utils';

import * as S from './styles';
import * as I from '../../../interfaces/groups';
import * as I2 from '../../bondTags/interfaces';

export default function GroupForm({
  defaultData,
  isDisabled,
  setIsDisabled,
}: I.PropsGroupsForm): React.ReactElement {
  const { push } = useHistory();
  const { loadData } = useGroup();
  const {
    updateGroup,
    createGroup,
    getOneGroup,
    bindGroupsModel,
    bindGroupsFlow,
    bindGroupsActions,
  } = useGroups();
  const [isLoading, setIsLoading] = useState(true);
  const [flow, setFlow] = useState('');
  const [model, setModel] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm({
    resolver: yupResolver(ValidateSchema),
    defaultValues: defaultData || {},
  });

  const { getValues } = methods;

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  useMemo(() => {
    if (defaultData) {
      if (defaultData.flow_id) {
        setFlow(defaultData.flow_id);
      }
      if (defaultData.model_id) {
        setModel(defaultData.model_id);
      }
    }
    setIsLoading(false);
  }, []);

  const reloadData = async (id: string): Promise<I.GroupModel> => {
    const response = await getOneGroup(id);
    return response.data;
  };

  const bindActions = async (group: I.GroupModel, actionID: string) => {
    if (actionID) {
      try {
        const res = await bindGroupsActions({
          id: group.id,
          scopeID: actionID,
          version: group.version,
        });

        if ([200, 201, 204].includes(res.request.status)) {
          toast.success(`Grupo adicionado a lista com sucesso!`);
          loadData();
          return;
        }
        const { reason } = JSON.parse(res.request.response);
        toast.warn(`Não foi possível adicionar o grupo a lista ${reason}`);
      } catch {
        toast.warn(`Não foi possível adicionar o grupo a lista`);
      }
    }
  };

  const handleBindFlow = async (
    flowID: string | undefined,
    group: I.GroupModel
  ): Promise<void> => {
    if (!!flowID && group.id) {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const reload = await reloadData(group.id);
      bindGroupsFlow({
        id: reload.id,
        scopeID: flowID,
        version: reload.version,
      })
        .then((res) => {
          if ([200, 201, 204].includes(res.request.status)) {
            toast.success(`Grupo adicionado ao fluxo com sucesso!`);
            loadData();
            return;
          }
          const { reason } = JSON.parse(res.request.response);
          toast.warn(`Não foi possível adicionar o grupo ao fluxo ${reason}`);
        })
        .catch(() => {
          toast.warn(`Não foi possível adicionar o grupo ao fluxo`);
        });
    }
  };

  const handleBindModel = async (
    modelID: string | undefined,
    group: I.GroupModel
  ): Promise<void> => {
    if (!!modelID && group.id) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const reload = await reloadData(group.id);

      bindGroupsModel({
        id: reload.id,
        scopeID: modelID,
        version: reload.version,
      })
        .then((res) => {
          if ([200, 201, 204].includes(res.request.status)) {
            toast.success(`Grupo adicionado ao modelo com sucesso!`);
            loadData();
            return;
          }
          const { reason } = JSON.parse(res.request.response);
          toast.warn(`Não foi possível adicionar o grupo ao modelo ${reason}`);
        })
        .catch(() => {
          toast.warn(`Não foi possível adicionar o grupo ao modelo`);
        });
    }
  };

  const handleBindActions = async (
    actions: string[] | undefined,
    group: I.GroupModel
  ): Promise<void> => {
    if (!!actions && !!group.id) {
      for (const actionID of actions) {
        await new Promise<void>((resolve) => setTimeout(resolve, 1000));
        if (!group.action_lists?.includes(actionID)) {
          const reload = await reloadData(group.id);
          bindActions(reload, actionID);
        }
      }
    }
  };

  const editGroup: SubmitHandler<I.GroupModel> = async (data) => {
    try {
      const res = await updateGroup({
        id: defaultData?.id,
        data,
        version: defaultData?.version,
      });
      if ([200, 201, 204].includes(res.request.status)) {
        toast.success(`Grupo alterado com sucesso!`);
        loadData();
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      toast.warn(`Não foi possível alterar o grupo ${reason}`);
    } catch {
      toast.warn(`Não foi possível alterar a o grupo`);
      loadData();
    }
  };

  const handleCreateGroup: SubmitHandler<I.GroupModel> = async (data) => {
    data.flow_id = flow;
    data.model_id = model;

    await createGroup(data)
      .then(async (response) => {
        await handleBindModel(data.model_id, response.data);
        await handleBindFlow(data.flow_id, response.data);
        await handleBindActions(data.action_lists, response.data);
        toast.success(`Grupo criado com sucesso!`);
        push(`/groups/info?id=${response.data.id}`);
      })
      .catch(() => {
        toast.warn(`Não foi possível criar a grupo`);
      });
  };

  const handleEditGroup: SubmitHandler<I.GroupModel> = async (data) => {
    if (defaultData) {
      data.flow_id = flow;
      data.model_id = model;

      if (
        defaultData.name !== data.name ||
        defaultData.description !== data.description
      ) {
        const sendData = {
          name: data.name,
          description: data.description,
        };
        await editGroup(sendData as I.GroupModel);
      }

      if (defaultData.flow_id !== data.flow_id) {
        await handleBindFlow(data.flow_id, defaultData);
      }

      if (defaultData.model_id !== data.model_id) {
        await handleBindModel(data.model_id, defaultData);
      }

      if (
        JSON.stringify(defaultData.action_lists) !==
        JSON.stringify(data.action_lists)
      ) {
        await handleBindActions(data.action_lists, defaultData);
      }
    }
  };

  const onSubmit: SubmitHandler<I.GroupModel> = async (data): Promise<void> => {
    setIsSubmitting(true);
    if (defaultData) {
      setIsModalOpen(false);
      await handleEditGroup(data).finally(() => {
        setIsSubmitting(false);
        setIsDisabled(true);
      });
    } else {
      await handleCreateGroup(data);
    }
  };

  const handleTagSubmit = ({ data, type }: I2.TagSubmitType) => {
    const sendData = handleTagsFormToScopeModel({
      scope: defaultData!,
      data,
      type,
    }) as I.GroupModel;

    return updateGroup({
      id: defaultData?.id,
      data: sendData,
      version: defaultData?.version,
    }).finally(() => {
      loadData();
    });
  };

  const handleSubmit = async (values: I.GroupModel) => {
    if (
      defaultData &&
      (defaultData?.flow_id !== values.flow_id ||
        defaultData?.model_id !== values.model_id)
    ) {
      handleModalOpen();
    } else {
      await onSubmit(values);
    }
  };

  const handleConfirmPublish = async () => {
    const formValues = getValues();
    await onSubmit(formValues);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <FormProvider {...methods}>
        {defaultData?.id && isDisabled && (
          <Header info={defaultData} title="Grupo" />
        )}
        {defaultData?.id && (
          <BondTags
            scope={defaultData}
            scopeName="group"
            onSubmitScope={handleTagSubmit}
          />
        )}
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <S.Container>
            <Stack spacing="md">
              <GroupsInformations isDisabled={isDisabled} />
              <RulesFlow
                isDisabled={isDisabled}
                value={flow}
                setValue={setFlow}
              />
              <Model
                isDisabled={isDisabled}
                value={model}
                setValue={setModel}
              />
              <GroupsActionsForm isDisabled={isDisabled} />
            </Stack>
          </S.Container>
          {!isDisabled && (
            <S.ButtonsContainer>
              <Button
                type="submit"
                colorScheme="green"
                rightIcon={<Icon as={HiArrowRight} />}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                data-testid="submit-button"
              >
                {defaultData?.id ? 'Publicar Alterações' : 'Publicar Grupo'}
              </Button>
            </S.ButtonsContainer>
          )}
        </form>
      </FormProvider>
      <ReviewModal
        isOpen={isModalOpen}
        onClose={handleModalOpen}
        action={handleConfirmPublish}
        closeText="Cancelar"
        title="Confirmar alteração de grupo"
        description="Atenção você está alterando o fluxo de um segmento inteiro. Gostaria de continuar?"
        actionText="Confirmar alteração"
      />
    </>
  );
}
