import React from 'react';

import Form from './base';
import Node from '../nodes';
import { isEditingAtom, isEditingNodeAtom } from '../stores';

export default function FormFlows(): React.ReactElement {
  return (
    <Form
      isEditingAtom={isEditingAtom}
      isEditingNodeAtom={isEditingNodeAtom}
      NodeComponent={Node}
    />
  );
}
