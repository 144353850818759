import * as I from '../../../../interfaces/queue_management';
import clone from '../../../../utils/clone';

export const defaultQueueManagement = {} as I.QueueManagementModel;

function removeIrrelevantParameters(
  parameters: I.ParametersForName<I.FilterNames>,
  validParameterNames: string[]
) {
  Object.keys(parameters).forEach((key) => {
    if (!validParameterNames?.includes(key)) {
      delete parameters[key];
    }
  });
}

function convertParameterValue(
  value: unknown,
  type: string,
  name: string
): unknown {
  if (name === 'analysis/score' && type === 'float') {
    return typeof value === 'number' ? value / 100 : value;
  }

  if (
    Array.isArray(value) &&
    ['array of strings', 'array of ints'].includes(type)
  ) {
    return value.map((item) => item);
  }

  if (['int', 'float'].includes(type)) {
    return value ?? 0;
  }

  return value;
}

function ConvertParameters({
  filterName,
  parameters,
  filters,
}: {
  filterName: string;
  parameters?: I.ParametersForName<I.FilterNames>;
  filters: I.QueueManagementAPIFiltersModel[];
}): I.ParametersForName<I.FilterNames> | undefined {
  if (!parameters) return undefined;

  const filter = filters.find((item) => item.name === filterName);
  if (!filter) return undefined;

  const validParameterNames = filter.parameters?.map((param) => param.name);
  removeIrrelevantParameters(parameters, validParameterNames);

  return Object.entries(parameters).reduce((acc, [key, value]) => {
    const parameter = filter.parameters?.find((item) => item.name === key);

    if (parameter) {
      acc[key] = convertParameterValue(value, parameter.type, filterName);
    }

    return acc;
  }, {} as I.ParametersForName<I.FilterNames>);
}

export function convertFormToApiFormat(
  filters: I.QueueManagementAPIFiltersModel[],
  form: I.QueueManagementModel
): I.QueueManagementModel {
  const newForm: I.QueueManagementModel = clone(form);

  return {
    description: newForm.description,
    filters: newForm.filters.map((filter) => {
      const filterClone = clone(filter);

      const newFilter = {
        ...filterClone,
        parameters: ConvertParameters({
          filterName: filterClone.name,
          parameters: filterClone.parameters,
          filters,
        }),
      };

      return newFilter as I.QueueManagementFiltersModel;
    }),
    name: newForm.name,
    priority: newForm.priority,
    status: newForm.status,
    users: newForm.users,
  };
}
