import React from 'react';

import * as S from '../../styles/main';

import Header from '../../components/blocks/header';
import Body from '../../components/blocks';

const BlocksForm: React.FC = () => {
  return (
    <S.Main>
      <Header />
      <Body />
    </S.Main>
  );
};

export default BlocksForm;
