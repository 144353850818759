import React from 'react';
import CreateIcon from '../../icons/create';
import { ReactComponent as CreateClient } from '../../../assets/icons/createClient.svg';
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg';

type addRowPosition = 'first' | 'last';
const first: addRowPosition = 'first';

export const editOptions = {
  search: true,
  selection: false,
  sorting: false,
  draggable: false,
  exportButton: false,
  pageSizeOptions: [10, 25, 50],
  pageSize: 10,
  emptyRowsWhenPaging: false,
  debounceInterval: 500,
  actionsColumnIndex: -1,
  addRowPosition: first,
  actionsCellStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '75px',
    padding: '8px 15px',
  },
};

export const createOptions = {
  search: false,
  selection: false,
  exportButton: false,
  sorting: false,
  draggable: false,
  pageSizeOptions: [10, 25, 50],
  pageSize: 10,
  emptyRowsWhenPaging: false,
  debounceInterval: 500,
  actionsColumnIndex: -1,
  addRowPosition: first,
  actionsCellStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '75px',
    padding: '8px 15px',
  },
};

export const clientIcons = {
  Add: (): React.ReactElement => (
    <div style={{ marginLeft: 'auto' }}>
      <CreateIcon img={<CreateClient />} text="Novo Item" />
    </div>
  ),
  Delete: (): React.ReactElement => <Trash />,
};
