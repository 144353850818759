import React from 'react';
import { PrimitiveAtom, useAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import { Stack, Text } from '@chakra-ui/react';

import InputText from '../../base/v2/inputs/text';
import InputSelect from '../../base/v2/inputs/select';
import { FlowModel } from '../../../interfaces/flow';
import { BlocksModel } from '../../../interfaces/blocks';

interface InformationBaseEditBaseProps {
  initialDataAtom: PrimitiveAtom<FlowModel> | PrimitiveAtom<BlocksModel>;
  isEditingAtom: PrimitiveAtom<boolean>;
  titleText: string;
  label: string;
  dataTestId: string;
}

export default function InformationBaseEditBase({
  dataTestId,
  initialDataAtom,
  isEditingAtom,
  label,
  titleText,
}: InformationBaseEditBaseProps): React.ReactElement {
  const [initialData] = useAtom(initialDataAtom);
  const [isEditing] = useAtom(isEditingAtom);

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack
      data-testid={dataTestId}
      spacing="medium"
      background="white"
      p="medium"
      borderRadius="extra-large"
      mb="16px"
    >
      <Text fontWeight="bold">{titleText}</Text>
      <Stack direction="row" spacing="medium">
        <InputText
          label={label}
          {...register('description')}
          formcontrol={{
            isDisabled: !isEditing,
            isRequired: true,
            error: errors.description,
            style: { width: '25%' },
          }}
        />

        <InputSelect
          label="Permitir revisão"
          options={[
            { option: 'Sim', value: 'true' },
            { option: 'Não', value: 'false' },
          ]}
          {...register('allow_review')}
          formcontrol={{
            isDisabled: Boolean(initialData.id),
            isRequired: true,
            error: errors.description,
          }}
        />
      </Stack>
    </Stack>
  );
}
