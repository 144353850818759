/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { ThresholdsType } from '../components/submenu/interfaces';

export const handleSLA = (slaNumber: number) => {
  const thresholds = [
    { limit: 0, color: 'red' },
    { limit: 7200, color: 'orange' },
    { limit: Infinity, color: 'green' },
  ] as ThresholdsType[];

  const { color } = thresholds.find(({ limit }) => slaNumber < limit)!;

  return color;
};

export default handleSLA;
