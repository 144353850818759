import React, { useState } from 'react';
import { GroupsProvider } from '../../../providers/groups';
import GroupsFormContainer from '../../../container/groups/forms';
import GenericForm from '../../../components/base/v2/genericForm';

const GroupsCreate: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <GroupsProvider>
      <GenericForm
        breadcrumb={[
          { label: 'Início', href: '/' },
          { label: 'Grupos', href: '/groups' },
          { label: 'Cadastro' },
        ]}
        title="Cadastro de Grupo"
        showBackButton
        backButtonUrl="/groups"
      >
        <GroupsFormContainer
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
        />
      </GenericForm>
    </GroupsProvider>
  );
};

export default GroupsCreate;
