import React from 'react';
import { Skeleton } from '@chakra-ui/react';

const ProfileSkeleton: React.FC = () => (
  <>
    <section className="header-content">
      <div className="avatar">
        <Skeleton variant="circle" width={100} height={100} />
      </div>
      <div className="name">
        <Skeleton variant="text" height={50} />
        <Skeleton variant="text" height={40} />
      </div>
    </section>
    <section className="body-content">
      <div className="cards-content">
        <Skeleton className="card" variant="rect" width={355.5} height={152} />

        <Skeleton className="card" variant="rect" width={355.5} height={152} />

        <Skeleton className="card" variant="rect" width={355.5} height={152} />

        <Skeleton className="card" variant="rect" width={355.5} height={152} />
      </div>

      <Skeleton
        className="notify-content"
        variant="rect"
        width={225}
        height={328.3}
      />
    </section>
  </>
);

export default ProfileSkeleton;
