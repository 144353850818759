import React from 'react';
import { GridItem } from '@chakra-ui/react';
import * as S from './styles';
import Stat from '../../../stat';
import { theme } from '../../../../styles/theme';
import Badge from '../../../badge';
import { checkString } from '../../../../utils/check_values';
import { dateExpiresFormater } from '../../../../utils/format_date';
import * as I from '../../../../interfaces/order';
import { handleBrands } from './utils';

interface CreditCardProps {
  payment: I.PaymentModel;
}

const CreditCard = ({ payment }: CreditCardProps): React.ReactElement => {
  return (
    <S.CreditCardContainer>
      <GridItem colSpan={2}>{handleBrands(payment.brand)}</GridItem>
      <GridItem colSpan={3} style={{ justifySelf: 'end' }}>
        <Badge
          label={`${checkString(payment.level)} - ${checkString(
            payment['type@display'].toUpperCase()
          )}`}
          schema="green"
          fontSize="small"
        />
      </GridItem>
      <GridItem colSpan={5}>
        <Stat
          title="Titular"
          fontColor={theme.colors.v2.white[100]}
          fontWeight={400}
          text={checkString(payment.holder_name)}
          identifier="name"
          copy={checkString(payment.holder_name)}
        />
      </GridItem>
      <GridItem colSpan={3}>
        <Stat
          title="Número do cartão"
          fontColor={theme.colors.v2.white[100]}
          fontWeight={400}
          text={`${checkString(payment.bin)}****${checkString(payment.last_4)}`}
          identifier="name"
          copy={`${checkString(payment.bin)}****${checkString(payment.last_4)}`}
        />
      </GridItem>
      <GridItem colSpan={2} style={{ justifySelf: 'end' }}>
        <Stat
          title="Válido até:"
          fontColor={theme.colors.v2.white[100]}
          fontWeight={400}
          text={dateExpiresFormater(payment.expires_at || null)}
          identifier="name"
          copy={dateExpiresFormater(payment.expires_at || null)}
        />
      </GridItem>
    </S.CreditCardContainer>
  );
};

export default CreditCard;
