import styled from 'styled-components';

export const Main = styled.main`
  .MuiInputBase-root {
    max-width: 175px;
    background: white;

    input {
      height: 40px;
      padding: 0 0 0 10px;
    }

    button {
      color: ${({ theme }) => theme.colors.blue[500]};
      padding: 0;
    }
  }
`;

export default Main;
