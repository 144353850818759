import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto ${({ theme }) => theme.spacing(3)}px;
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
`;

export const NodeContainer = styled.div`
  width: 100%;
  margin: 0 auto ${({ theme }) => theme.spacing(3)}px;
  border-radius: 0.625rem;
`;
