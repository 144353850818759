import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';

import * as I from './interfaces';

export default function Breadcrumb({
  items,
}: I.BreadcrumbProps): React.ReactElement {
  const isLastItem = (index: number): boolean => index === items.length - 1;

  const getLinkHoverStyle = (index: number): React.CSSProperties =>
    isLastItem(index)
      ? { textDecoration: 'none', cursor: 'auto' }
      : { textDecoration: 'underline', cursor: 'pointer' };

  const getTextColor = (index: number): string =>
    isLastItem(index) ? 'neutral.700' : 'neutral.500';

  return (
    <Box>
      <ChakraBreadcrumb
        spacing="smallest"
        color="neutral.400"
        fontSize="medium"
      >
        {items.map((item, index) => {
          const key = `breadcrumb-item_${item.label}_${index}`;

          return (
            <BreadcrumbItem key={key} isCurrentPage={isLastItem(index)}>
              {item.href ? (
                <BreadcrumbLink
                  as={NavLink}
                  to={item.href}
                  data-testid={`breadcrumb-link-${index}`}
                  color={getTextColor(index)}
                  _hover={getLinkHoverStyle(index)}
                >
                  {item.label}
                </BreadcrumbLink>
              ) : (
                <BreadcrumbLink
                  data-testid={`breadcrumb-link-${index}`}
                  color={getTextColor(index)}
                  _hover={getLinkHoverStyle(index)}
                >
                  {item.label}
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          );
        })}
      </ChakraBreadcrumb>
    </Box>
  );
}
