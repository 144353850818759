import React from 'react';

import GenericForm from '../../../components/base/v2/genericForm';
import ActionsListForm from '../../../components/forms/actions_list';

const ActionsListCreate: React.FC = () => {
  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Listas', href: '/actions-list' },
        { label: 'Cadastro' },
      ]}
      title="Cadastrar listas"
      showBackButton
      backButtonUrl="/actions-list"
    >
      <ActionsListForm isDisabled={false} initialData={null} />
    </GenericForm>
  );
};

export default ActionsListCreate;
