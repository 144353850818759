import React, { useEffect, useState } from 'react';
import { AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import { Query, QueryResult } from '@material-table/core';
import { useToastFunctions } from '../../../../utils/useToastFunction';
import useTag from '../../../../hooks/api/tags';
import DefaultTable, {
  fetchDataTable,
} from '../../../../components/base/v2/table';
import { CustomToolbarComponent } from '../../../../components/base/v2/table/components';
import {
  createdAtColumn,
  TagColumn,
} from '../../../../components/base/v2/table/columns';
import { TagModel } from '../../../../interfaces/tag';

import * as S from '../../styles';
import * as I from '../../interfaces';

export const TagsView = ({
  reloadTable,
  tableRef,
  item,
}: I.ClientGroupViewTagProps): React.ReactElement => {
  const [search, setSearch] = useState('');
  const { getAllTags } = useTag();
  const { showToast } = useToastFunctions();

  useEffect(() => {
    reloadTable();
  }, [search]);

  const loadData = (query: Query<TagModel>): Promise<QueryResult<TagModel>> => {
    const showErrorToast = () => {
      showToast({
        title: `Erro ao buscar as tags vinculadas ao ${item.title}`,
        description: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
        status: 'error',
      });
    };

    return fetchDataTable(
      query,
      (fetchQuery) =>
        getAllTags({ search, query: fetchQuery, scope: item.scope }),
      undefined,
      showErrorToast
    );
  };

  const tableColumns = [TagColumn(item.scope), createdAtColumn];

  return (
    <S.StyledAccordion defaultIndex={[0]} allowMultiple>
      <S.StyledAccordionItem>
        <S.AccordionHeader>
          <S.AccordionTitle>{item.title}</S.AccordionTitle>
          <S.HeaderButtonContainer>
            <CustomToolbarComponent
              label="Buscar"
              width="100%"
              padding="none"
              marginBottom="none"
              toolbarProps={{
                onSearchChanged: (value) => {
                  setSearch(value);
                },
              }}
            />
            <S.StyledAccordionButton>
              <AccordionIcon />
            </S.StyledAccordionButton>
          </S.HeaderButtonContainer>
        </S.AccordionHeader>
        <AccordionPanel p={4}>
          <DefaultTable
            columns={tableColumns}
            data={(query) => loadData(query)}
            hasSearch={false}
            tableRef={tableRef}
            options={{
              pageSize: 5,
            }}
          />
        </AccordionPanel>
      </S.StyledAccordionItem>
    </S.StyledAccordion>
  );
};

export default TagsView;
