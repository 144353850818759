import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRulesModels } from '../../../providers/models';
import { handleSubmitAndCloseModal } from '../../../components/complex/form/utils';
import { handleTagsFormToScopeModel } from '../../../components/bondTags/utils';

import GenericFooter from '../../../components/complex/form/generic/footer';
import DoubleCheckModal from '../../../components/base/v2/DoubleCheckModal';
import validateSchema from '../../../validations/models';
import CardsGroup from './cardsGroup';
import Submenu from './submenu';
import CloneModal from '../clone';
import BondTags from '../../../components/bondTags';
import useModels from '../../../hooks/api/models';

import * as S from './styles';
import * as I from '../../../interfaces/models';
import * as I2 from '../interfaces';
import { FormToScopeModelType } from '../../../components/bondTags/interfaces';

const ModelsUpsertForm = ({
  onSubmit,
  isOpenModal,
  onCloseModal,
}: I2.ModelFormProps): React.ReactElement => {
  const [isLoaded, setIsLoaded] = useState(true);
  const { updateModels } = useModels();
  const { dataContext: model, hasID, getNewVersion } = useRulesModels();
  const { onOpen, onClose: onSubmitClose, isOpen } = useDisclosure();
  const {
    onOpen: onDescartOpen,
    onClose: onDescartClose,
    isOpen: isDescartOpen,
  } = useDisclosure();

  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: model,
  });

  const handleDescart = async (): Promise<void> => {
    setIsLoaded(false);
    methods.reset(model);

    setTimeout(() => {
      onDescartClose();
      setIsLoaded(true);
    }, 300);
  };

  const onSubmitForm = (data: I.ModelsModel) => {
    onSubmit({
      data,
      id: model.id,
      version: model.version,
    });
  };

  const handleTagSubmit = async ({
    data,
    type,
    scope,
  }: FormToScopeModelType) => {
    const formData = handleTagsFormToScopeModel({
      scope: model,
      data,
      type,
    });

    return updateModels({
      id: scope.id,
      data: formData,
      version: scope.version,
    }).finally(() => {
      getNewVersion();
    });
  };

  const handleSubmit = handleSubmitAndCloseModal<I.ModelsModel>({
    methods,
    submitFunction: onSubmitForm,
    onSubmitClose,
  });

  return (
    <>
      {model.id && (
        <BondTags
          scope={model}
          scopeName="model"
          onSubmitScope={handleTagSubmit}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit}>
          <S.Container>
            <Submenu />
          </S.Container>
          <S.NodeContainer>
            <CardsGroup />
          </S.NodeContainer>

          <GenericFooter
            publishNewText="Publicar modelo"
            publishUpdateText="Publicar alterações"
            alertText="Revise as informações antes de publicar o modelo."
            onDescartOpen={onDescartOpen}
            onOpen={onOpen}
            hasID={Boolean(hasID)}
            isEditing
          />

          <DoubleCheckModal
            title="Publicar novo modelo"
            description="Ao publicar as alterações, as modificações serão automaticamente aplicadas ao modelo vinculado."
            isOpen={isOpen}
            onClose={onSubmitClose}
            modal={{ size: 'xl' }}
            primaryButton={{
              colorScheme: 'green',
              text: 'Publicar Modelo',
              action: handleSubmit,
            }}
            isLoading={methods.formState.isSubmitting}
          />

          <DoubleCheckModal
            title="Descartar alterações da fila"
            description="Ao descartar as alterações que você está fazendo agora, o formulário voltará ao estado da última atualização, eliminando todas as modificações que você fez na edição atual. Tem certeza que deseja prosseguir?"
            isOpen={isDescartOpen}
            onClose={onDescartClose}
            modal={{ size: 'xl' }}
            primaryButton={{
              colorScheme: 'green',
              text: 'Descartar alterações',
              action: handleDescart,
            }}
            isLoading={!isLoaded}
          />
          <CloneModal isOpenModal={isOpenModal} onClose={onCloseModal} />
        </form>
      </FormProvider>
    </>
  );
};

export default ModelsUpsertForm;
