import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MdQueuePlayNext } from 'react-icons/md';

import { useKeycloak } from '@react-keycloak/web';
import { toast } from 'react-toastify';
import {
  IdColumn,
  orderColumnName,
  orderColumnSLALimit,
  orderColumnDocument,
  orderColumnEmail,
  orderColumnPaymentsAmount,
  orderColumnQueueTime,
  orderColumnCreatedAt,
  orderColumnDate,
  orderColumnStatus,
  orderColumnReviewer,
} from '../../components/base/v2/table/columns';
import GenericListPage from '../genericList';
import useOrders from '../../hooks/api/orders';
import { OrderModel } from '../../interfaces/order';
import useNavegate from '../../hooks/navegate';
import useDocumentTitle from '../../hooks/documentTitle';
import StatusItem from '../../components/base/v2/statusItem';
import { StatusContainer } from '../../components/base/v2/statusItem/styles';
import checkMenuRoles from '../../utils/check_menu_roles';
import { OrdersAPIsQueryType } from '../../hooks/api/orders/interfaces';
import { cleanFilters } from '../../components/base/v2/table/stores';

const QueuesList: React.FC = () => {
  useDocumentTitle('Trex - Filas');

  const { keycloak } = useKeycloak();
  const { push } = useHistory();
  const { getQueuesOrders, getFilteredOrders, getNextQueue } = useOrders();
  const { savePage } = useNavegate();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  const handleNextQueue = (): Promise<void> =>
    new Promise((resolve, reject) => {
      savePage();
      getNextQueue()
        .then((result) => {
          if (result.status === 204) {
            toast.error('Não existem pedidos na fila atual. Tente outra fila.');
          }
          if (result.data) {
            push({
              pathname: `/orders/info/${result.data.id}`,
              state: {
                from: '/filas',
              },
            });
          }
          resolve();
        })
        .catch((err) => {
          if (err.response.status === 409) {
            toast.error(
              'Erro ao buscar o próximo pedido! Algum analista já pegou esse pedido antes, buscando por outro pedido.'
            );

            return handleNextQueue();
          }
          resolve();
          return reject(
            toast.error(
              'Erro ao buscar o próximo pedido. Não foi possível iniciar a revisão.'
            )
          );
        });
    });

  const roles = checkMenuRoles(keycloak);
  const table = [
    {
      fetch: {
        apiCall: (props: OrdersAPIsQueryType) =>
          getFilteredOrders({
            ...props,
            params: {
              'hide-suspended': true,
              status: 'pending',
            },
          }),
        title: 'fila',
      },
      body: {
        title: 'Geral',
        columns: [
          orderColumnSLALimit,
          orderColumnName('/filas'),
          IdColumn,
          orderColumnDocument,
          orderColumnEmail,
          orderColumnPaymentsAmount,
          orderColumnQueueTime,
          orderColumnCreatedAt('asc'),
          orderColumnDate,
          orderColumnStatus({}),
          orderColumnReviewer,
        ],
        searchLabel:
          'Busque por loja, ID da loja, documento, email ou ID de um pedido',
        accessURL: '/orders/info',
        searchMinWidth: '621px',
        isSearch: false,
        filters: { dateFromTo: true, filteredBy: true },
      },
    },
    {
      fetch: {
        apiCall: (props: OrdersAPIsQueryType) =>
          getFilteredOrders({
            ...props,
            params: {
              'all-delayed': true,
            },
          }),
        title: 'agendado',
      },
      body: {
        title: 'agendado',
        columns: [
          orderColumnSLALimit,
          orderColumnName('/filas'),
          IdColumn,
          orderColumnDocument,
          orderColumnEmail,
          orderColumnPaymentsAmount,
          orderColumnQueueTime,
          orderColumnCreatedAt('asc'),
          orderColumnDate,
          orderColumnStatus({}),
          orderColumnReviewer,
        ],
        searchLabel:
          'Busque por loja, ID da loja, documento, email ou ID de um pedido',
        accessURL: '/orders/info',
        searchMinWidth: '621px',
        isSearch: false,
        filters: { dateFromTo: true, filteredBy: true },
      },
    },
    {
      fetch: {
        apiCall: getQueuesOrders,
        title: 'fila',
      },
      body: {
        title: 'Pessoal',
        columns: [
          orderColumnSLALimit,
          orderColumnName('/filas'),
          IdColumn,
          orderColumnDocument,
          orderColumnEmail,
          orderColumnPaymentsAmount,
          orderColumnQueueTime,
          orderColumnCreatedAt('asc'),
          orderColumnDate,
          orderColumnStatus({}),
          orderColumnReviewer,
        ],
        searchLabel:
          'Busque por loja, ID da loja, documento, email ou ID de um pedido',
        accessURL: '/orders/info',
        searchMinWidth: '621px',
        isSearch: false,
        filters: { filteredBy: true },
      },
    },
  ];

  if (roles.includes('resolve_analysis_withhold')) {
    table.push({
      fetch: {
        apiCall: (props: OrdersAPIsQueryType) =>
          getFilteredOrders({ ...props, params: { 'only-withheld': true } }),
        title: 'retenção',
      },
      body: {
        title: 'Retenção',
        columns: [
          orderColumnSLALimit,
          orderColumnName('/filas'),
          IdColumn,
          orderColumnDocument,
          orderColumnEmail,
          orderColumnPaymentsAmount,
          orderColumnQueueTime,
          orderColumnCreatedAt('asc'),
          orderColumnDate,
          orderColumnStatus({}),
          orderColumnReviewer,
        ],
        searchLabel:
          'Busque por loja, ID da loja, documento, email ou ID de um pedido',
        accessURL: '/orders/info',
        searchMinWidth: '621px',
        isSearch: false,
        filters: { dateFromTo: true, filteredBy: true },
      },
    });
  }

  return (
    <GenericListPage<OrderModel>
      actionButton={{
        text: 'Iniciar próximo pedido',
        action: handleNextQueue,
        icon: MdQueuePlayNext,
      }}
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Pedidos em fila' }],
      }}
      title="Pedidos em fila"
      table={table}
    >
      <StatusContainer>
        <StatusItem
          label="Pedidos Pendentes"
          colorScheme="orange"
          state="ready"
          mr="16px"
        />
        <StatusItem
          label="Pedidos Agendados"
          colorScheme="gray"
          state="delayed"
          mr="16px"
        />
        <StatusItem
          label="Pedidos em andamento"
          colorScheme="blue"
          state="in-review"
        />
      </StatusContainer>
    </GenericListPage>
  );
};

export default QueuesList;
