/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */

import {
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { BlocksModel } from '../../interfaces/blocks';
import * as I from '../../interfaces/flow';

interface ConvertParametersProps {
  ruleName: string;
  parameters?: I.ParametersForm;
  rules: I.RuleModel[];
}

function toBoolean(value: boolean | string): boolean {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true';
  }
  return value;
}

function toBooleanAndNull(value: boolean | string | null): boolean | null {
  if (typeof value === 'string') {
    return JSON.parse(value.toLowerCase());
  }
  return value;
}

function removeIrrelevantParameters(
  parameters: I.ParametersForm,
  validParameterNames: string[]
) {
  Object.keys(parameters).forEach((key) => {
    if (!validParameterNames?.includes(key)) {
      delete parameters[key];
    }
  });
}

function convertParameterValue(value: any, type: string): any {
  if (
    Array.isArray(value) &&
    ['array of strings', 'array of ints'].includes(type)
  ) {
    return value.map((item) => item);
  }

  if (['int', 'float'].includes(type)) {
    return value ?? 0;
  }

  if (type === 'boolean') {
    return toBooleanAndNull(value);
  }

  if (type === 'duration') {
    return new Date(value).toISOString();
  }

  return value;
}

function ConvertParameters({
  ruleName,
  parameters,
  rules,
}: ConvertParametersProps): I.Parameters | undefined {
  if (!parameters) return undefined;

  const rule = rules.find((item) => item.name === ruleName);
  if (!rule) return undefined;

  const validParameterNames = rule.parameters?.map((param) => param.name);
  removeIrrelevantParameters(parameters, validParameterNames);

  return Object.entries(parameters).reduce((acc, [key, value]) => {
    const parameter = rule.parameters?.find((item) => item.name === key);

    if (parameter) {
      acc[key] = convertParameterValue(value, parameter.type);
    }

    return acc;
  }, {} as I.Parameters);
}

export function convertFormToApiFormat(
  rules: I.RuleModel[],
  form: I.FluxModelForm
): I.FlowModel | BlocksModel {
  return {
    ...form,
    allow_review: toBoolean(form.allow_review),
    nodes: form.nodes.map((node) => {
      const newNode = {
        ...node,
        terms: node.terms.map((term) => ({
          ...term,
          complement: toBoolean(term.complement),
          rule: {
            name: term.rule.name,
            parameters: ConvertParameters({
              ruleName: term.rule.name,
              parameters: term.rule.parameters,
              rules,
            }),
          },
        })),
      };

      if (newNode.action !== '') {
        delete newNode.relay_to;
      }

      return newNode;
    }),
  };
}

export const defaultFlow = {
  allow_review: false,
  description: '',
  nodes: [
    {
      title: '',
      operator: '',
      action: 'approve',
      error_policy: '',
      terms: [
        {
          complement: false,
          error_policy: '',
          rule: {
            name: '',
          },
        },
      ],
    },
  ],
};

export function getStyle(
  snapshot: DraggableStateSnapshot,
  dragStyle?: DraggingStyle | NotDraggingStyle
) {
  if (!snapshot.isDropAnimating) {
    return dragStyle;
  }
  return {
    ...dragStyle,
    transitionDuration: `0.05s`,
  };
}
