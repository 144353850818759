import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto ${({ theme }) => theme.spacing(3)}px;
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
`;

export const Header = styled.div`
  width: 100%;
  min-height: ${({ theme }) => theme.spacing(11.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
  border-radius: ${({ theme }) => theme.spacing(2)}px;

  p {
    font-size: 0.75rem;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(3)}px;
  height: ${({ theme }) => theme.spacing(11.5)}px;
`;
