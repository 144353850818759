import React from 'react';
import { GridItem } from '@chakra-ui/react';
import { checkPhone, checkString } from '../../../../utils/check_values';
import jaro from '../../../../utils/jaro';
import BondsText from '../../../bonds';

import Stat from '../../../stat';

import * as I from './interfaces';
import * as S from './styles';
import { removePhoneFormat } from '../../../../utils/removePhoneFormat';

export default function Shipping({
  shipping,
  order,
}: I.ShippingProps): React.ReactElement {
  const name = checkString(shipping.receiver?.name);
  const phone = checkPhone({
    value: shipping.receiver?.phone?.full_number,
  });
  const freight = checkString(shipping['cost@display']);
  const address = checkString(shipping.address?.full_address);

  const similarityName = jaro(shipping.receiver?.name, order?.buyer?.name);
  const similarityPhone = jaro(
    shipping.receiver?.phone?.full_number,
    order?.buyer?.name
  );
  const similarityAddress = jaro(
    shipping.address?.full_address,
    order?.buyer?.address?.full_address
  );

  return (
    <S.GridContainer>
      <GridItem rowSpan={1} colSpan={2}>
        <Stat title="Nome" identifier="order_shippings_name" copy={name}>
          <BondsText text={name} similarity={similarityName} />
        </Stat>
      </GridItem>

      <GridItem rowSpan={1} colSpan={2}>
        <Stat
          title="Contato"
          identifier="order_shippings_phone"
          copy={removePhoneFormat(phone)}
        >
          <BondsText text={phone} similarity={similarityPhone} />
        </Stat>
      </GridItem>

      <GridItem rowSpan={1} colSpan={1}>
        <Stat
          title="Frete"
          text={freight}
          identifier="order_shippings_freight"
          copy={freight}
        />
      </GridItem>

      <GridItem rowSpan={2} colSpan={5}>
        <Stat
          title="Endereço"
          identifier="order_shippings_address"
          copy={address}
        >
          <BondsText text={address} similarity={similarityAddress} />
        </Stat>
      </GridItem>
    </S.GridContainer>
  );
}
