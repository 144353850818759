import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto 25px;
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
`;

export const AddButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 25px 0;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
  height: 92px;
`;
