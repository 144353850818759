import React, { useEffect, useState } from 'react';
import useOrders from '../../../../hooks/api/orders';
import LoadingSpinner from '../../../loadingSpinner';

import * as I from './interfaces';
import * as S from './styles';

export default function StatusItem({
  label,
  colorScheme,
  state,
  mr,
}: Readonly<I.StatusItemProps>): React.ReactElement {
  const [info, setInfo] = useState<I.StatusInfoResponseType>();
  const { getOrdersInfo } = useOrders();

  const handleInfo = async () => {
    const { data } = await getOrdersInfo({ state });
    setInfo(data);
  };

  useEffect(() => {
    handleInfo();
  }, []);

  if (!info) {
    return (
      <S.StatusItem mr={mr}>
        <LoadingSpinner />
      </S.StatusItem>
    );
  }

  return (
    <S.StatusItem mr={mr}>
      <S.NumberContainer>
        <S.ContainerIndicator>
          <S.ColorIndicatorBigger
            bg={`${colorScheme}.300`}
            data-testid="color-indicator-1"
          />

          <S.ColorIndicatorSmaller
            bg={`${colorScheme}.500`}
            data-testid="color-indicator-2"
          />
        </S.ContainerIndicator>

        <S.NumberLabel>{info.total_of_items}</S.NumberLabel>
      </S.NumberContainer>
      <S.Label>{label}</S.Label>
    </S.StatusItem>
  );
}
