import React, { useEffect, useState, useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Chart from 'react-apexcharts';
import {
  Box,
  Flex,
  Text,
  theme,
  Avatar,
  VStack,
  Button,
  Icon,
  HStack,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  SimpleGrid,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Main } from './Main';
import * as I from './interfaces';
import * as IUser from '../../interfaces/user';

import ProfileSkeleton from './skeleton';
import Title from '../../components/base/title';
import { H2 } from '../../components/base/Headings';
import { dateFormaterAmerican } from '../../utils/format_date';
import { trexApi } from '../../services/api';
import { SETID } from '../../services/storage';
import useDocumentTitle from '../../hooks/documentTitle';

const Profile: React.FC = () => {
  useDocumentTitle('Trex - Profile');
  const UUID = localStorage.getItem(SETID);
  const { keycloak } = useKeycloak();
  const [user, setUser] = useState<IUser.UserModel>();
  const [metrics, setMetrics] = useState<I.Metrics>();
  const [metricsAVGS, setMetricsAVGS] = useState<I.MetricsAVGS>();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const today = new Date();
  const dayOne = new Date(`${today.getFullYear()}-${today.getMonth() + 1}-01`);
  const [selectedDate, setSelectedDate] = useState<I.SelectedDate>({
    from: dayOne,
    to: today,
  });

  const handleValues = (number?: number): number => {
    if (number) {
      return number;
    }
    return 0;
  };

  const toKeycloakProfile = (): void => {
    keycloak.accountManagement();
  };

  const tryLoadInfo = useCallback((): void => {
    keycloak.loadUserProfile().then((response) => {
      setUser(response);
      setIsLoading(false);
    });
  }, [keycloak]);

  const getMetrics = async (): Promise<void> => {
    const response = await trexApi.get(
      `users/metrics?start-date=${dateFormaterAmerican(
        selectedDate.from
      )}&end-date=${dateFormaterAmerican(selectedDate.to)}&interval=daily`,
      {
        headers: {
          TZ: Intl.DateTimeFormat().resolvedOptions().timeZone,
          'X-SetID': UUID,
        },
      }
    );

    setMetricsAVGS(response.data.avgs);

    setMetrics({
      ...metrics,
      approvalRate: await response.data.buckets.map((item: I.MetricsResponse) =>
        (item.data.approval_rate * 100).toFixed(1)
      ),
      averageAnalysisTime: await response.data.buckets.map(
        (item: I.MetricsResponse) =>
          (item.data.average_analysis_time / 60).toFixed(1)
      ),
      chargebackRate: await response.data.buckets.map(
        (item: I.MetricsResponse) =>
          (item.data.chargeback_rate * 100).toFixed(1)
      ),
      finishedTransactions: await response.data.buckets.map(
        (item: I.MetricsResponse) => item.data.finished_transactions.toFixed(1)
      ),
      labels: await response.data.buckets.map(
        (item: I.MetricsResponse) => item.label
      ),
    });
  };

  const handleRefresh = (): void => {
    setIsRefreshing(true);
    getMetrics();

    setTimeout(() => {
      setIsRefreshing(false);
    }, 1000);
  };

  useEffect(() => {
    getMetrics();
  }, [selectedDate]);

  useEffect(() => {
    if (keycloak.authenticated) {
      tryLoadInfo();
    }
  }, [keycloak.authenticated]);

  const options = (): object => {
    return {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        foreColor: theme.colors.gray[300],
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        colors: [theme.colors.blue[900]],
      },
      xaxis: {
        type: 'datetime',
        axisBorder: {
          color: theme.colors.gray[600],
        },
        axisTicks: {
          color: theme.colors.gray[600],
        },
        categories: metrics?.labels,
      },
      fill: {
        opacity: 0.3,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          opacityFrom: 0.5,
          opacityTo: 0.1,
        },
      },
    };
  };

  const optionsBar = (): object => {
    return {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        foreColor: theme.colors.gray[500],
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        colors: [theme.colors.blue[900]],
      },
      xaxis: {
        type: 'datetime',
        axisBorder: {
          color: theme.colors.gray[600],
        },
        axisTicks: {
          color: theme.colors.gray[600],
        },
        categories: metrics?.labels,
      },
    };
  };

  const series = (type?: string): Array<object> => {
    if (type === 'Aprovados') {
      return [
        {
          name: 'Aprovados',
          data: metrics?.approvalRate,
        },
      ];
    }

    if (type === 'Chargeback') {
      return [
        {
          name: 'Chargeback',
          data: metrics?.chargebackRate,
        },
      ];
    }

    if (type === 'Finalizados') {
      return [
        {
          name: 'Finalizados',
          data: metrics?.finishedTransactions,
        },
      ];
    }

    return [
      {
        name: 'Tempo médio',
        data: metrics?.averageAnalysisTime,
      },
    ];
  };

  return (
    <>
      <Box
        position="absolute"
        bg="red.400"
        w="100%"
        h="160px"
        top="60px"
        zIndex="-1"
      />

      <Main>
        <Title color="white">Perfil</Title>

        {isLoading ? (
          <ProfileSkeleton />
        ) : (
          <Box position="relative">
            <Stack
              direction="row"
              spacing="8"
              m="120px auto 30px"
              justify="space-between"
            >
              <Box
                as="aside"
                maxW="320px"
                mt="-94px"
                bg="white"
                borderRadius="md"
              >
                <Box as="section" boxShadow="base" p={8} position="relative">
                  <Button
                    type="button"
                    position="absolute"
                    top="10px"
                    right="10px"
                    bg="none"
                    onClick={toKeycloakProfile}
                  >
                    <Icon as={FaExternalLinkAlt} />
                  </Button>
                  <VStack spacing="md" textAlign="center">
                    <Box
                      as="label"
                      p="5px"
                      w="150px"
                      h="148px"
                      borderWidth="5px"
                      borderColor="blue.900"
                      borderRadius="50%"
                    >
                      <Avatar size="2xl" name={user?.firstName} />
                    </Box>

                    <H2 fontSize="lgg">{`${user?.firstName} ${user?.lastName}`}</H2>
                    <VStack spacing="xs">
                      <Text>{user?.attributes?.role}</Text>
                      <Text>{user?.username}</Text>
                    </VStack>
                  </VStack>
                </Box>
              </Box>

              <VStack spacing="md" flex="1" position="relative">
                <HStack
                  top="-112px"
                  spacing="sm"
                  position="absolute"
                  align="flex-start"
                  mb="20px"
                  w="100%"
                >
                  <FormControl w="fit-content">
                    <FormLabel htmlFor="name" color="white">
                      Inicio
                    </FormLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        disableFuture
                        autoOk
                        invalidDateMessage="Data inválida"
                        maxDateMessage="A data não pode ser maior que o dia atual"
                        minDate={new Date('2012-06-22T12:00:00.000Z')}
                        minDateMessage="Data não pode ser menor do que 22/06/2012"
                        format="dd/MM/yyyy"
                        id="date-picker"
                        value={selectedDate?.from}
                        onChange={(e): void =>
                          setSelectedDate({ ...selectedDate, from: e })
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <FormErrorMessage>erro na data</FormErrorMessage>
                  </FormControl>

                  <FormControl w="fit-content">
                    <FormLabel htmlFor="name" color="white">
                      Fim
                    </FormLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        disableFuture
                        autoOk
                        invalidDateMessage="Data inválida"
                        maxDateMessage="A data não pode ser maior que o dia atual"
                        minDate={new Date('2012-06-22T12:00:00.000Z')}
                        minDateMessage="Data não pode ser menor do que 22/06/2012"
                        format="dd/MM/yyyy"
                        id="date-picker"
                        value={selectedDate.to}
                        onChange={(e): void =>
                          setSelectedDate({ ...selectedDate, to: e })
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <FormErrorMessage>erro na data</FormErrorMessage>
                  </FormControl>

                  <Box marginLeft="auto !important" mt="33px !important">
                    <Button
                      type="button"
                      colorScheme="blue"
                      onClick={handleRefresh}
                      isLoading={isRefreshing}
                    >
                      <Icon as={FiRefreshCcw} />
                    </Button>
                  </Box>
                </HStack>

                <SimpleGrid columns={2} spacing="md" w="100%">
                  <Box p="6" boxShadow="base" borderRadius="md">
                    <Flex justify="space-between" align="center">
                      <Text>Conversão</Text>
                      <Text as="span" fontWeight="bold" fontSize="xxl">
                        {(
                          handleValues(metricsAVGS?.approval_rate) * 100
                        )?.toFixed(1)}
                        %
                      </Text>
                    </Flex>
                    {metrics?.approvalRate && (
                      <Chart
                        options={optionsBar()}
                        series={series('Aprovados')}
                        type="bar"
                        height={160}
                      />
                    )}
                  </Box>

                  <Box p="6" boxShadow="base" borderRadius="md">
                    <Flex justify="space-between" align="center">
                      <Text>Tempo médio de análise</Text>
                      <Text
                        as="span"
                        fontWeight="bold"
                        fontSize="xxl"
                        textAlign="center"
                      >
                        {(
                          handleValues(metricsAVGS?.average_analysis_time) / 60
                        ).toFixed(1)}{' '}
                        min
                      </Text>
                    </Flex>
                    {metrics?.averageAnalysisTime && (
                      <Chart
                        options={options()}
                        series={series()}
                        type="area"
                        height={160}
                      />
                    )}
                  </Box>

                  <Box p="6" boxShadow="base" borderRadius="md">
                    <Flex justify="space-between" align="center">
                      <Text>Chargeback</Text>
                      <Text as="span" fontWeight="bold" fontSize="xxl">
                        {(
                          handleValues(metricsAVGS?.chargeback_rate) * 100
                        ).toFixed(1)}
                        %
                      </Text>
                    </Flex>
                    {metrics?.chargebackRate && (
                      <Chart
                        options={options()}
                        series={series('Chargeback')}
                        type="area"
                        height={160}
                      />
                    )}
                  </Box>

                  <Box p="6" boxShadow="base" borderRadius="md">
                    <Flex justify="space-between" align="center">
                      <Text>Finalizados</Text>
                      <Text as="span" fontWeight="bold" fontSize="xxl">
                        {metricsAVGS?.finished_transactions?.toFixed(1)}/dia
                      </Text>
                    </Flex>
                    {metrics?.finishedTransactions && (
                      <Chart
                        options={optionsBar()}
                        series={series('Finalizados')}
                        type="bar"
                        height={160}
                      />
                    )}
                  </Box>
                </SimpleGrid>
              </VStack>
            </Stack>
          </Box>
        )}
      </Main>
    </>
  );
};

export default Profile;
