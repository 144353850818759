import React from 'react';
import Body from '../../../components/rulesFlowInfo';
import GenericForm from '../../../components/base/v2/genericForm';

const RulesFlowCreate: React.FC = () => {
  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Fluxo de Regras', href: '/rules-flow' },
        { label: 'Novo Fluxo' },
      ]}
      title="Fluxos de regras"
      showBackButton
      backButtonUrl="/rules-flow"
      actionText="Editar Fluxo"
      isDisabled={false}
    >
      <Body />
    </GenericForm>
  );
};

export default RulesFlowCreate;
