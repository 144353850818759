/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  AccordionItem,
  AccordionIcon,
  Button,
  Text,
  Flex,
  Icon,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { RxPinLeft, RxPinRight } from 'react-icons/rx';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useOrder } from '../../../providers/order';
import ReviewModal from '../../reviewModal';

import { validationSchema, handleStatus } from '../utils';
import checkMenuRoles from '../../../utils/check_menu_roles';

import * as I from '../interface';
import * as S from '../styles';
import * as S2 from './styles';

const BaseBox = ({
  roleName,
  link,
  textMessage,
  dataTestId,
  selectOptions,
  valueOptions,
  handleSubmitForm,
}: I.BoxProps) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [boxReviewPositionX, setBoxReviewPositionX] = useState('right');

  const { keycloak } = useKeycloak();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { orderContext, loadOrder } = useOrder();
  const { push } = useHistory();

  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const { register, formState, handleSubmit, getValues } = methods;

  useEffect(() => {
    if (
      orderContext?.status === 'pending' ||
      orderContext?.status === 'ready'
    ) {
      setShow(true);
    }

    if (keycloak.authenticated) {
      const roles = checkMenuRoles(keycloak);

      if (roles.includes(roleName)) {
        setHasPermission(true);
      } else {
        setHasPermission(false);
      }
    }
  }, [orderContext]);

  const handleMoveBoxReview = () => {
    if (boxReviewPositionX === 'right') {
      setBoxReviewPositionX('left');
    } else {
      setBoxReviewPositionX('right');
    }
  };

  const handleOpenModal = (data: any) => {
    setFormData(data);
    onOpen();
  };

  const onSubmit = async (): Promise<void> => {
    onClose();
    setIsSubmiting(true);

    const values = getValues();

    try {
      const res = await handleSubmitForm({
        id: orderContext?.id,
        values,
        version: orderContext?.version,
      });

      setIsSubmiting(false);
      if ([200, 201].includes(res.request.status)) {
        toast.success(`${textMessage} concluida com sucesso!`);
        push(link);
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      toast.warn(`Não foi possível concluir a ${textMessage}: ${reason}`);
    } catch (error) {
      setIsSubmiting(false);
      toast.warn(`Não foi possível concluir a ${textMessage}`);
      await loadOrder();
    }
  };

  if (!show || !hasPermission) {
    return null;
  }

  return (
    <S.Container
      allowMultiple
      defaultIndex={[0]}
      data-testid={`${dataTestId}-boxReview-container`}
      positionX={boxReviewPositionX}
      maxHeight="350px"
    >
      <AccordionItem>
        <S.Row>
          {openAccordion ? (
            <Flex
              direction="row"
              borderBottom="1px solid"
              borderColor="v2.dark.100"
              alignItems="center"
              width="100%"
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing="smallest"
                flex="1"
                data-testid={`${dataTestId}-review-started-title`}
              >
                <Button
                  variant="ghost"
                  type="button"
                  onClick={handleMoveBoxReview}
                >
                  <Icon
                    as={boxReviewPositionX === 'right' ? RxPinLeft : RxPinRight}
                  />
                </Button>

                <S.TitleText>{textMessage} Iniciada</S.TitleText>
              </Stack>
              <Stack direction="row" alignItems="center">
                <S.StyledButton>
                  <AccordionIcon />
                </S.StyledButton>
              </Stack>
            </Flex>
          ) : (
            <S.ButtonsWrapper>
              <S.AnalysisButton
                id="btn-start-suspended-analysis"
                onClick={() => setOpenAccordion(true)}
                data-testid={`start-${dataTestId}-analysis-button`}
                width="180px"
              >
                <Text>Tomar uma ação</Text>
              </S.AnalysisButton>
            </S.ButtonsWrapper>
          )}
        </S.Row>
        <S.FormContainer>
          <FormProvider {...methods}>
            <S2.StyledForm onSubmit={handleSubmit(handleOpenModal)}>
              <S.TextArea
                {...register('message')}
                rows={7}
                placeholder="Comentário"
                disabled={isSubmiting || formState.isSubmitting}
              />
              <S.ButtonsContainer
                display="flex"
                direction="row"
                justify="space-between"
                positionX={boxReviewPositionX}
              >
                <S.StyledSelect
                  {...register('recommendation')}
                  placeholder="Definir finalização"
                  name="recommendation"
                  path="recommendation"
                  options={selectOptions}
                  values={valueOptions}
                  isDisabled={isSubmiting || formState.isSubmitting}
                  error={formState.errors?.recommendation}
                />
                <S.AnalysisButton
                  type="submit"
                  isLoading={isSubmiting || formState.isSubmitting}
                >
                  Salvar análise
                </S.AnalysisButton>
              </S.ButtonsContainer>
            </S2.StyledForm>
          </FormProvider>
        </S.FormContainer>
      </AccordionItem>
      <ReviewModal
        title={`${handleStatus(formData?.recommendation ?? '')} pedido`}
        description={`Você tem certeza que quer ${handleStatus(
          formData?.recommendation ?? ''
        )} o pedido? Este processo não poderá ser desfeito.`}
        secondAction={onSubmit}
        closeText="Enviar"
        isOpen={isOpen}
        onClose={onClose}
      >
        <>
          <S.NodeText mt={4} mb={3}>
            Resumo da análise:
          </S.NodeText>
          <S.ReviewSummary>
            <S.TextRow>
              <S.NodeText>
                <b>Comentário:</b> {formData?.message}
              </S.NodeText>
            </S.TextRow>
          </S.ReviewSummary>
        </>
      </ReviewModal>
    </S.Container>
  );
};

export default BaseBox;
