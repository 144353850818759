/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import {
  FormLabel,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputGroup,
} from '@chakra-ui/react';

import FormControl from './formControl';
import * as I from './interfaces';

const InputNumber: ForwardRefRenderFunction<
  HTMLInputElement,
  I.InputNumberProps
> = ({ defaultValue, formcontrol, label, name, ...rest }, ref) => {
  return (
    <FormControl {...formcontrol}>
      <InputGroup>
        <ChakraNumberInput
          data-testid="input-number"
          aria-labelledby={`${name}-label`}
          height="2.75rem"
          width="100%"
          borderRadius="large"
          variant="outline"
          fontSize="medium"
          borderColor="neutral.500"
          isDisabled={formcontrol.isDisabled}
          defaultValue={defaultValue as any}
        >
          <NumberInputField
            id={name}
            data-testid="number-input-field"
            height="2.75rem"
            width="100%"
            borderRadius="large"
            variant="outline"
            fontSize="medium"
            borderColor="neutral.500"
            ref={ref}
            name={name}
            {...rest}
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </ChakraNumberInput>

        <FormLabel
          data-testid="form-label"
          id={`${name}-label`}
          htmlFor={name}
          className="active"
        >
          {label}
        </FormLabel>
      </InputGroup>
    </FormControl>
  );
};

export default forwardRef(InputNumber);
