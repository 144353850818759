import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import Routes from './routes';
import MenuV2 from './components/base/v2/menu';
import GlobalStyle from './styles/globals';
import { keycloak, keycloakProviderInitConfig } from './services/keycloak';
import Interceptor from './services/api/interceptors';
import { theme, styledTheme } from './styles/theme';
import { StyledToastContainer } from './styles/toast';

function App(): React.ReactElement {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
    >
      <ChakraProvider theme={theme}>
        <StyledThemeProvider theme={styledTheme}>
          <Interceptor />
          <BrowserRouter>
            <MenuV2 />
            <Routes />
            <GlobalStyle />
            <StyledToastContainer autoClose={5000} />
          </BrowserRouter>
        </StyledThemeProvider>
      </ChakraProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
