import React, { useState, useEffect } from 'react';
import AutoSuggest from 'react-autosuggest';
import { trexApi } from '../../../services/api';
import Container from './styles';
import { SETID } from '../../../services/storage';
import * as I from '../../../interfaces/components/base/autocomplete';

export default function AutocompleteGroups({
  action,
  defaultValue,
  disabled,
  name,
}: I.AutoCompleteProps): React.ReactElement {
  const UUID = localStorage.getItem(SETID);
  const [list, setList] = useState<I.AutoCompleteNameModel[]>([
    { id: '', name: '' },
  ]);
  const [values, setValues] = useState('');
  const [suggestions, setSuggestions] = useState<I.AutoCompleteNameModel[]>([
    { id: '', name: '' },
  ]);

  const getGroup = (): Promise<React.ReactNode> =>
    new Promise((resolve, reject) => {
      trexApi
        .get(`groups/${defaultValue}`, {
          headers: {
            'X-SetID': UUID,
          },
        })
        .then((result) => {
          if (result.data) {
            setValues(result.data.name);
            setList(result.data);
          }
        })
        .catch(() => {
          reject();
        });
    });

  const callAPI = (): Promise<React.ReactNode> =>
    new Promise((resolve, reject) => {
      trexApi
        .get('groups', {
          params: {
            page: 1,
            'page-size': 10,
            search: values,
          },
          headers: {
            'X-SetID': UUID,
          },
        })
        .then((result) => {
          if (result.data.items) {
            setList(result.data.items);
          }
        })
        .catch(() => {
          reject();
        });
    });

  const callAction = (suggestion: I.AutoCompleteNameModel): void => {
    if (action) {
      action(suggestion.id, suggestion.name);
    }
  };

  function getSuggestions(item: string): Array<{ id: string; name: string }> {
    return list.filter((company) =>
      `${company.name.toLowerCase()} (ID: ${company.id.toLowerCase()})`.includes(
        item.toLowerCase().trim()
      )
    );
  }
  useEffect(() => {
    callAPI();
  }, [values]);

  useEffect(() => {
    if (defaultValue) {
      getGroup();
    }
  }, []);

  return (
    <Container>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={(): void => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }): void => {
          setValues(value);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestion }): void =>
          callAction(suggestion)
        }
        getSuggestionValue={(suggestion): string => suggestion.name}
        renderSuggestion={(suggestion): React.ReactElement => (
          <span>{`${suggestion.name} (ID: ${suggestion.id.substring(
            0,
            6
          )}...)`}</span>
        )}
        inputProps={{
          id: 'suggestionGroupField',
          value: values,
          placeholder: 'Pesquisar',
          name: name || 'group',
          onChange: (_, { newValue }): void => {
            setValues(newValue);
          },
          disabled: !!disabled,
        }}
        highlightFirstSuggestion
      />
    </Container>
  );
}

AutocompleteGroups.defaultProps = {
  action: null,
  name: '',
  defaultValue: '',
  disabled: false,
};
