/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';
import * as I from './interface';
import * as I2 from '../base/v2/inputs/interfaces';
import NodeRow from './nodeRow';
import { NodeText, TextRow } from './styles';
import { ReviewDetailEvents } from '../../interfaces/order';

export const handleStatus = (status: string) => {
  switch (status) {
    case 'reject':
      return 'Recusar';

    case 'approve':
      return 'Aprovar';

    case 'delay':
      return 'Agendar';

    case 'review':
      return 'Revisar';

    default:
      return '';
  }
};

export const handleFormToReviewModel = (values: {
  [key: string]: string[] | string | undefined;
}) => {
  const hint = Object.values(values)
    .filter((item) => Array.isArray(item))
    .flat(1);

  const hints = hint.map((item) => item && { node_id: item }).filter(Boolean);

  return {
    message: values.message,
    recommendation: values.recommendation,
    hints,
    event_type: 'review',
    until: typeof values.until !== 'undefined' ? values.until : undefined,
  } as I.SubmitReviewType;
};

export const handleFormToHeldModel = (values: I.HeldFormType) => {
  return {
    action: values.recommendation,
    message: values.message,
  };
};

export const handleFormToSuspensionModel = (values: I.SuspensionFormValues) => {
  return {
    action: values.recommendation,
    reason: values.message,
    resolved_at: new Date(),
  };
};

export const handleFormToRetentionModel = (values: I.SuspensionFormValues) => {
  return {
    recommendation: values.recommendation,
    message: values.message,
  };
};

export const validationSchema = Yup.object().shape({
  historyTransaction: Yup.array().of(Yup.string()),
  fingerprint: Yup.array().of(Yup.string()),
  registrationData: Yup.array().of(Yup.string()),
  email: Yup.array().of(Yup.string()),
  binLevel: Yup.array().of(Yup.string()),
  connection: Yup.array().of(Yup.string()),
  others: Yup.array().of(Yup.string()),
  message: Yup.string(),
  recommendation: Yup.string().required(
    'Por favor, selecione um item para o campo de recomendação'
  ),
});

export const handleObjectEntriesNames = (value: string) => {
  return value
    .normalize('NFD')
    .replace(/[^a-zA-Z\s]/g, '')
    .replace(/\s/g, '_')
    .toLowerCase();
};

export const handleTreeObjects = (options: I.TreeOptionsType) => {
  const selectOptions: Record<string, I.SelectOptionsType> = {};

  const arrayItem = [
    ...options.on_approve,
    ...options.on_reject,
    ...options.on_delay,
  ];

  arrayItem.forEach((subDecision) => {
    if (subDecision.nodes) {
      subDecision.nodes.forEach((category) => {
        const name = handleObjectEntriesNames(category.name);

        if (selectOptions[name]) {
          selectOptions[name].nodes = Array.from(
            new Set([...selectOptions[name].nodes, ...category.nodes])
          ) as I.HintTreeNodesModel[];
        } else {
          selectOptions[name] = category;
        }
      });
    }
  });

  return selectOptions;
};

export const handleOptionsById = (
  data: string[],
  name: string,
  hintTreeOptions?: Record<string, I.SelectOptionsType>
) => {
  return data
    .map((itemId) => {
      return hintTreeOptions?.[name].nodes.find(
        (hintTreeItem) => hintTreeItem.id === itemId
      )?.name;
    })
    .join(', ');
};

export const handleSearchInputOptions = (options?: I.HintTreeNodesModel[]) => {
  const inputOptions: I2.SearchSelectOptions[] = [];

  options?.forEach((item) => {
    if (item.active) {
      inputOptions.push({ label: item.name, value: item.id });
    }
  });

  return inputOptions;
};

export const handleDataToForm = (data: ReviewDetailEvents) => {
  const hintTreeOptions = handleTreeObjects(data.hint_tree);
  const finalObj = {} as any;

  Object.entries(hintTreeOptions).forEach((item) => {
    finalObj[item[0]] = item[1].nodes.map((node) => {
      return node.id;
    });
  });

  return {
    ...finalObj,
    message: data.message || '',
  };
};

export const handleSummary = (
  formData?: {
    [key: string]: string[] | undefined;
  },
  hintTreeOptions?: Record<string, I.SelectOptionsType>
) => {
  return (
    <>
      {formData &&
        Object.entries(formData).map((item: (string | any)[], index) => {
          const key = `nodeRow-${index}`;
          return (
            <>
              {Array.isArray(formData?.[item[0]]) && (
                <NodeRow
                  key={key}
                  title={item[0]}
                  data={formData?.[item[0]]}
                  name={item[0]}
                  hintTreeOptions={hintTreeOptions}
                />
              )}
            </>
          );
        })}

      <TextRow>
        <NodeText>
          <b>Comentário:</b> {formData?.message}
        </NodeText>
      </TextRow>
    </>
  );
};

export const reviewSelectStatus = ['Agendar', 'Aprovar', 'Reprovar'];
export const reviewSelectStatusValues = ['delay', 'approve', 'reject'];

export const suspensionSelectStatus = ['Aprovar', 'Reprovar', 'Revisar'];
export const suspensionSelectStatusValues = ['approve', 'reject', 'review'];

export const heldSelectStatus = ['Correto', 'Incorreto'];
export const heldSelectStatusValues = ['agree', 'disagree'];
