import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRulesModels } from '../../../../../providers/models';
import InputFactory from '../../../../../components/base/v2/inputs/factory';
import handleType from '../../../../../utils/handleType';

import * as I from '../../../interfaces';

const Params = ({
  featureIndex,
  ruleName,
}: I.ParamsProps): React.ReactElement => {
  const methods = useFormContext();
  const { features, isDisabled } = useRulesModels();

  return (
    <>
      {features
        .find((feature) => feature.name === ruleName)
        ?.parameters?.map((param) => (
          <div key={`features.${featureIndex}.parameters.${param.name}`}>
            {InputFactory({
              name: param.name,
              path: `features.${featureIndex}.parameters.${param.name}`,
              type: handleType(param),
              defaultValue: param.default,
              isRequired: param.mandatory,
              multiple: param.type === 'array of strings',
              description: param.description,
              values: param.one_of,
              isDisabled,
              methods,
            })}
          </div>
        ))}
    </>
  );
};

export default Params;
