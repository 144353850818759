/* eslint-disable import/prefer-default-export */
import { ComponentType, SVGProps } from 'react';
import { ReactComponent as shopIcon } from '../assets/icons/darkShopIcon.svg';
import { ReactComponent as teamIcon } from '../assets/icons/darkTeamIcon.svg';
import { ReactComponent as flowIcon } from '../assets/icons/darkFlowIcon.svg';
import { ReactComponent as bookIcon } from '../assets/icons/darkBookIcon.svg';
import { ReactComponent as handIcon } from '../assets/icons/darkHandIcon.svg';

const iconMap: { [key: string]: ComponentType<SVGProps<SVGSVGElement>> } = {
  client: shopIcon,
  group: teamIcon,
  flow: flowIcon,
  model: bookIcon,
  partner: handIcon,
};

export const handleIcon = (scope: string) => {
  return iconMap[scope] || shopIcon;
};
