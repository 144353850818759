import { Avatar } from '@chakra-ui/react';
import React from 'react';
import { checkDateCustom } from '../../../../../utils/check_values';
import { SelectOptionsType } from '../../../../boxReview/interface';
import { handleTreeObjects } from '../../../../boxReview/utils';
import Stat from '../../../../stat';
import * as I from '../interface';
import * as S from '../styles';
import ItemRow from './ItemRow';

const CommentItem = ({
  commentInfo,
  suspensionInfo,
  backgroundColor,
  identifier,
  event,
}: I.CommentProps): React.ReactElement => {
  const handleHintTreeItems = () => {
    if (commentInfo?.hint_tree) {
      const selectedItems = handleTreeObjects(commentInfo.hint_tree);
      const ObjEntries: [string, SelectOptionsType][] =
        Object.entries(selectedItems);
      return ObjEntries.map(([key, value]) => {
        return <ItemRow key={key} data={value} />;
      });
    }

    return '';
  };

  return (
    <S.Container data-testid={`comment-item-${identifier}`}>
      <S.Row>
        <Avatar mr="8px" />
        {commentInfo && (
          <Stat
            text={commentInfo?.created_by}
            subtitle={checkDateCustom(commentInfo?.created_at)}
          />
        )}
        {suspensionInfo && (
          <Stat
            text={suspensionInfo?.created_by}
            subtitle={checkDateCustom(suspensionInfo?.created_at)}
          />
        )}
      </S.Row>
      <S.Row>
        <S.CommentBox $backgroundColor={backgroundColor}>
          {handleHintTreeItems()}
          {commentInfo?.message && (
            <S.Row>
              <S.ItemText>
                <b>Comentário: </b>
                {commentInfo?.message}
              </S.ItemText>
            </S.Row>
          )}
          {suspensionInfo?.message && (
            <S.Row>
              <S.ItemText>
                <b>Comentário: </b>
                {suspensionInfo?.message}
              </S.ItemText>
            </S.Row>
          )}
          {event && (
            <S.Row>
              <S.ItemText>
                <b>Evento: </b>
                {event}
              </S.ItemText>
            </S.Row>
          )}
        </S.CommentBox>
      </S.Row>
    </S.Container>
  );
};

export default CommentItem;
