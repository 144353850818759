import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { MdOutlineDeliveryDining } from 'react-icons/md';

import { useOrder } from '../../../../providers/order';
import ImageWithText from '../../../base/image_with_text';
import LoadingSpinner from '../../../loadingSpinner';
import Shipping from './shipping';
import * as S2 from '../styles';

export default function Shippings(): React.ReactElement {
  const [isLoaded, setIsLoaded] = useState(false);
  const { orderContext } = useOrder();

  useEffect(() => {
    setIsLoaded(Boolean(orderContext));
  }, [orderContext]);

  return (
    <S2.Container>
      <S2.TitleContainer>
        <S2.Text>Entrega</S2.Text>
      </S2.TitleContainer>
      <Stack backgroundColor="white" padding="regular" borderRadius="huge">
        {!isLoaded && <LoadingSpinner />}
        {isLoaded && (
          <>
            {orderContext?.order?.shippings ? (
              orderContext.order.shippings.map((shipping, index) => {
                const key = `order_shippings-${shipping.receiver?.name}.${index}`;
                return (
                  <Shipping
                    key={key}
                    order={orderContext.order}
                    shipping={shipping}
                  />
                );
              })
            ) : (
              <ImageWithText
                title="Nenhuma entrega informada"
                text="Não recebemos as informações de entrega desta compra."
                icon={MdOutlineDeliveryDining}
              />
            )}
          </>
        )}
      </Stack>
    </S2.Container>
  );
}
