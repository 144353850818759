/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Tab as ChakraTab } from '@chakra-ui/react';
import { theme } from '../../styles/theme';

export const Tab = styled(ChakraTab).attrs(() => ({
  _selected: {
    color: theme.colors.v2.green[300],
    borderBottomColor: theme.colors.v2.green[300],
    fontWeight: 600,
    boxShadow: 'none',

    '.notification-container': {
      backgroundColor: theme.colors.v2.green[300],
    },
  },
}))`
  color: ${theme.colors.v2.dark[200]};
  border-bottom-color: ${theme.colors.v2.dark[200]};
  font-weight: 600;

  .notification-container {
    background-color: ${theme.colors.v2.dark[200]};
  }
`;
