import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';

import { H3 } from '../../../../base/Headings';
import { GroupsFormProps } from '../../../../../interfaces/groups';
import ChakraAutocompleteActions from '../../../../base/chakra_autocomplete/actions';

export default function GroupsActionsForm({
  isDisabled,
}: GroupsFormProps): React.ReactElement {
  const methods = useFormContext();
  const {
    formState: { errors },
    watch,
  } = methods;

  return (
    <>
      <H3>Listas Vinculadas</H3>
      <Stack spacing="md" direction="row" w="100%" data-testid="actions-input">
        <ChakraAutocompleteActions
          error={errors.action_lists}
          methods={methods}
          defaultValue={watch('action_lists')}
          placeholder="Pesquisar"
          isDisabled={isDisabled}
          name="action_lists"
        />
      </Stack>
    </>
  );
}
