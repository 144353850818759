import { Icon } from '@chakra-ui/react';

import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineInfoCircle,
} from 'react-icons/ai';

import { ReactComponent as YellowAttentionIcon } from '../../../../assets/icons/attention.svg';
import { ReactComponent as RedAttentionIcon } from '../../../../assets/icons/redAlert.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock.svg';
import { ReactComponent as ReloadClockIcon } from '../../../../assets/icons/reloadClock.svg';
import { Status } from '../../../../interfaces/types';
import { statusCSS } from '../../../../utils/css';
import * as I from './interfaces';

export const statusOptions = [
  {
    label: 'Limpar seleção',
    options: [{ label: 'Nenhum', value: '' }],
  },
  {
    label: 'status',
    options: [
      { label: 'Pendente', value: 'pending' },
      { label: 'Finalizado', value: 'finished' },
      { label: 'Não analizado', value: 'not-analyzed' },
    ],
  },

  {
    label: 'recomendações',
    options: [
      { label: 'Aprovado', value: 'approve' },
      { label: 'Rejeitado', value: 'reject' },
    ],
  },
];

export const filterByOptions = [
  { label: 'Nenhum', value: '' },
  { label: 'ID do pedido', value: 'id' },
  { label: 'ID do cliente', value: 'client' },
  { label: 'ID do fluxo', value: 'flow' },
  { label: 'Documento', value: 'order.buyer.document' },
  { label: 'Telefone', value: 'order.buyer.phone' },
  { label: 'Email', value: 'order.buyer.email' },
  { label: 'Nome do portador', value: 'order.payments.holder-name' },
  { label: 'Fingerprint', value: 'order.payments.fingerprint' },
  { label: 'Referencia do pedido', value: 'order.order-reference' },
  { label: 'Nome do seller', value: 'order.products.seller.name' },
  { label: 'ID do seller', value: 'order.products.seller.id' },
  { label: 'Documento do seller', value: 'order.products.seller.document' },
];

export const initialFilterState = {
  from: '',
  to: '',
  status: '',
  filterBy: '',
  text: '',
};

export const handleStatus = (
  state: Status,
  recomendation: Status,
  status: Status,
  suspension: Status
): React.CSSProperties => {
  if (statusCSS[state] && state === 'withheld') {
    return statusCSS[state]();
  }

  if (statusCSS[suspension] && suspension === 'suspended') {
    return statusCSS[suspension]();
  }

  if (statusCSS[recomendation] && status !== 'not-analyzed') {
    return statusCSS[recomendation]();
  }

  if (statusCSS[state] && state !== 'timed-out') {
    return statusCSS[state]();
  }

  if (statusCSS[status]) {
    return statusCSS[status]();
  }
  return {};
};

export const handleShowStatus = (
  state: string,
  stateDisplay: string,
  recomendation: string,
  recomendationDisplay: string,
  status: string,
  statusDisplay: string,
  suspension?: string
): string => {
  if (state === 'withheld') {
    return stateDisplay;
  }

  if (!!recomendation && status !== 'not-analyzed') {
    return recomendationDisplay;
  }

  if (!!state && state !== 'timed-out' && state !== 'ready') {
    return stateDisplay;
  }

  if (suspension) {
    return suspension;
  }

  return statusDisplay;
};

export const handleShowStatusIcon = (
  state: string,
  recomendation: string,
  status: string,
  suspension?: string
): React.ReactElement => {
  if (state === 'in-review') {
    return <Icon src={ReloadClockIcon} display="block" />;
  }

  if (status === 'finished' && recomendation === 'approve') {
    return <Icon as={AiOutlineCheckCircle} display="block" />;
  }

  if (status === 'finished' && recomendation === 'reject') {
    return <Icon as={AiOutlineCloseCircle} display="block" />;
  }

  if (suspension || status === 'pending') {
    return <Icon src={ClockIcon} display="block" />;
  }

  return <Icon as={AiOutlineInfoCircle} display="block" />;
};

export const handleScore = (score: number): React.CSSProperties => {
  if (score < 0.33) {
    return { color: '#5b8c5a' };
  }
  if (score < 0.66) {
    return { color: '#f2a359' };
  }
  return { color: '#de2222' };
};

export const handleSLA = (sla: number): React.CSSProperties => {
  if (sla < 0) {
    return { color: '#de2222', fontWeight: 'bold' };
  }
  if (sla < 7200) {
    return { color: '#f2a359', fontWeight: 'bold' };
  }
  return {};
};

export const handleSLAIcon = (sla: number): React.ReactElement | null => {
  if (sla < 0) {
    return <RedAttentionIcon />;
  }

  if (sla < 7200) {
    return <YellowAttentionIcon />;
  }

  return null;
};

export const handleShowSLA = (recomendation: string): boolean => {
  if (recomendation) {
    return false;
  }
  return true;
};

export const formatDateForPicker = (isoDate?: string) => {
  if (isoDate) {
    const timeZoneHour = (Number(isoDate.slice(-13, -11)) - 3).toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }
    );

    const zonedTime = isoDate
      .replace(/(.{11}).{2}/, `$1${timeZoneHour}`)
      .slice(0, 16);

    return zonedTime;
  }

  return '';
};

const handleStatusRecommendation = (value = '') => {
  const statuses = {
    pending: 'status',
    finished: 'status',
    'not-analyzed': 'status',
    approve: 'recommendation',
    reject: 'recommendation',
  } as I.StatusesType;

  const key = statuses[value] || 'status';
  return { key, value };
};

export const handleFormToFiltersModel = (values: I.FilterFormValues) => {
  const { key, value } = handleStatusRecommendation(values.status);

  return {
    [key]: value,
    'order.date.from': values.from ? new Date(values.from).toISOString() : '',
    'order.date.to': values.to ? new Date(values.to).toISOString() : '',
    [values.filterBy || 'search']: values.text || '',
  };
};

export const handleStatusDefaultValue = (value: string) => {
  const matchingItem = statusOptions.find((item) =>
    item.options.some((option) => option.value === value)
  );

  const matchingOption = matchingItem
    ? matchingItem.options.find((option) => option.value === value)
    : undefined;

  return matchingOption;
};

export const handleFilterByDefaultValue = (value: string) => {
  return filterByOptions.find((item) => item.value === value);
};
