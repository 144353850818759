/* eslint-disable @typescript-eslint/no-explicit-any */
const handleType = (
  feature: any
): 'select' | 'text' | 'autocomplete' | 'boolean' | 'number' | 'float' => {
  if (feature.one_of) {
    return 'select';
  }

  if (feature.type === 'array of strings') {
    return 'autocomplete';
  }

  if (feature.type === 'boolean') {
    return 'boolean';
  }

  if (feature.type === 'int') {
    return 'number';
  }

  if (feature.type === 'float') {
    return 'float';
  }

  return 'text';
};

export default handleType;
