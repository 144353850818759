import styled from 'styled-components';

const Container = styled.div`
  span {
    background: ${({ theme }) => theme.colors.blue[500]} !important;
    padding: 8px 15px;
    color: white;
    border-radius: 5px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    font-size: 0.875rem;

    img {
      margin-right: 10px;
      height: 34px;
      width: 34px;
    }

    svg {
      fill: ${({ theme }) => theme.colors.red[500]};
      margin-right: 10px;
      height: 34px;
      width: 34px;
    }
  }
`;

export default Container;
