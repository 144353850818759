import React from 'react';
import { Divider, Stack } from '@chakra-ui/react';
import CardsAction from './actions';
import Features from './features';

import * as I from '../../interfaces';

const Card = ({
  dragProvider,
  moveFeature,
  features,
  featureIndex,
  appendFeature,
  featuresLength,
  removeFeature,
}: I.CardProps): React.ReactElement => {
  return (
    <Stack
      direction="row"
      spacing="regular"
      p="medium"
      background="white"
      borderRadius="0.75rem"
      mb="8px"
    >
      <CardsAction
        dragProvider={dragProvider}
        moveFeature={moveFeature}
        featureIndex={featureIndex}
        features={features}
      />

      <div>
        <Divider orientation="vertical" borderColor="neutral.400" />
      </div>

      <Features
        appendFeature={appendFeature}
        featureIndex={featureIndex}
        featuresLength={featuresLength}
        removeFeature={removeFeature}
      />
    </Stack>
  );
};

export default Card;
