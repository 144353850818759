import * as yup from 'yup';

const parametersSchema = yup
  .object()
  .shape({
    client: yup.array().of(yup.string()),
    score: yup
      .number()
      .positive('é preciso ser um score positivo')
      .max(100, 'valor máximo de 100')
      .min(0, 'valor minimo de 0'),
    operator: yup.string(),
    amount: yup
      .number()
      .positive('é preciso ser um valor positivo')
      .min(0, 'valor minimo de 0'),
  })
  .test(
    'valid-parameters',
    'Erro na validação dos parâmetros',
    function validateParameters(value) {
      const { name } = this.parent;
      if (name === 'analysis/score' && (!value?.score || !value?.operator)) {
        return this.createError({
          message:
            'é preciso informar um score e um operador para analysis/score',
        });
      }
      if (name === 'payment/amount' && (!value?.amount || !value?.operator)) {
        return this.createError({
          message:
            'é preciso informar um amount e um operador para payment/amount',
        });
      }
      return true;
    }
  );

const CreateQueueSchema = yup.object().shape({
  filters: yup.array().of(
    yup.object().shape({
      name: yup.string().required('é preciso selecionar uma regra'),
      parameters: parametersSchema,
    })
  ),
  name: yup.string().required('Nome é obrigatório'),
  priority: yup.string().required('Selecione uma prioridade'),
  status: yup
    .string()
    .oneOf(['active', 'inactive'], 'Não é um valor valido')
    .required('Status é obrigatório'),
});

export default CreateQueueSchema;
