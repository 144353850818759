import React from 'react';

import * as S from './styles';
import * as I from './interface';
import { handleOptionsById } from './utils';

const NodeRow = ({
  data,
  name,
  hintTreeOptions,
  title,
}: I.NodeRowType): React.ReactElement => {
  return (
    <>
      {data && data.length > 0 && (
        <S.TextRow>
          <S.NodeText>
            <b>{title}: </b>
            {handleOptionsById(data, name, hintTreeOptions)}
          </S.NodeText>
        </S.TextRow>
      )}
    </>
  );
};

export default NodeRow;
