import styled from 'styled-components';
import { Box, Text } from '@chakra-ui/react';

export const Progressbar = styled(Box)`
  position: relative;
  height: ${({ theme }) => theme.spacing(0.75)}px;
  width: ${({ theme }) => theme.spacing(18.75)}px;
  background: ${({ theme }) => theme.colors.gray[200]};
  border-radius: ${({ theme }) => theme.radii.large};
`;

export const Progressline = styled(Box)`
  height: 100%;
  position: absolute;
  border-radius: ${({ theme }) => theme.radii.large};
`;

export const Progresslabel = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
`;

export const Label = styled(Text)`
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: ${({ theme }) => theme.radii.huge};
  white-space: nowrap;
`;
