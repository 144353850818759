import { jaroWinkler } from 'jaro-winkler-typescript';

const jaro = (
  firstValue: string | undefined,
  secondValue: string | undefined
): number => {
  if (!firstValue || !secondValue) {
    return 0;
  }

  return Math.min(
    Math.max(
      (jaroWinkler(firstValue, secondValue, { caseSensitive: false }) - 0.5) *
        2,
      0
    ),
    1
  );
};

export default jaro;
