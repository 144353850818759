import React from 'react';

import { InputGroup } from '@chakra-ui/react';
import * as I from '../../../../interfaces/components/base/input';
import * as S from './styles';
import FormControl from '../inputs/formControl';

export default function InputCountry({
  formcontrol,
  name,
  handleChange,
  value,
  label,
}: Readonly<I.InputCountryProps>): React.ReactElement {
  return (
    <FormControl {...formcontrol}>
      <InputGroup>
        <S.InputCountry
          valueType="short"
          value={value}
          onChange={handleChange}
          priorityOptions={['BR']}
          defaultOptionLabel="Selecione um País"
          id={name}
          name={name}
          disabled={formcontrol?.isDisabled}
        />

        <S.ActiveFormLabel htmlFor={name}>{label}</S.ActiveFormLabel>
      </InputGroup>
    </FormControl>
  );
}
