export function dateFormater(date: Date | null): string {
  if (date) {
    const newDate = `${
      date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()
    }/${
      date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()}`;
    return newDate;
  }
  return '';
}

export function dateFullFormater(date: Date | null): string {
  if (date) {
    const month = date
      .toLocaleString('pt-BR', {
        month: 'long',
      })
      .toUpperCase()
      .substring(0, 3);
    const day = date.toLocaleString('pt-BR', { day: '2-digit' });
    const year = date.toLocaleString('pt-BR', { year: 'numeric' });
    const hours = date.toLocaleString('pt-BR', { hour: '2-digit' });
    const minutes = date.toLocaleString('pt-BR', { minute: '2-digit' });

    return `${day} ${month} ${year} ${hours}h ${minutes}min`;
  }
  return '';
}

export function dateFullFormaterList(date: Date | null): string {
  if (date) {
    const month = date
      .toLocaleString('pt-BR', {
        month: 'long',
      })
      .substring(0, 3);
    const day = date.toLocaleString('pt-BR', { day: '2-digit' });
    const year = date.toLocaleString('pt-BR', { year: 'numeric' });
    const hours = date.toLocaleString('pt-BR', { hour: '2-digit' });
    const minutes = date.toLocaleString('pt-BR', { minute: '2-digit' });

    return `${day} ${month}, ${year} - ${hours}h ${minutes}min`;
  }
  return '';
}

export function dateFormaterAmerican(date: Date | null): string {
  if (date) {
    const newDate = `${date.getFullYear()}-${
      date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()}`;
    return newDate;
  }
  return '';
}

export function dateExpiresFormater(validateAt: string | null): string {
  if (validateAt === null) return '';
  const year = validateAt.substring(0, 4);
  const month = validateAt.substring(5, 7);
  return `${month}/${year}`;
}

export function dateTimeFormater(date: Date | null): string {
  if (date) {
    const newDate = `${
      date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()
    }/${
      date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()} ${
      date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
    }h ${
      date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()
    }min`;
    return newDate;
  }
  return '';
}

export function dateTimeFormater2(date: Date | null): string {
  if (date) {
    const newDate = `${
      date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()
    }/${
      date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()} ${
      date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
    }:${date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()}`;
    return newDate;
  }
  return '';
}

export function hoursTimeDisplay(millisec: number): string {
  let seconds = Number((millisec / 1000).toFixed(0));

  let minutes = Math.floor(seconds / 60);
  let hours = 0;
  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    hours = hours >= 10 ? hours : 0 + hours;
    minutes -= hours * 60;
    minutes = minutes >= 10 ? minutes : 0 + minutes;
  }

  seconds = Math.floor(seconds % 60);
  seconds = seconds >= 10 ? seconds : 0 + seconds;
  if (hours !== 0) {
    return `${hours}h ${minutes}min ${seconds}sec`;
  }
  return `${minutes}min ${seconds}sec`;
}

export function TimeDisplayWithSecond(seconds: number): string {
  if (seconds < 0) {
    seconds = Math.abs(seconds);
  }

  let minutes = Math.floor(seconds / 60);
  let hours = 0;
  let days = 0;

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    minutes -= hours * 60;
    minutes = minutes >= 10 ? minutes : 0 + minutes;
  }

  if (hours > 24) {
    days = Math.floor(hours / 24);
    hours -= days * 24;
  }

  seconds = Math.floor(seconds % 60);
  seconds = seconds >= 10 ? seconds : 0 + seconds;

  if (days !== 0) {
    return `${days}d ${hours}h ${minutes}min ${seconds}sec`;
  }
  if (hours !== 0) {
    return `${hours}h ${minutes}min ${seconds}sec`;
  }
  return `${minutes}min ${seconds}sec`;
}

export function TimeDisplaySLA(seconds: number): string {
  if (seconds < 0) {
    seconds = Math.abs(seconds);
  }

  let minutes = Math.floor(seconds / 60);
  let hours = 0;
  let days = 0;

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    minutes -= hours * 60;
    minutes = minutes >= 10 ? minutes : 0 + minutes;
  }

  if (hours > 24) {
    days = Math.floor(hours / 24);
    hours -= days * 24;
  }

  seconds = Math.floor(seconds % 60);
  seconds = seconds >= 10 ? seconds : 0 + seconds;

  if (days !== 0) {
    return `${days}d ${hours}h ${minutes}min`;
  }
  if (hours !== 0) {
    return `${hours}h ${minutes}min`;
  }
  return `${minutes}min`;
}

export function formatDate(dateString?: string): string {
  if (!dateString) {
    return '';
  }

  const date: Date = new Date(dateString);

  if (Number.isNaN(date.getTime())) {
    return '';
  }

  const months: string[] = [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ];

  const day: number = date.getDate();
  const month: string = months[date.getMonth()];
  const year: number = date.getFullYear();
  const hour: number = date.getHours();
  const minute: number = date.getMinutes();

  return `${day} ${month}, ${year} - ${hour}h ${minute}min`;
}

export function customDateTimeFormater(date: Date | null): string {
  if (date) {
    const newDate = `${
      date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()
    }/${
      date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()} - ${
      date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
    }h${
      date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()
    }min`;
    return newDate;
  }
  return '';
}
