import React from 'react';
import { useAtom } from 'jotai';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Stack, Icon } from '@chakra-ui/react';
import { CgAddR } from 'react-icons/cg';
import { HiOutlineTrash } from 'react-icons/hi';

import MultiInputs from './filters';
import { isQueuesManagementEditingAtom } from './stores';
import Button from '../../../base/v2/button';
import MultiInputsInformation from './filters/information';

export default function QueueManagementRules(): React.ReactElement {
  const [isEditing] = useAtom(isQueuesManagementEditingAtom);
  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'filters',
  });

  const addFilter = (): void => {
    append({ name: '' });
  };

  const hasOnly = fields.length <= 1;

  return (
    <>
      {fields.map((field, index) => (
        <Stack
          key={field.id}
          direction="row"
          background="white"
          borderRadius="extra-large"
          p="medium"
        >
          {isEditing ? (
            <MultiInputs MultiInputsIndex={index} />
          ) : (
            <MultiInputsInformation MultiInputsIndex={index} />
          )}
          <Stack direction="row" marginLeft="auto !important">
            <Button
              onClick={() => remove(index)}
              isDisabled={hasOnly || !isEditing}
            >
              <Icon as={HiOutlineTrash} />
            </Button>
          </Stack>
        </Stack>
      ))}

      {isEditing ? (
        <Stack
          direction="row"
          background="white"
          borderRadius="extra-large"
          p="medium"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            leftIcon={<Icon as={CgAddR} />}
            onClick={addFilter}
            isDisabled={!isEditing}
          >
            Adicionar filtro
          </Button>
        </Stack>
      ) : null}
    </>
  );
}
