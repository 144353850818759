/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;

  input {
    width: 100%;
    height: 2.75rem !important;
    border-radius: 0.5rem !important;
    border: 1px solid ${({ theme }) => theme.colors.neutral[400]} !important;
    padding: 0 ${({ theme }) => theme.spacing(2)}px;

    ::placeholder {
      color: ${({ theme }) => theme.colors.neutral[400]};
    }
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: ${({ theme }) => theme.spacing(6.25)}px;
    width: ${({ theme }) => theme.spacing(35)}px;
    border: 1px solid ${({ theme }) => theme.colors.v2.gray[100]};
    background-color: ${({ theme }) => theme.colors.white[700]};
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: ${({ theme }) => theme.spacing(2)}px;
    border-bottom-left-radius: ${({ theme }) => theme.spacing(0.5)}px;
    border-bottom-right-radius: ${({ theme }) => theme.spacing(0.5)}px;
    z-index: 20;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing(1.25)}px
      ${({ theme }) => theme.spacing(2.5)}px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) => theme.colors.v2.gray[100]};
  }

  .highlight {
    color: ${({ theme }) => theme.colors.red[800]};
    font-weight: bold;
  }

  .react-autosuggest__suggestion--focused .highlight {
    color: ${({ theme }) => theme.colors.v2.dark[200]};
  }
`;
