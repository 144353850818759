import React from 'react';
import { Button, Icon, Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BiUnlink } from 'react-icons/bi';
import { H3 } from '../../../../components/base/Headings';
import AutocompleteModel from '../../../../components/base/autocomplete_model';

import * as I from '../interfaces';

const Model = ({
  handleUnlinkModel,
  isDisabled,
  value,
  setValue,
}: I.BondsProps): React.ReactElement => {
  const { register, control, setValue: setInputValue } = useFormContext();

  const modelID = useWatch({
    control,
    name: 'model_id',
  });

  const handleSetValues = (val: string) => {
    setValue(val);
    setInputValue('flow_id', val);
  };

  return (
    <Stack spacing="md">
      <H3>Modelo Vinculado</H3>
      <Stack direction="row" spacing="md" alignItems="center">
        <div
          className="default--input"
          style={{ flex: 1 }}
          data-testid="models-input"
        >
          <AutocompleteModel
            {...register('model_id')}
            action={handleSetValues}
            disabled={isDisabled}
            defaultValue={value}
          />
        </div>
        {handleUnlinkModel && (
          <Button
            type="button"
            isDisabled={isDisabled || !value}
            colorScheme="gray"
            onClick={(): void => {
              handleUnlinkModel(modelID);
            }}
          >
            <Icon as={BiUnlink} />
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default Model;
