import React, { useEffect } from 'react';

import { ModelsModel } from '../../interfaces/models';

import {
  IdColumn,
  createdAtColumn,
  descriptionAndIDColumn,
  modifiedAtColumn,
} from '../../components/base/v2/table/columns';
import useModels from '../../hooks/api/models';
import GenericListPage from '../genericList';
import useDocumentTitle from '../../hooks/documentTitle';
import { cleanFilters } from '../../components/base/v2/table/stores';

const ModelsList: React.FC = () => {
  useDocumentTitle('Trex - Modelos');
  const { getAllModels } = useModels();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  return (
    <GenericListPage<ModelsModel>
      actionButton={{
        pushPath: '/models/create',
        text: 'Criar novo modelo',
      }}
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Modelos' }],
      }}
      title="Modelos"
      table={{
        fetch: {
          apiCall: getAllModels,
          title: 'modelos',
        },
        body: {
          columns: [
            descriptionAndIDColumn('/models/info'),
            IdColumn,
            createdAtColumn,
            modifiedAtColumn,
          ],
          searchLabel: 'Busque por nome ou ID de um modelo',
          accessURL: '/models/info',
        },
      }}
    />
  );
};

export default ModelsList;
