/**
 * Esta função gera um UUID v4 aleatório usando a função Math.random().
 * Note que a fonte de entropia não é criptograficamente segura.
 */
const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.floor(Math.random() * 16);
    const lowBits = r % 4;
    const finalValue = lowBits + 8;
    const v = c === 'x' ? r : finalValue;
    return v.toString(16);
  });
};

export default generateUUID;
