function checkMenuRoles(keycloak: Keycloak.KeycloakInstance): string[] {
  const newRoles = [''];
  const roles = [
    'view_clients',
    'view_flows',
    'view_actions',
    'view_models',
    'view_partners',
    'view_analysis',
    'view_queues',
    'view_groups',
    'resolve_suspension',
    'resolve_analysis_withhold',
    'view_tags',
    'create_tags',
  ];
  if (keycloak && roles) {
    roles.forEach((r) => {
      const realm = keycloak.hasRealmRole(r);
      const resource = keycloak.hasResourceRole(r, 'trex-application');
      const account = keycloak.hasResourceRole(r, 'account');
      if (realm || resource || account) {
        newRoles.push(r);
      }
    });
  }
  return newRoles;
}

export default checkMenuRoles;
