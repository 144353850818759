/* eslint-disable import/prefer-default-export */

import {
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

export const getStyle = (
  snapshot: DraggableStateSnapshot,
  dragStyle?: DraggingStyle | NotDraggingStyle
) => {
  if (!snapshot.isDropAnimating) {
    return dragStyle;
  }
  return {
    ...dragStyle,
    transitionDuration: `0.05s`,
  };
};
