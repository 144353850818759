/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { Grid } from '@chakra-ui/react';
import { styledTheme, theme } from '../../../../styles/theme';

export const Card = styled.div`
  background-color: ${theme.colors.v2.white[100]};
  width: 100%;
  min-height: ${styledTheme.spacing(10)}px;
  border-radius: ${styledTheme.spacing(2)}px;
  padding: ${styledTheme.spacing(2)}px;
  word-break: break-word;
`;

export const GridContainer = styled(Grid).attrs(() => ({
  templateColumns: 'repeat(7, 1fr)',
  gap: 3,
}))`
  grid-auto-rows: minmax(0px, auto);
  width: 100%;
  height: 100%;
`;
