import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

import * as I from './interfaces';

export default function ResponsiveIdDisplay({
  id,
}: I.ResponsiveIdDisplayProps): React.ReactElement {
  const breakpoint = useBreakpointValue({
    base: 'base',
    '2xl': '2xl',
  });

  return (
    <>
      {breakpoint && ['base'].includes(breakpoint)
        ? `${id?.substring(0, 6)}...${id?.substring(
            id?.length - 6,
            id?.length
          )}`
        : id}
    </>
  );
}
