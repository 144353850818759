type ColorModel = 'Alto' | 'Baixo' | 'Fraude' | 'Moderado';
type Colors = 'green' | 'orange' | 'red' | 'blue' | 'gray';

type RiskLevel = {
  [key in ColorModel]: Colors;
};

export const colorMode: RiskLevel = {
  Fraude: 'red',
  Alto: 'red',
  Moderado: 'orange',
  Baixo: 'green',
};

export function getRiskLevel(risk: number): ColorModel {
  if (risk > 0.9) {
    return 'Fraude';
  }
  if (risk > 0.6) {
    return 'Alto';
  }
  if (risk > 0.3) {
    return 'Moderado';
  }
  return 'Baixo';
}
