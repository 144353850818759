import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Stack, Wrap, WrapItem } from '@chakra-ui/react';

import * as I from './interfaces';

import { initialQueuesManagementDataAtom, queuesFiltersAtom } from '../stores';
import Stat from '../../../../stat';
import Badge from '../../../../badge';
import useClients from '../../../../../hooks/api/clients';
import { isUUID } from '../../../../../utils/checkUUID';

export default function MultiInputsInformation({
  MultiInputsIndex,
}: Readonly<I.QueueManagementFilterProps>): React.ReactElement {
  const { getOneClient } = useClients();
  const [initialData] = useAtom(initialQueuesManagementDataAtom);
  const [queueFilters] = useAtom(queuesFiltersAtom);
  const [clientNames, setClientNames] = useState<I.ClientNamesType>({});

  useEffect(() => {
    const fetchClientName = async (uuid: string) => {
      if (clientNames[uuid]) return;
      const clientName = await getOneClient({ id: uuid });
      setClientNames((prev: typeof clientNames) => ({
        ...prev,
        [uuid]: clientName.data.name,
      }));
    };

    if (initialData.filters[MultiInputsIndex].name === 'client/id') {
      const client = initialData.filters[MultiInputsIndex]?.parameters?.clients;
      if (Array.isArray(client)) {
        client.forEach((uuid) => {
          if (isUUID(uuid)) {
            fetchClientName(uuid);
          }
        });
      } else if (isUUID(client)) {
        fetchClientName(client);
      }
    }
  }, [initialData, MultiInputsIndex]);

  let parameterStats;

  if (initialData?.filters) {
    const validFilter = queueFilters.filters.find(
      (filter) => filter.name === initialData.filters[MultiInputsIndex].name
    );

    const mappedParameters = validFilter?.parameters
      ?.map((param: { name: string }) => {
        const value = initialData?.filters?.[MultiInputsIndex]?.parameters
          ? initialData.filters[MultiInputsIndex].parameters[param.name]
          : undefined;
        return { key: param.name, value };
      })
      .filter(({ key, value }: I.keyAndValue) => key && value !== undefined);

    parameterStats = mappedParameters?.map(
      ({ key, value }: I.keyAndValue, i: number) => {
        const identifier = `queue-managements-filters${MultiInputsIndex}-parameters${key}__${i}`;

        if (Array.isArray(value)) {
          return (
            <WrapItem key={identifier}>
              <Stat title={key} identifier={identifier}>
                <Wrap spacing="smallest">
                  {value.map((val, index) => {
                    const childKey = `queue-managements-filters${MultiInputsIndex}-parameters${key}_${i}_${index}`;
                    return (
                      <Badge
                        label={
                          key === 'clients' && isUUID(val)
                            ? clientNames?.[val] ?? 'Carregando...'
                            : value?.toString()
                        }
                        key={childKey}
                        schema="gray"
                        transparent
                      />
                    );
                  })}
                </Wrap>
              </Stat>
            </WrapItem>
          );
        }

        return (
          <WrapItem key={identifier}>
            <Stat
              title={key}
              text={value?.toString()}
              identifier={identifier}
            />
          </WrapItem>
        );
      }
    );
  }

  return (
    <Stack spacing="md" direction="row" flexWrap="wrap">
      <Wrap spacing="large">
        <WrapItem>
          <Stat
            title="Filtro"
            text={initialData.filters[MultiInputsIndex].name}
            identifier={`queue-managements-filters${MultiInputsIndex}-name`}
          />
        </WrapItem>

        {parameterStats}
      </Wrap>
    </Stack>
  );
}
