import React from 'react';
import { useAtom } from 'jotai';
import {
  Icon,
  IconButton,
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  Stack,
} from '@chakra-ui/react';
import { BiEditAlt, BiDotsVerticalRounded } from 'react-icons/bi';

import { initialDataBlocksAtom, isEditingBlocksAtom } from '../stores';
import CloneModal from '../clone';
import Title from '../../base/v2/title';
import Button from '../../base/v2/button';

export default function MenuBlocks(): React.ReactElement {
  const [isEditing, setIsEditing] = useAtom(isEditingBlocksAtom);
  const [initialData] = useAtom(initialDataBlocksAtom);

  const titleText = initialData.id
    ? `Bloco: ${initialData.description}`
    : 'Blocos';

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      pb="extra-large"
      pt="medium"
    >
      <Title
        showBackButton
        backButtonUrl="/blocks"
        text={titleText}
        copy={{ text: initialData.id }}
      />
      {initialData.id ? (
        <Stack direction="row" spacing="medium">
          {!isEditing && (
            <Button
              onClick={handleEditing}
              colorScheme="green"
              leftIcon={<Icon as={BiEditAlt} />}
            >
              Editar bloco
            </Button>
          )}

          <ChakraMenu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Icon as={BiDotsVerticalRounded} />}
              variant="ghost"
            />
            <MenuList p="small">
              <CloneModal />
            </MenuList>
          </ChakraMenu>
        </Stack>
      ) : null}
    </Stack>
  );
}
