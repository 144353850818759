import React from 'react';
import InformationBase from './baseInformation';
import InformationBaseEditBase from './baseInformationEdit';
import * as I from '../interfaces';

const SubmenuPartners = ({
  isDisabled,
  partner,
}: I.PartnersInfo): React.ReactElement => {
  return (
    <>
      {isDisabled ? (
        <InformationBase
          partner={partner}
          dataTestId="partner-submenu"
          name="Parceiro"
          identifierPrefix="partner"
        />
      ) : (
        <InformationBaseEditBase
          isDisabled={isDisabled}
          dataTestId="partner-submenu-edit"
          titleText="Informações do parceiro"
        />
      )}
    </>
  );
};

export default SubmenuPartners;
