import React from 'react';
import {
  Popover as ChakraPopover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Icon,
} from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';

import * as I from './interfaces';
import colors from '../../../styles/colors';

export default function Popover({
  content,
  title,
}: I.PopoverProps): React.ReactElement {
  return (
    <ChakraPopover isLazy>
      <PopoverTrigger>
        <Button
          type="button"
          variant="ghost"
          p="0"
          h="20px"
          w="20px"
          _focus={{
            border: 'none',
          }}
        >
          <Icon as={FiAlertCircle} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        background={colors.softGray}
        border={`2px solid ${colors.dark}`}
        _focus={{
          border: `2px solid ${colors.dark}`,
        }}
      >
        <PopoverArrow
          bg={colors.softGray}
          border={`2px solid ${colors.dark}`}
          borderBottom="0px"
          borderRight="0px"
        />
        <PopoverCloseButton />
        <PopoverHeader fontWeight="bold">{title || 'Descrição'}</PopoverHeader>
        <PopoverBody>{content}</PopoverBody>
      </PopoverContent>
    </ChakraPopover>
  );
}

Popover.defaultProps = {
  className: '',
};
