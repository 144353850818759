import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Button, Skeleton, Stack } from '@chakra-ui/react';

import * as I from './interfaces';

import { useOrder } from '../../providers/order';
import Stat from '../stat';
import {
  chakraSocialMidias,
  SocialMidiasModel,
} from '../../utils/social_midia';

export default function SocialMidias(): React.ReactElement {
  const { birotContext } = useOrder();
  const [isLoaded, setIsLoaded] = useState(false);
  const [avatar, setAvatar] = useState<I.Avatar>({
    image: '',
    index: 0,
  });

  const handleChangeAvatar = useCallback(
    ({ image, index }: I.Avatar): void => {
      setAvatar({ image, index });
    },
    [birotContext]
  );

  const handleColorScheme = useCallback(
    (index: number): string => {
      return index === avatar.index ? 'green' : 'white';
    },
    [avatar.index]
  );

  useEffect(() => {
    birotContext?.email?.linked_accounts?.forEach(
      ({ avatar: bureauAvatar }, index) => {
        if (bureauAvatar) {
          handleChangeAvatar({ image: bureauAvatar, index });
        }
      }
    );
    setIsLoaded(true);
  }, [birotContext]);

  return (
    <Skeleton isLoaded={isLoaded}>
      <Stack direction="row" align="center">
        <Avatar
          data-testid="avatar"
          src={avatar.image}
          borderWidth="3px"
          borderColor="v2.dark.100"
        />
        <Box>
          <Stat
            title="Vinculos"
            text=""
            fontSize="larger"
            identifier="bureau_email"
          >
            <Stack direction="row">
              {birotContext?.email?.linked_accounts?.map((item, index) => (
                <Button
                  key={`bureau_email--social-midia${item.account}`}
                  data-testid={`button-${item.account}`}
                  colorScheme={handleColorScheme(index)}
                  width="larger"
                  minWidth="larger"
                  height="larger"
                  padding="zero"
                  borderRadius="regular"
                  isDisabled={!item.avatar}
                  _disabled={{ opacity: 1 }}
                  onClick={(): void =>
                    handleChangeAvatar({ image: item.avatar, index })
                  }
                >
                  {chakraSocialMidias[item.account as SocialMidiasModel]()}
                </Button>
              ))}
            </Stack>
          </Stat>
        </Box>
      </Stack>
    </Skeleton>
  );
}
