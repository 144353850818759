import styled from 'styled-components';
import { Text as ChackraText } from '@chakra-ui/react';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled(ChackraText).attrs(() => ({
  color: 'v2.green.400',
  fontWeight: 600,
  fontSize: 'larger',
}))``;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  min-height: ${theme.sizes.bigger};
  margin-bottom: ${theme.sizes.smallest};
`;
