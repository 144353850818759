import React, { useEffect } from 'react';

import {
  IdColumn,
  orderColumnCreatedAt,
  orderColumnDate,
  orderColumnDocument,
  orderColumnEmail,
  orderColumnName,
  orderColumnPaymentsAmount,
  orderColumnPaymentsStatusChargeback,
  orderColumnReviewer,
  orderColumnScore,
  orderColumnStatus,
} from '../../components/base/v2/table/columns';
import useOrders from '../../hooks/api/orders';
import GenericListPage from '../genericList';
import { OrderModel } from '../../interfaces/order';
import useDocumentTitle from '../../hooks/documentTitle';
import { cleanFilters } from '../../components/base/v2/table/stores';
import { OrdersAPIsQueryType } from '../../hooks/api/orders/interfaces';

const OrdersList: React.FC = () => {
  useDocumentTitle('Trex - Pedidos');
  const { getFilteredOrders } = useOrders();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  return (
    <GenericListPage<OrderModel>
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Pedidos' }],
      }}
      title="Pedidos"
      table={{
        fetch: {
          apiCall: (props: OrdersAPIsQueryType) =>
            getFilteredOrders({ ...props, params: { status: 'finished' } }),
          title: 'pedidos',
        },
        body: {
          columns: [
            orderColumnName('/orders'),
            IdColumn,
            orderColumnPaymentsStatusChargeback,
            orderColumnScore,
            orderColumnDocument,
            orderColumnEmail,
            orderColumnPaymentsAmount,
            orderColumnCreatedAt('desc'),
            orderColumnDate,
            orderColumnStatus({}),
            orderColumnReviewer,
          ],
          searchLabel:
            'Busque por loja, ID da loja, documento, email ou ID de um pedido',
          accessURL: '/orders/info',
          searchMinWidth: '621px',
          isSearch: false,
          filters: { dateFromTo: true, orderStatus: true, filteredBy: true },
        },
      }}
    />
  );
};

export default OrdersList;
