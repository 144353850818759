import React, { useState } from 'react';

import Header from './header';
import Sidebar from './sidebar';

import * as I from './interface';
import * as S from './styles';

const Menu = ({ link }: I.MenuProps): React.ReactElement => {
  const [onlyIcons, setOnlyIcons] = useState(false);

  const handleMarginLeft = (): string => {
    return onlyIcons ? '60px' : '200px';
  };

  const handleChangeIcons = (): void => {
    setOnlyIcons(!onlyIcons);
  };

  return (
    <S.Navegation marginLeft={handleMarginLeft()}>
      <Header
        link={link}
        onlyIcons={onlyIcons}
        handleChangeIcons={handleChangeIcons}
      />
      <Sidebar onlyIcons={onlyIcons} />
    </S.Navegation>
  );
};

export default Menu;
