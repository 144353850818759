/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { FiLink2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Query, QueryResult } from '@material-table/core';
import GenericForm from '../../../../components/base/v2/genericForm';
import LoadingSpinner from '../../../../components/loadingSpinner';
import useGroups from '../../../../hooks/api/groups';
import useQuery from '../../../../hooks/queryParams';
import { GroupModel } from '../../../../interfaces/groups';
import DefaultTable from '../../../../components/base/v2/table';
import { ClientModel } from '../../../../interfaces/client';
import useClients from '../../../../hooks/api/clients';
import {
  nameAndIDColumn,
  documentColumn,
  ExternalIdColumn,
  IdColumn,
} from '../../../../components/base/v2/table/columns';

import * as S from './styles';

const GroupUpdateloadInfo = () => {
  const { keycloak } = useKeycloak();
  const { getOneGroup } = useGroups();

  const [group, setGroup] = useState<GroupModel>();
  const [loading, setLoading] = useState(true);

  const { getAllClients, postClientsBonds } = useClients();
  const tableRef = useRef<any>();
  const queryUrl = useQuery();
  const id = queryUrl.get('id') ?? undefined;

  const loadInfo = useCallback(async (): Promise<void> => {
    const response = await getOneGroup(id);
    setGroup(response.data);

    setLoading(false);
  }, [keycloak.token]);

  const reloadTable = useCallback(() => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange();
    }
  }, []);

  const onButtonClick = () => {
    if (tableRef.current) {
      tableRef.current.dataManager?.changeRowEditing();
      tableRef.current.setState({
        ...tableRef.current.dataManager?.getRenderState(),
        showAddRow: true,
      });
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  const loadTable = (
    query: Query<ClientModel>
  ): Promise<QueryResult<ClientModel>> =>
    new Promise((resolve, reject) => {
      const data = {
        groups: group,
      };

      getAllClients({ data, query })
        .then((result) => {
          if (result.data.items) {
            return resolve({
              data: result.data.items,
              page: result.data.page - 1,
              totalCount:
                result.data.total_of_items > 9999
                  ? 10000
                  : result.data.total_of_items,
            });
          }
          return resolve({
            data: [],
            page: result.data.page - 1,
            totalCount: result.data.total_of_items,
          });
        })
        .catch(() => {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
          return reject(toast.error('Nenhum resultado encontrado.'));
        });
    });

  const clientTableEdit = {
    onRowAdd: (data: { name: ClientModel }): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          const client = data.name;

          if (client?.id === undefined) {
            toast.warn(
              'É preciso selecionar um cliente para realizar o vínculo.'
            );
            resolve(reloadTable());
            resolve();
            return;
          }

          postClientsBonds({
            url: `clients/${client.id}/groups/${group?.id}`,
            version: client.version,
          })
            .then((res) => {
              if ([200, 201, 204].includes(res.request.status)) {
                toast.success('Vínculo realizado com sucesso!');
                reloadTable();
                resolve();
                return;
              }
              const { reason } = JSON.parse(res.request.response);
              toast.warn(`Não foi possível realizar o vínculo. ${reason}`);
            })
            .catch(() => {
              toast.warn('Não foi possível realizar o vínculo.');
              resolve(reloadTable());
            });
        }, 1000);
      }),
  };

  const tableColumns = [
    nameAndIDColumn('/client/info'),
    IdColumn,
    ExternalIdColumn,
    documentColumn,
  ];

  if (loading) {
    return (
      <S.LoadingContainer>
        <LoadingSpinner />
      </S.LoadingContainer>
    );
  }

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Grupo', href: '/groups' },
        { label: `${group?.name}`, href: `/groups/info?id=${group?.id}` },
        { label: 'Clientes' },
      ]}
      title={`${group?.name}`}
      handleAction={onButtonClick}
      actionText="Vincular nova lista"
      buttonIcon={FiLink2}
      isDisabled
      showBackButton
    >
      <DefaultTable
        columns={tableColumns}
        data={loadTable}
        searchLabel="Busque por nome ou ID"
        accessURL="/client/info"
        editable={clientTableEdit}
        tableRef={tableRef}
      />
    </GenericForm>
  );
};

export default GroupUpdateloadInfo;
