import styled from 'styled-components';

const Main = styled.div`
  position: relative;
  .leaflet-container {
    height: 500px;
  }
`;

export default Main;
