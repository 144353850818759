import React from 'react';
import { Heading } from '@chakra-ui/react';
import { HeadingProps } from '../../../interfaces/components/base/heading';

export default function Title({
  children,
  ...rest
}: HeadingProps): React.ReactElement {
  return (
    <Heading as="h1" {...rest}>
      {children}
    </Heading>
  );
}

Title.defaultProps = {
  size: 'xl',
  margin: '30px 0 27px',
};
