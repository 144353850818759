/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';
import { useAtom } from 'jotai';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormLabel, Icon, Input, InputGroup } from '@chakra-ui/react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { CiSearch } from 'react-icons/ci';
import { FormProvider, useForm } from 'react-hook-form';
import { MdOutlinePlaylistRemove } from 'react-icons/md';
import { filterByAtom, filtersAtom, searchTextAtom } from './stores';
import {
  filterByOptions,
  handleFormToFiltersModel,
  statusOptions,
  formatDateForPicker,
  handleStatusDefaultValue,
  handleFilterByDefaultValue,
  initialFilterState,
} from './utils';
import { customStyles } from '../inputs/searchselect';
import InputText from '../inputs/text';
import LoadingSpinner from '../../../loadingSpinner';
import Overlay from '../overlay';
import Button from '../button';

import * as I from './interfaces';
import * as S from './styles';

export const CustomToolbarComponent = ({
  label,
  toolbarProps,
  searchMinWidth,
  width = '50%',
  padding = 'medium',
  marginBottom = 'medium',
  filters: filtersProps,
}: I.CustomTollbarComponentProps) => {
  const [searchText, setSearchText] = useAtom(searchTextAtom);
  const [filterBy, setFilterBy] = useAtom(filterByAtom);
  const [filters, setFilters] = useAtom(filtersAtom);
  const defaultValues = {
    from: formatDateForPicker(filters?.['order.date.from']) || '',
    to: formatDateForPicker(filters?.['order.date.to']) || '',
    status: filters?.recommendation || filters?.status || '',
    filterBy: filterBy || '',
    text: searchText || '',
  };

  const methods = useForm({
    defaultValues,
  });
  const { register, setValue, handleSubmit, reset } = methods;
  const selectStatusRef: any = useRef();
  const selectFilterRef: any = useRef();

  const handleFilterChange = (newValue: unknown) => {
    const selectedOption = newValue as I.SelectOptionType;
    setValue('filterBy', selectedOption.value);
  };

  const handleStatusChange = (newValue: unknown) => {
    const selectedOption = newValue as I.SelectOptionType;
    setValue('status', selectedOption.value);
  };

  const handleClearInputs = () => {
    reset();
    if (filtersProps?.orderStatus)
      selectStatusRef.current.setValue({ label: 'Nenhum', value: '' });
    if (filtersProps?.filteredBy)
      selectFilterRef.current.setValue({ label: 'Nenhum', value: '' });
  };

  const handleCleanFilters = () => {
    handleClearInputs();

    if (JSON.stringify(defaultValues) !== JSON.stringify(initialFilterState)) {
      setFilters({});
      setFilterBy('');
      setSearchText('');
      toolbarProps.onSearchChanged('');
    }
  };

  const startSearch = (values: I.FilterFormValues) => {
    setFilters(handleFormToFiltersModel(values));
    setFilterBy(values?.filterBy || '');
    setSearchText(values?.text || '');

    if (values.filterBy) {
      toolbarProps.onSearchChanged('');
    } else {
      toolbarProps.onSearchChanged(values?.text || '');
    }
  };

  return (
    <S.FilterContainer padding={padding} marginBottom={marginBottom}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(startSearch)}>
          <S.ModWrapper>
            {filtersProps?.dateFromTo ? (
              <S.WrapItem>
                <S.Stack>
                  <S.StyledFormControl>
                    <InputGroup>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Input
                          {...register('from')}
                          label="from"
                          type="datetime-local"
                          minH="2.75rem"
                          borderColor="neutral.500"
                        />
                      </MuiPickersUtilsProvider>
                      <FormLabel htmlFor="from">Data inicial</FormLabel>
                    </InputGroup>
                  </S.StyledFormControl>

                  <S.StyledFormControl>
                    <InputGroup>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Input
                          {...register('to')}
                          label="to"
                          type="datetime-local"
                          minH="2.75rem"
                          borderColor="neutral.500"
                        />
                      </MuiPickersUtilsProvider>
                      <FormLabel htmlFor="to">Data final</FormLabel>
                    </InputGroup>
                  </S.StyledFormControl>
                </S.Stack>
              </S.WrapItem>
            ) : null}

            {filtersProps?.orderStatus ? (
              <S.WrapItem>
                <S.StyledFormControl variant="none">
                  <S.ModInputGroup>
                    <FormLabel variant="floating" className="active">
                      Status
                    </FormLabel>

                    <Select
                      {...register('status')}
                      ref={selectStatusRef}
                      placeholder=" "
                      styles={customStyles}
                      options={statusOptions}
                      hideSelectedOptions={false}
                      onChange={handleStatusChange}
                      defaultValue={handleStatusDefaultValue(
                        defaultValues.status
                      )}
                    />
                  </S.ModInputGroup>
                </S.StyledFormControl>
              </S.WrapItem>
            ) : null}

            {filtersProps?.filteredBy ? (
              <S.WrapItem>
                <S.StyledFormControl variant="none">
                  <S.ModInputGroup>
                    <FormLabel variant="floating" className="active">
                      Filtrar por
                    </FormLabel>

                    <Select
                      {...register('filterBy')}
                      ref={selectFilterRef}
                      placeholder=" "
                      styles={customStyles}
                      options={filterByOptions}
                      hideSelectedOptions={false}
                      onChange={handleFilterChange}
                      defaultValue={handleFilterByDefaultValue(
                        defaultValues.filterBy
                      )}
                    />
                  </S.ModInputGroup>
                </S.StyledFormControl>
              </S.WrapItem>
            ) : null}

            <S.WrapButton minWidth={searchMinWidth ?? '300px'}>
              <InputText
                {...register('text')}
                label={label}
                formcontrol={{ style: { width } }}
              />

              <Button
                onClick={handleCleanFilters}
                width="bigger-plus"
                borderRadius="larger"
              >
                <Icon
                  ml={1}
                  as={MdOutlinePlaylistRemove}
                  height="larger"
                  width="larger"
                />
              </Button>
              <Button type="submit" width="bigger-plus" borderRadius="larger">
                <Icon as={CiSearch} height="larger" width="larger" />
              </Button>
            </S.WrapButton>
          </S.ModWrapper>
        </form>
      </FormProvider>
    </S.FilterContainer>
  );
};

export const EnhancedToolbar =
  ({
    propsComponents,
    label,
    filters,
    searchMinWidth,
  }: I.EnhancedToolbarProps) =>
  (toolbarProps: I.ToolbarProps) => {
    const Toolbar = propsComponents?.Toolbar;

    return (
      <div>
        {Toolbar && <Toolbar {...toolbarProps} />}
        <CustomToolbarComponent
          label={label}
          width="100%"
          toolbarProps={toolbarProps}
          filters={filters}
          searchMinWidth={searchMinWidth}
        />
      </div>
    );
  };

export const CustomLoadingComponent = () => {
  return (
    <Overlay>
      <LoadingSpinner />
    </Overlay>
  );
};

export const CustomIcon = () => {
  return <Icon as={RiArrowRightSLine} />;
};
