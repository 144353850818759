import React from 'react';
import GroupForm from '../../../components/forms/groups';
import * as I from '../../../interfaces/groups';
import { useGroups } from '../../../providers/groups';

export default function GroupsFormContainer({
  isDisabled,
  setIsDisabled,
}: Readonly<I.PropsGroupsForm>): React.ReactElement {
  const { dataContext, initialized } = useGroups();

  return (
    <>
      {initialized && (
        <GroupForm
          defaultData={dataContext}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
        />
      )}
    </>
  );
}
