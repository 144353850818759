import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import DoubleCheckModal from '../../../components/base/v2/DoubleCheckModal';
import InputText from '../../../components/base/v2/inputs/text';
import InputSelect from '../../../components/base/v2/inputs/select';
import { useRulesModels } from '../../../providers/models';
import useModels from '../../../hooks/api/models';
import { CloneModalProps } from '../interfaces';
import { initialValues } from './utils';

import * as I from './interfaces';

const CloneModal = ({
  isOpenModal,
  onClose,
}: CloneModalProps): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [clonedObject, setClonedObject] = useState<I.CloneModel>(initialValues);
  const { isDisabled, handleDisabled } = useRulesModels();
  const { push } = useHistory();
  const { createModel } = useModels();
  const { getValues } = useFormContext();

  useEffect(() => {
    const data = getValues();
    setClonedObject({
      description: data.description,
      external_uri: data.external_uri,
      features: data.features,
      backend: data.backend,
    });
  }, []);

  const onClone = async () => {
    setIsLoading(true);
    if (isDisabled) {
      handleDisabled();
    }

    if (!clonedObject) {
      setIsLoading(false);
      onClose();
      return;
    }

    try {
      await createModel(clonedObject).then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          toast.success('Modelo clonado com sucesso!');
          setTimeout(() => {
            push(`/models/info?id=${res.data.id}`);
            window.location.reload();
          }, 1000);
          return;
        }
        const { reason } = JSON.parse(res.request.response);
        toast.warn(`Não foi possível clonar o modelo: ${reason}`);
      });
    } catch (error) {
      toast.warn(
        `Não foi possível clonar o modelo. Tente novamente mais tarde.`
      );
    }
    onClose();
    setIsLoading(false);
  };

  const handleDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClonedObject({ ...clonedObject, description: e.target.value });
  };

  const handleBackendChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setClonedObject({ ...clonedObject, backend: e.target.value });
  };

  return (
    <>
      <DoubleCheckModal
        title="Clonar modelo existente"
        description={`Ao clonar o modelo ${clonedObject?.description}, é importante lembrar que quaisquer alterações não salvas não serão incluídas na cópia.`}
        isOpen={isOpenModal}
        onClose={onClose}
        modal={{ size: 'xl' }}
        primaryButton={{
          colorScheme: 'green',
          text: 'Clonar modelo',
          action: onClone,
        }}
        isLoading={isLoading}
      >
        <Stack
          spacing="medium"
          background="white"
          p="medium"
          borderRadius="extra-large"
        >
          <Text fontWeight="bold">Informações do modelo</Text>
          <Stack direction="row" spacing="medium">
            <InputText
              label="Modelo"
              value={clonedObject.description}
              onChange={handleDescription}
              formcontrol={{}}
            />
            <InputSelect
              label="Backend"
              value={clonedObject.backend}
              options={[
                { option: 'Selecione', value: '' },
                { option: 'knative', value: 'knative' },
                { option: 'ai-platform', value: 'ai-platform' },
              ]}
              formcontrol={{}}
              onChange={handleBackendChange}
            />
          </Stack>
        </Stack>
      </DoubleCheckModal>
    </>
  );
};

export default CloneModal;
