import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';

import * as I from './interfaces';

export default function Notifications({ client }: I.NotificationsProps) {
  return (
    <>
      {client?.is_runnable === false && (
        <Alert status="error" borderRadius="4px">
          <AlertIcon />
          <AlertTitle>Cliente sem Regra!</AlertTitle>
          <AlertDescription>
            Podem ocorrer erros inesperados, como a não execução de transações
            em nosso sistema.
          </AlertDescription>
        </Alert>
      )}
    </>
  );
}
