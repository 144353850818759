import { ReviewDetail, ReviewDetailEvents } from '../interfaces/order';
import { checkFullDateList } from './check_values';

export default function handleDisplayDelayedTime(reviewDetail?: ReviewDetail) {
  if (!reviewDetail?.events || reviewDetail?.state !== 'delayed') return '';

  const delays = reviewDetail.events.filter((item) => item.action === 'delay');

  if (delays.length === 0) {
    return '';
  }

  const sortFn = (a: ReviewDetailEvents, b: ReviewDetailEvents) =>
    new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  delays.sort(sortFn);

  const lastDelay = delays[0];
  return checkFullDateList(lastDelay.until);
}
