import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  GridItem,
} from '@chakra-ui/react';

import * as S from './styles';
import * as S2 from '../styles';
import * as I from './interfaces';

import { useOrder } from '../../../../providers/order';
import Stat from '../../../stat';
import ImageWithText from '../../../base/image_with_text';
import StoreImg from '../../../../assets/icons/store.svg';
import LoadingSpinner from '../../../loadingSpinner';
import { checkEmptyObject, checkString } from '../../../../utils/check_values';

const PurchaseInfo = () => {
  const [loading, setLoading] = useState(true);
  const { orderContext } = useOrder();
  const image = { alt: 'store-image', src: StoreImg };

  useEffect(() => {
    if (orderContext) {
      setLoading(false);
    }
  });

  const groupedBySeller = orderContext?.order?.products
    ? orderContext.order.products.reduce<I.GroupedProducts>((acc, product) => {
        const sellerId = product.seller ? product.seller.id : 'no-seller';

        if (!acc[sellerId]) {
          acc[sellerId] = [];
        }

        acc[sellerId].push(product);

        return acc;
      }, {})
    : {};

  const allIndexes =
    groupedBySeller && Object.keys(groupedBySeller).map((_, index) => index);

  return (
    <S2.Container data-testid="purchase-container">
      <S2.TitleContainer>
        <S2.Text>
          Carrinho ({orderContext?.order?.products?.length || '0'})
        </S2.Text>
      </S2.TitleContainer>
      <S.Card>
        {loading && (
          <GridItem colSpan={8} rowSpan={5}>
            <LoadingSpinner />
          </GridItem>
        )}
        {!loading && (
          <>
            {!checkEmptyObject(groupedBySeller) ? (
              <>
                <Accordion allowToggle allowMultiple defaultIndex={allIndexes}>
                  {Object.entries(groupedBySeller).map(
                    ([sellerId, products]) => {
                      const { seller } = products[0];
                      const key = `seller-${sellerId}`;
                      return (
                        <AccordionItem key={key} border="none">
                          <AccordionButton>
                            <S.GridContainer>
                              <GridItem colSpan={3} textAlign="left">
                                <Stat
                                  title="Seller"
                                  text={checkString(seller?.name)}
                                  identifier="seller-name"
                                  copy={checkString(seller?.name)}
                                />
                              </GridItem>
                              <GridItem colSpan={2} textAlign="left">
                                <Stat
                                  title="ID"
                                  text={`${seller?.id.substring(
                                    0,
                                    6
                                  )}...${seller?.id.substring(
                                    seller?.id.length - 6,
                                    seller?.id.length
                                  )}`}
                                  identifier="seller-id"
                                  copy={checkString(seller?.id)}
                                />
                              </GridItem>
                              <GridItem colSpan={2} textAlign="left">
                                <Stat
                                  title="Documento"
                                  text={checkString(seller?.document)}
                                  identifier="seller-document"
                                  copy={checkString(seller?.document)}
                                />
                              </GridItem>
                            </S.GridContainer>
                            <AccordionIcon />
                          </AccordionButton>

                          <AccordionPanel pb={4}>
                            {products.map((product, index) => {
                              const childKey = `${key}-product-${product.name}-${index}`;
                              return (
                                <S.GridContainer key={childKey}>
                                  <GridItem colSpan={4}>
                                    <Stat
                                      title="Produto"
                                      text={checkString(product?.name)}
                                      identifier="products"
                                      copy={checkString(product?.name)}
                                    />
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <Stat
                                      title="Quant"
                                      text={
                                        product?.quantity || 'Não informado'
                                      }
                                      identifier="products-amount"
                                      copy={
                                        product?.quantity || 'Não informado'
                                      }
                                    />
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <Stat
                                      title="Preço"
                                      text={product['price@display']}
                                      identifier="products-price"
                                      copy={product['price@display']}
                                    />
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <Stat
                                      title="Desconto"
                                      text={product['discount@display']}
                                      identifier="products-discount"
                                      copy={product['discount@display']}
                                    />
                                  </GridItem>
                                  {products?.length > 1 && (
                                    <GridItem colSpan={7}>
                                      <Divider />
                                    </GridItem>
                                  )}
                                </S.GridContainer>
                              );
                            })}
                          </AccordionPanel>
                        </AccordionItem>
                      );
                    }
                  )}
                </Accordion>
              </>
            ) : (
              <GridItem
                data-testid="purchase-container-with-no-content"
                colSpan={5}
                rowSpan={2}
              >
                <ImageWithText
                  title="Nenhum carrinho informado"
                  text="Não recebemos as informações do carrinho desta compra."
                  image={image}
                />
              </GridItem>
            )}
          </>
        )}
      </S.Card>
    </S2.Container>
  );
};

export default PurchaseInfo;
