import { Center } from '@chakra-ui/react';

import * as I from './interfaces';

export default function Overlay({
  children,
  ...props
}: I.OverlayProps): React.ReactElement {
  return (
    <Center height="100%" bg="rgba(255, 255, 255, 0.7)" {...props} blur="2">
      {children}
    </Center>
  );
}
