/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { AbsoluteCenter, Box, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import { toast } from 'react-toastify';
import * as I from '../../interfaces/blocks';
import { CommonRuleModel } from '../../interfaces/common';

import MenuBlocks from './menu';
import SubmenuBlocks from './submenu';
import Form from './form';
import {
  rulesDataAtom,
  initialDataBlocksAtom,
  isLoadedBlocksAtom,
  isEditingBlocksAtom,
  isEditingNodeBlocksAtom,
} from './stores';
import { convertFormToApiFormat, defaultFlow } from '../rulesFlowInfo/utils';
import DoubleCheckModal from '../base/v2/DoubleCheckModal';
import LoadingSpinner from '../loadingSpinner';
import useRulesFlows from '../../hooks/api/rules_flows';
import useBlocks from '../../hooks/api/blocks';
import useQuery from '../../hooks/queryParams';
import clone from '../../utils/clone';
import GenericFooter from '../complex/form/generic/footer';

export default function Body(): React.ReactElement {
  const query = useQuery();
  const id = query.get('id');
  const { push } = useHistory();
  const { getRules } = useRulesFlows();
  const { getOneBlock, createBlock, updateBlock } = useBlocks();
  const [{ rules }, setRulesData] = useAtom(rulesDataAtom);
  const [initialData, setInitialData] = useAtom(initialDataBlocksAtom);
  const [, setIsNodeEditing] = useAtom(isEditingNodeBlocksAtom);
  const [isEditing, setIsBlockEditing] = useAtom(isEditingBlocksAtom);
  const [isLoaded, setIsLoaded] = useAtom(isLoadedBlocksAtom);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onDescartOpen,
    onClose: onDescartClose,
    isOpen: isDescartOpen,
  } = useDisclosure();
  const [updateCount, setUpdateCount] = useState(0);

  const methods = useForm({
    defaultValues: defaultFlow,
  });

  const handleBlockError = (error: { response: string }) => {
    onClose();
    const { reason } = JSON.parse(error.response);
    const fieldRegex = /the field '([^']+)'/;
    const match = clone(reason).match(fieldRegex);

    if (match) {
      const field = match[1];
      methods.setError(field, {
        type: 'manual',
        message: reason,
      });
    }
    toast.error(`Não foi possível criar o bloco: ${reason}`);
  };

  const handleSuccessBlockResponse = (res: any, isUpdate: boolean) => {
    if ([200, 201, 204].includes(res.request.status)) {
      const successMessage = isUpdate
        ? 'Bloco alterado com sucesso!'
        : 'Bloco publicado com sucesso!';
      toast.success(successMessage);

      if (!isUpdate) {
        push(`/blocks/info?id=${res.data.id}`);
      } else {
        setUpdateCount((prevCount) => prevCount + 1);
      }

      onClose();
    } else {
      handleBlockError(res.request);
    }
  };

  const onBlockSubmit = methods.handleSubmit(
    async (data: I.BlocksModelForm): Promise<void> => {
      methods.formState.isSubmitting = true;
      const sendData = convertFormToApiFormat(rules, data);

      try {
        let res;

        if (id) {
          res = await updateBlock({
            id: id ?? '',
            data: sendData,
            version: initialData.version,
          });
        } else {
          res = await createBlock({ data: sendData });
        }

        handleSuccessBlockResponse(res, !!id);
      } catch (error) {
        onClose();
        toast.error(
          'Não foi possível criar o bloco. Tente novamente mais tarde.'
        );
      }

      methods.formState.isSubmitting = false;
    }
  );

  const handleBlockDescart = async (): Promise<void> => {
    setIsLoaded(false);
    methods.reset(initialData);

    setTimeout(() => {
      setIsNodeEditing(false);
      onDescartClose();
      setIsBlockEditing(false);
      setIsLoaded(true);
    }, 300);
  };

  useEffect(() => {
    let cleanUP = true;
    setIsLoaded(false);
    setIsBlockEditing(false);
    setIsNodeEditing(false);
    setInitialData(defaultFlow);

    const handleGetRules = async () => {
      try {
        const response = await getRules();
        if (cleanUP && response?.data?.items) {
          setRulesData({
            rules: response.data.items,
            rulesNames: response.data.items.map((rule: CommonRuleModel) => ({
              label: rule.name,
              value: rule.name,
            })),
          });
        } else {
          toast.error(
            'Não foi possível buscar as regras. Tente novamente mais tarde.'
          );
        }
      } catch {
        toast.error(
          'Ocorreu um erro inesperado ao buscar regras. Tente novamente mais tarde.'
        );
      }
    };

    const handleGetBlock = async () => {
      if (id) {
        try {
          const response = await getOneBlock({ id: id ?? '' });
          if (cleanUP && response?.data) {
            setInitialData(response.data);
            methods.reset(response.data);
          } else {
            toast.error(
              'Não foi possível buscar o bloco. Tente novamente mais tarde.'
            );
          }
        } catch {
          toast.error(
            'Ocorreu um erro inesperado ao buscar o bloco. Tente novamente mais tarde.'
          );
        }
      }
    };

    const fetchBlockData = async () => {
      await Promise.all([handleGetRules(), handleGetBlock()]);

      if (cleanUP) {
        if (!id) setIsBlockEditing(true);
        setIsLoaded(true);
      }
    };

    fetchBlockData();

    return () => {
      cleanUP = false;
    };
  }, [id, updateCount]);

  if (!isLoaded) {
    return (
      <AbsoluteCenter>
        <LoadingSpinner />
      </AbsoluteCenter>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={onBlockSubmit}>
          <Box background="neutral.100" zIndex="5" pb="regular">
            <MenuBlocks />
            <SubmenuBlocks />
          </Box>
          <Form />
          <GenericFooter
            publishNewText="Publicar bloco"
            publishUpdateText="Publicar alterações"
            alertText="Revise as informações antes de publicar o bloco."
            onDescartOpen={onDescartOpen}
            onOpen={onOpen}
            hasID={Boolean(initialData.id)}
            isEditing={isEditing}
          />

          <DoubleCheckModal
            title="Publicar novo bloco"
            description="Ao publicar as alterações, as modificações serão automaticamente aplicadas ao bloco vinculado."
            isOpen={isOpen}
            onClose={onClose}
            modal={{ size: 'xl' }}
            primaryButton={{
              colorScheme: 'green',
              text: 'Publicar bloco',
              action: onBlockSubmit,
            }}
            isLoading={methods.formState.isSubmitting}
          />

          <DoubleCheckModal
            title="Descartar alterações do bloco"
            description="Ao descartar as alterações que você está fazendo agora, o formulário voltará ao estado da última atualização, eliminando todas as modificações que você fez na edição atual. Tem certeza que deseja prosseguir?"
            isOpen={isDescartOpen}
            onClose={onDescartClose}
            modal={{ size: 'xl' }}
            primaryButton={{
              colorScheme: 'green',
              text: 'Descartar alterações',
              action: handleBlockDescart,
            }}
            isLoading={!isLoaded}
          />
        </form>
      </FormProvider>
    </>
  );
}
