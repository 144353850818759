import { Text as ChakraText } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const Text = styled(ChakraText).attrs(() => ({
  fontWeight: '600',
}))``;
