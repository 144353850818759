/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colors.v2.gray[100]};
  min-height: 100vh;
  padding-right: 2.5rem;
  padding-left: 1.875rem;
  position: relative;
  padding-top: ${({ theme }) => theme.spacing(6.5)}px;
`;
