/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { AbsoluteCenter, Box, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loadingSpinner';
import SubmenuFlows from './submenu';
import FormFlows from './form';
import useRulesFlows from '../../hooks/api/rules_flows';
import {
  rulesDataAtom,
  initialDataAtom,
  isLoadedAtom,
  isEditingAtom,
  isEditingNodeAtom,
} from './stores';
import useQuery from '../../hooks/queryParams';
import DoubleCheckModal from '../base/v2/DoubleCheckModal';
import GenericFooter from '../complex/form/generic/footer';
import { convertFormToApiFormat, defaultFlow } from './utils';
import clone from '../../utils/clone';
import BondTags from '../bondTags';
import { FormToScopeModelType } from '../bondTags/interfaces';
import { handleTagsFormToScopeModel } from '../bondTags/utils';

import * as I from '../../interfaces/flow';

const Body = (): React.ReactElement => {
  const query = useQuery();
  const id = query.get('id');
  const { push } = useHistory();
  const { getRules, getOneFlow, updateFlow, createFlow } = useRulesFlows();
  const [{ rules }, setRulesData] = useAtom(rulesDataAtom);
  const [initialData, setInitialData] = useAtom(initialDataAtom);
  const [, setIsNodeEditing] = useAtom(isEditingNodeAtom);
  const [isEditing, setIsFlowEditing] = useAtom(isEditingAtom);
  const [isLoaded, setIsLoaded] = useAtom(isLoadedAtom);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onDescartOpen,
    onClose: onDescartClose,
    isOpen: isDescartOpen,
  } = useDisclosure();
  const [updateCount, setUpdateCount] = useState(0);

  const methods = useForm({
    defaultValues: defaultFlow,
  });

  const handleError = (error: { response: string }) => {
    onClose();
    const { reason } = JSON.parse(error.response);
    const fieldRegex = /the field '([^']+)'/;
    const match = clone(reason).match(fieldRegex);

    if (match) {
      const field = match[1];
      methods.setError(field, {
        type: 'manual',
        message: reason,
      });
    }

    toast.error(`Não foi possível criar o fluxo: ${reason}`);
  };

  const handleSuccessResponse = (res: any, isUpdate: boolean) => {
    if ([200, 201, 204].includes(res.request.status)) {
      const successMessage = isUpdate
        ? 'Fluxo alterado com sucesso!'
        : 'Fluxo publicado com sucesso!';
      toast.success(successMessage);

      if (!isUpdate) {
        push(`/rules-flow/info?id=${res.data.id}`);
      } else {
        setUpdateCount((prevCount) => prevCount + 1);
      }

      onClose();
    } else {
      handleError(res.request);
    }
  };

  const onSubmit = methods.handleSubmit(
    async (data: I.FluxModelForm): Promise<void> => {
      methods.formState.isSubmitting = true;
      const sendData = convertFormToApiFormat(rules, data);

      try {
        let res;

        if (id) {
          res = await updateFlow({
            id: id ?? '',
            data: sendData,
            version: initialData.version,
          });
        } else {
          res = await createFlow(sendData);
        }

        handleSuccessResponse(res, !!id);
      } catch (error) {
        onClose();
        toast.error(
          'Não foi possível criar o fluxo. Tente novamente mais tarde.'
        );
      }

      methods.formState.isSubmitting = false;
    }
  );

  const handleDescart = async (): Promise<void> => {
    setIsLoaded(false);
    methods.reset(initialData);

    setTimeout(() => {
      setIsNodeEditing(false);
      onDescartClose();
      setIsFlowEditing(false);
      setIsLoaded(true);
    }, 300);
  };

  useEffect(() => {
    let isComponentMounted = true;
    setIsLoaded(false);
    setIsFlowEditing(false);
    setIsNodeEditing(false);
    setInitialData(defaultFlow);

    const handleGetRules = async () => {
      try {
        const response = await getRules();
        if (isComponentMounted && response?.data?.items) {
          setRulesData({
            rules: response.data.items,
            rulesNames: response.data.items.map((rule: I.RuleModel) => ({
              label: rule.name,
              value: rule.name,
            })),
          });
        } else {
          toast.error(
            'Não foi possível buscar as regras. Tente novamente mais tarde.'
          );
        }
      } catch {
        toast.error(
          'Ocorreu um erro inesperado ao buscar regras. Tente novamente mais tarde.'
        );
      }
    };

    const handleGetFlow = async () => {
      if (id) {
        try {
          const response = await getOneFlow(id ?? '');
          if (isComponentMounted && response?.data) {
            setInitialData(response.data);
            methods.reset(response.data);
          } else {
            toast.error(
              'Não foi possível buscar o fluxo. Tente novamente mais tarde.'
            );
          }
        } catch {
          toast.error(
            'Ocorreu um erro inesperado ap buscar o fluxo. Tente novamente mais tarde.'
          );
        }
      }
    };

    const fetchData = async () => {
      await Promise.all([handleGetRules(), handleGetFlow()]);

      if (isComponentMounted) {
        if (!id) setIsFlowEditing(true);
        setIsLoaded(true);
      }
    };

    fetchData();

    return () => {
      isComponentMounted = false;
    };
  }, [id, updateCount]);

  const handleTagSubmit = ({ data, type, scope }: FormToScopeModelType) => {
    const formData = handleTagsFormToScopeModel({
      scope,
      data,
      type,
    });

    return updateFlow({
      id: scope.id,
      data: formData,
      version: initialData.version,
    }).finally(() => {
      setUpdateCount((prevCount) => prevCount + 1);
    });
  };

  if (!isLoaded) {
    return (
      <AbsoluteCenter>
        <LoadingSpinner />
      </AbsoluteCenter>
    );
  }

  return (
    <>
      {initialData.id && (
        <BondTags
          scope={initialData}
          scopeName="flow"
          onSubmitScope={handleTagSubmit}
        />
      )}

      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Box background="neutral.100" zIndex="5" pb="regular">
            <SubmenuFlows />
          </Box>
          <FormFlows />

          <GenericFooter
            publishNewText="Publicar Fluxo"
            publishUpdateText="Publicar alterações"
            alertText="Revise as informações antes de publicar o fluxo."
            onDescartOpen={onDescartOpen}
            onOpen={onOpen}
            hasID={Boolean(initialData.id)}
            isEditing={isEditing}
          />

          <DoubleCheckModal
            title="Publicar novo fluxo"
            description="Ao publicar as alterações, elas serão vinculadas ao cliente."
            isOpen={isOpen}
            onClose={onClose}
            modal={{ size: 'xl' }}
            primaryButton={{
              colorScheme: 'green',
              text: 'Publicar fluxo',
              action: onSubmit,
            }}
            isLoading={methods.formState.isSubmitting}
          />

          <DoubleCheckModal
            title="Descartar alterações do fluxo"
            description="Ao descartar as alterações que você está fazendo agora, 
            o formulário voltará ao estado da última atualização, eliminando todas 
            as modificações que você fez na edição atual. Tem certeza que deseja prosseguir?"
            isOpen={isDescartOpen}
            onClose={onDescartClose}
            modal={{ size: 'xl' }}
            primaryButton={{
              colorScheme: 'green',
              text: 'Descartar alterações',
              action: handleDescart,
            }}
            isLoading={!isLoaded}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default Body;
