import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack, Text } from '@chakra-ui/react';

import * as I from './interfaces';

import InputText from '../../../../base/v2/inputs/text';
import InputSelect from '../../../../base/v2/inputs/select';

export default function InformationBaseEditBase({
  dataTestId,
  titleText,
}: Readonly<I.InformationBaseEditBaseProps>): React.ReactElement {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack
      data-testid={dataTestId}
      spacing="medium"
      background="white"
      p="medium"
      borderRadius="extra-large"
    >
      <Text fontWeight="bold">{titleText}</Text>
      <Stack direction="row" spacing="medium">
        <InputText
          data-testid={`${dataTestId}--name`}
          label="Fila"
          {...register('name')}
          formcontrol={{
            isRequired: true,
            error: errors.name,
            style: { width: '25%' },
          }}
        />

        <InputText
          data-testid={`${dataTestId}--description`}
          label="Descrição"
          {...register('description')}
          formcontrol={{
            isRequired: true,
            error: errors.description,
          }}
        />

        <InputSelect
          data-testid={`${dataTestId}--priority`}
          label="Prioridade"
          options={[
            { option: 'Padrão', value: 'standard' },
            { option: 'Alto', value: 'high' },
          ]}
          {...register('priority')}
          formcontrol={{
            isRequired: true,
            error: errors.priority,
          }}
        />

        <InputSelect
          data-testid={`${dataTestId}--status`}
          label="Status"
          options={[
            { option: 'Ativo', value: 'active' },
            { option: 'Inativo', value: 'inactive' },
          ]}
          {...register('status')}
          formcontrol={{
            isRequired: true,
            error: errors.status,
          }}
        />
      </Stack>
    </Stack>
  );
}
