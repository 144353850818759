import * as Yup from 'yup';

const validateSchema = Yup.object().shape({
  document: Yup.string().required('É preciso informar o CPF ou CNPJ'),
  name: Yup.string().required('É preciso informar um Nome'),
  contacts: Yup.array()
    .of(
      Yup.object()
        .shape({
          name: Yup.string().required('Nome é obrigatório'),
          phone: Yup.string().notRequired(),
          email: Yup.string().email('E-mail inválido').notRequired(),
        })
        .test(
          'phone-or-email',
          'Telefone ou Email é obrigatório',
          function validatePhoneOrEmail(contact) {
            if (!contact.phone && !contact.email) {
              return false;
            }
            return true;
          }
        )
    )
    .min(1, 'Deve haver pelo menos um contato.'),
});

export default validateSchema;
