import styled from 'styled-components';
import { Grid, Text } from '@chakra-ui/react';
import { styledTheme, theme } from '../../../../styles/theme';

export const Card = styled(Grid).attrs(() => ({
  bg: 'white',
  templateColumns: 'repeat(4, 1fr)',
  gap: 3,
}))`
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  min-height: ${styledTheme.spacing(10)}px;
  border-radius: ${styledTheme.spacing(2)}px;
  padding: ${styledTheme.spacing(2)}px;
  word-break: break-word;
`;

export const ContentText = styled(Text).attrs(() => ({
  color: theme.colors.v2.green[400],
}))``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;
