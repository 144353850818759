import styled from 'styled-components';
import { Text } from '@chakra-ui/react';

export const Container = styled.div`
  .MuiTableCell-head {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.v2.dark[200]};
    font-size: ${({ theme }) => theme.spacing(1.5)}px;
    background: ${({ theme }) => theme.colors.v2.gray[100]};
  }

  .MuiPaper-elevation2 {
    box-shadow: none;

    .MuiToolbar-root {
      padding: 10px 20px;

      .MuiInputBase-root {
        margin-right: 20px;
        .MuiSelect-root {
          position: relative;
          left: 0;
          border: 1px solid ${({ theme }) => theme.colors.v2.white[200]};
          border-radius: 5px;
          font-size: 12px;

          > div {
            color: ${({ theme }) => theme.colors.v2.white[400]};
          }
        }
      }

      .MuiTypography-caption {
        padding: 5px 14px;
        color: ${({ theme }) => theme.colors.v2.white[400]};
        border-bottom: 1px solid ${({ theme }) => theme.colors.v2.white[200]};
        border-left: 1px solid ${({ theme }) => theme.colors.v2.white[200]};
        border-top: 1px solid ${({ theme }) => theme.colors.v2.white[200]};
      }

      .MuiButtonBase-root {
        height: 25px;
        width: 25px;
        background-color: ${({ theme }) => theme.colors.v2.white[100]};
        border-left: 1px solid ${({ theme }) => theme.colors.v2.white[200]};
        border-bottom: 1px solid ${({ theme }) => theme.colors.v2.white[200]};
        border-top: 1px solid ${({ theme }) => theme.colors.v2.white[200]};
        border-radius: 0;
      }

      > div:last-child {
        > span {
          .MuiButtonBase-root {
            margin-top: 17px;
            height: calc(100% - 35px);
          }
          > span:first-child {
            .MuiButtonBase-root {
              border-radius: 10px 0 0 10px;
            }
          }

          > span:last-child {
            .MuiButtonBase-root {
              border-radius: 0 10px 10px 0;
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: ${({ theme }) => theme.spacing(1.5)}px !important;
          font-weight: 600 !important;
          line-height: 18px !important;
          color: ${({ theme }) => theme.colors.v2.black[200]} !important;
          border-bottom: 1px solid ${({ theme }) => theme.colors.v2.gray[100]};
        }
      }
    }
  }
`;

export const ItemText = styled(Text).attrs(() => ({
  fontSize: '14px',
}))`
  margin-right: 2.5px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  word-break: break-word;
`;
