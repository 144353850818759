import React from 'react';
import { GridItem } from '@chakra-ui/react';
import { checkString } from '../../../../../utils/check_values';
import BondsText from '../../../../bonds';
import Stat from '../../../../stat';
import * as I from './interface';
import jaro from '../../../../../utils/jaro';
import fetchFromObject from '../../../../../utils/fetchFromObject';
import { useOrder } from '../../../../../providers/order';

const NetworkingRow = ({
  name,
  type,
  document,
  identifier,
  similarity,
}: I.NetworkingInfoType) => {
  const { orderContext } = useOrder();
  return (
    <>
      <GridItem colSpan={3} data-testid={`network-info-name-${identifier}`}>
        <Stat title="Nome" copy={checkString(name)}>
          <BondsText
            text={checkString(name)}
            similarity={jaro(
              name,
              fetchFromObject(orderContext, similarity.name)
            )}
          />
        </Stat>
      </GridItem>
      <GridItem colSpan={1} data-testid={`network-info-type-${identifier}`}>
        <Stat title="Relação" copy={checkString(type)}>
          <BondsText text={checkString(type)} />
        </Stat>
      </GridItem>
      <GridItem colSpan={1} data-testid={`network-info-document-${identifier}`}>
        <Stat title="Documento" copy={checkString(document)}>
          <BondsText
            text={checkString(document)}
            similarity={jaro(
              document,
              fetchFromObject(orderContext, similarity.document)
            )}
          />
        </Stat>
      </GridItem>
    </>
  );
};

export default NetworkingRow;
