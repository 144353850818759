import React from 'react';
import { Divider, Stack, Tag, Text } from '@chakra-ui/react';
import * as I from '../../interfaces/order';

export default function AnalysisDetail({
  flow,
  model,
}: I.AnalysisDetail): React.ReactElement {
  return (
    <Stack padding="smaller">
      {flow ? (
        <>
          <Text fontWeight="bold">Flow</Text>
          <Text>
            Node:
            <Tag
              variant="solid"
              size="sm"
              color="white"
              style={{ backgroundColor: '#008E5A', margin: 2 }}
            >
              {flow.node?.name}
            </Tag>
          </Text>
          {flow?.node?.term_results ? (
            <>
              <Text>Term results</Text>
              {flow.node.term_results.map((result, index) => {
                const key = `flow_${result.rule_name}_${index}`;

                return (
                  <Text key={key}>
                    <Tag
                      variant="solid"
                      style={{ backgroundColor: '#008E5A', margin: 2 }}
                      size="sm"
                      color="white"
                    >
                      {result.rule_name}: {result.rule_result.toString()}
                    </Tag>
                  </Text>
                );
              })}
            </>
          ) : null}
        </>
      ) : null}

      {flow && model && <Divider />}

      {model ? (
        <>
          <Text fontWeight="bold">Model</Text>
          {model?.features ? (
            <Text>
              Features:
              {model.features.map((feat) => (
                <Tag
                  variant="solid"
                  style={{ backgroundColor: '#008E5A', margin: 2 }}
                  size="sm"
                  color="white"
                >
                  {feat}
                </Tag>
              ))}
            </Text>
          ) : null}
          {model?.recommendation ? (
            <Text>
              recommendation:
              <Tag
                variant="solid"
                style={{ backgroundColor: '#008E5A', margin: 2 }}
                size="sm"
                color="white"
              >
                {model.recommendation}
              </Tag>
            </Text>
          ) : null}
        </>
      ) : null}
    </Stack>
  );
}
