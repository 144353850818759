import styled from 'styled-components';
import { Box, Stack, Text } from '@chakra-ui/react';

export const StatusItem = styled(Box)`
  width: 100%;
  margin-top: 0 !important;
  padding: ${({ theme }) => theme.space.regular};
  border-radius: ${({ theme }) => theme.radii.large};
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
`;

export const NumberContainer = styled(Stack).attrs(() => ({
  direction: 'row',
  alignItems: 'center',
}))``;

export const ContainerIndicator = styled(Box)`
  width: ${({ theme }) => theme.sizes['extra-large']};
  height: ${({ theme }) => theme.sizes['extra-large']};
  position: relative;
  margin-bottom: -${({ theme }) => theme.space.smaller}; //negative
`;

export const ColorIndicatorBigger = styled(Box)`
  width: ${({ theme }) => theme.sizes['extra-large']};
  height: ${({ theme }) => theme.sizes['extra-large']};
  position: absolute;
  border-radius: ${({ theme }) => theme.radii.full};
`;

export const ColorIndicatorSmaller = styled(Box)`
  width: ${({ theme }) => theme.sizes.smallest};
  height: ${({ theme }) => theme.sizes.smallest};
  position: absolute;
  border-radius: ${({ theme }) => theme.radii.full};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NumberLabel = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.big};
`;

export const Label = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  word-break: break-word;
`;
