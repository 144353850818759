/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { NavLink } from 'react-router-dom';
import { Image, Text, Tooltip } from '@chakra-ui/react';
import { Column } from '@material-table/core';
import { OrderModel } from '../../interfaces/order';
import { checkString } from '../../utils/check_values';
import jaro from '../../utils/jaro';
import { phoneMask } from '../../utils/mask';
import { Bond, CustomDate } from '../base/table/config';
import BondsText from '../bonds';
import {
  findFinalizedReview,
  handleColor,
  handleHintTreeOptions,
  handleJaro,
} from './utils';
import ShopIcon from '../../assets/icons/shopIcn.svg';
import ChatIcon from '../../assets/icons/chat.svg';
import * as S from './styles';
import Badge from '../badge';
import { getStatusDetails } from '../../utils/handle_status';

export const birotTableColumns = (orderContext: OrderModel | null) => {
  return [
    CustomDate({
      title: 'DATA E HORA',
      field: 'order.date',
      editable: 'never',
      minWidth: 150,
    }),
    {
      title: 'LOJA',
      field: 'client_name',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 150,
      cellStyle: (clientName): React.CSSProperties => {
        if (clientName && orderContext?.client_name) {
          return handleJaro(jaro(clientName, orderContext.client_name));
        }
        return {};
      },
      render: (rowData: OrderModel): React.ReactNode => {
        const similarity = jaro(
          rowData?.client_name,
          orderContext?.client_name
        );
        return (
          <NavLink
            to={{
              pathname: `/orders/info/${rowData.id}`,
              state: {
                from: '/filas',
              },
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BondsText
              color={handleColor(similarity)}
              size="small"
              text={checkString(rowData?.client_name)}
              similarity={similarity}
            />
          </NavLink>
        );
      },
    },
    {
      title: 'COMPRADOR',
      field: 'order.buyer.name',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 170,
      cellStyle: (buyerName): React.CSSProperties => {
        if (buyerName && orderContext?.order?.buyer?.name) {
          return handleJaro(jaro(buyerName, orderContext.order.buyer.name));
        }
        return {};
      },
      render: (rowData: OrderModel): React.ReactNode => {
        const similarity = jaro(
          rowData?.order?.buyer?.name,
          orderContext?.order?.buyer?.name
        );
        return (
          <BondsText
            color={handleColor(similarity)}
            size="small"
            text={rowData?.order?.buyer?.name}
            similarity={similarity}
          />
        );
      },
    },
    {
      title: 'DOCUMENTO',
      field: 'order.buyer.document',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 150,
      cellStyle: (buyerDocument): React.CSSProperties => {
        if (buyerDocument && orderContext?.order?.buyer?.document) {
          return handleJaro(
            jaro(buyerDocument, orderContext.order.buyer.document)
          );
        }
        return {};
      },
      render: (rowData: OrderModel): React.ReactNode => {
        const similarity = jaro(
          rowData?.order?.buyer?.document,
          orderContext?.order?.buyer?.document
        );
        return (
          <BondsText
            color={handleColor(similarity)}
            size="small"
            text={rowData?.order?.buyer?.document}
            similarity={similarity}
          />
        );
      },
    },
    {
      title: 'E-MAIL',
      field: 'order.buyer.email',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 200,
      cellStyle: (buyerEmail): React.CSSProperties => {
        if (buyerEmail && orderContext?.order?.buyer?.email) {
          return handleJaro(jaro(buyerEmail, orderContext.order.buyer.email));
        }
        return {};
      },
      render: (rowData: OrderModel): React.ReactNode => {
        const similarity = jaro(
          rowData?.order?.buyer?.email,
          orderContext?.order?.buyer?.email
        );
        return (
          <BondsText
            color={handleColor(similarity)}
            size="small"
            text={rowData?.order?.buyer?.email}
            similarity={similarity}
          />
        );
      },
    },
    Bond({
      title: 'TELEFONE',
      field: 'order.buyer.phones',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 170,
      cellStyle: (buyerPhones): React.CSSProperties => {
        if (buyerPhones && orderContext?.order?.buyer?.phones) {
          return handleJaro(
            jaro(
              buyerPhones.map((item: any) => item.full_number).join(),
              orderContext.order.buyer.phones
                .map((item) => item.full_number)
                .join()
            )
          );
        }
        return {};
      },
      render: (rowData: OrderModel): React.ReactNode => {
        return rowData.order.buyer.phones.map((item, index) => {
          const key = `order_buyer_phones_&${index + 1}_full_number`;
          const similarity = jaro(
            item?.full_number,
            orderContext?.order?.buyer?.phones?.[index]?.full_number
          );

          return (
            <BondsText
              color={handleColor(
                jaro(
                  rowData.order.buyer.phones
                    .map((phone: any) => phone.full_number)
                    .join(),
                  orderContext?.order?.buyer?.phones
                    ?.map((phone) => phone.full_number)
                    .join()
                )
              )}
              size="small"
              key={key}
              text={phoneMask({ value: item?.full_number, removeHyphen: true })}
              similarity={similarity}
            />
          );
        });
      },
    }),
    {
      title: 'ENDEREÇO',
      field: 'order.buyer.address.full_address',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 200,
      cellStyle: (buyerAddress): React.CSSProperties => {
        if (buyerAddress && orderContext?.order?.buyer?.address?.full_address) {
          return handleJaro(
            jaro(buyerAddress, orderContext.order.buyer.address.full_address)
          );
        }
        return {};
      },
      render: (rowData: OrderModel): React.ReactNode => {
        const similarity = jaro(
          rowData?.order?.buyer?.address?.full_address,
          orderContext?.order?.buyer?.address?.full_address
        );
        return (
          <BondsText
            color={handleColor(similarity)}
            size="small"
            text={rowData?.order?.buyer?.address?.full_address}
            similarity={similarity}
          />
        );
      },
    },
    Bond({
      title: 'TÍTULAR',
      field: 'order.payments',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 150,
      cellStyle: (orderPaymentsName): React.CSSProperties => {
        if (orderPaymentsName && orderContext?.order?.payments) {
          return handleJaro(
            jaro(
              orderPaymentsName.map((item: any) => item.holder_name).join(),
              orderContext.order.payments.map((item) => item.holder_name).join()
            )
          );
        }
        return {};
      },
      render: (rowData: OrderModel): React.ReactNode => {
        return rowData.order.payments.map((item, index) => {
          const key = `order_payments_${index}_holderName`;
          const similarity = jaro(
            item?.holder_name,
            orderContext?.order?.payments?.[index]?.holder_name
          );

          return (
            <BondsText
              color={handleColor(
                jaro(
                  rowData.order.payments
                    ?.map((payment: any) => payment.holder_name)
                    .join(),
                  orderContext?.order?.payments
                    ?.map((payment) => payment.holder_name)
                    .join()
                )
              )}
              key={key}
              size="small"
              text={item?.holder_name}
              similarity={similarity}
            />
          );
        });
      },
    }),
    Bond({
      title: 'MEIO DE PAGAMENTO',
      field: 'order.payments',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 200,
      cellStyle: (orderPaymentsType): React.CSSProperties => {
        if (orderPaymentsType && orderContext?.order?.payments) {
          return handleJaro(
            jaro(
              orderPaymentsType
                .map((item: any) => `${item.bin}****${item.last_4}`)
                .join(),
              orderContext.order.payments
                .map((item) => `${item.bin}****${item.last_4}`)
                .join()
            )
          );
        }
        return {};
      },
      render: (rowData: OrderModel): React.ReactNode => {
        return rowData.order.payments.map((payment, index) => {
          const key = `order_payments_${index}_payment_type`;
          const similarity = jaro(
            `${payment?.bin}****${payment?.last_4}`,
            `${orderContext?.order?.payments?.[index]?.bin}****${orderContext?.order?.payments?.[index]?.last_4}`
          );

          if (payment?.payment_type === 'boleto') {
            return (
              <Text
                color={handleColor(
                  jaro(
                    rowData?.order?.payments
                      ?.map((item: any) => `${item.bin}****${item.last_4}`)
                      .join(),
                    orderContext?.order?.payments
                      .map((item) => `${item.bin}****${item.last_4}`)
                      .join()
                  )
                )}
                key={key}
              >
                boleto
              </Text>
            );
          }

          return (
            <BondsText
              color={handleColor(
                jaro(
                  rowData?.order?.payments
                    ?.map((item: any) => `${item.bin}****${item.last_4}`)
                    .join(),
                  orderContext?.order?.payments
                    .map((item) => `${item.bin}****${item.last_4}`)
                    .join()
                )
              )}
              key={key}
              size="small"
              text={`${payment?.bin}****${payment?.last_4}`}
              similarity={similarity}
            />
          );
        });
      },
    }),
    Bond({
      title: 'VALOR',
      field: 'order.payments',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 140,
      render: (rowData: OrderModel): React.ReactNode => {
        return rowData?.order?.payments?.map((item, index) => {
          const key = `order_payments_${index}_amount@display`;
          return <Text key={key}>{item['amount@display']}</Text>;
        });
      },
    }),
    Bond({
      title: 'STATUS',
      field: 'status@display',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 100,
      render: (rowData): React.ReactNode => {
        const status = getStatusDetails({
          status: rowData?.status,
          recommendation: rowData?.recommendation,
          reviewDetail: rowData?.review_detail?.state,
        });

        return (
          <Badge
            label={status.label}
            color={status.color}
            background={status.background}
            fontSize="small"
            icon={status.icon}
          />
        );
      },
    }),
    Bond({
      title: 'RECOMENDAÇÃO',
      field: 'recommendation@display',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 120,
      render: (rowData): React.ReactNode => {
        if (!rowData.recommendation)
          return <Text>{rowData['recommendation@display']}</Text>;
        if (rowData.review_detail)
          return <Text>{rowData['recommendation@display']} manualmente</Text>;
        return <Text>{rowData['recommendation@display']} automaticamente</Text>;
      },
    }),
    Bond({
      title: 'PAGAMENTO',
      field: 'order.payments',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 110,
      render: (rowData: OrderModel): React.ReactNode => {
        return rowData?.order?.payments?.map((item, index): React.ReactNode => {
          const key = `order_payments_${index}_status@display`;
          const status = getStatusDetails({ status: item.status });
          const hasChargeback = status.label === 'Chargeback';
          const { color, background } = getStatusDetails({
            status: 'chargeback',
            isPaymentStatus: true,
          });

          const badgeStyle = hasChargeback
            ? { color, background }
            : {
                color: status.color,
                background: status.background,
                icon: status.icon,
              };

          return (
            <Badge
              key={key}
              label={status.label}
              color={badgeStyle.color}
              background={badgeStyle.background}
              icon={badgeStyle.icon}
              fontSize="small"
            />
          );
        });
      },
    }),
    {
      title: '',
      field: 'order.products',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 50,
      render: (rowData: OrderModel): React.ReactNode => {
        const items: string[] = rowData.order.products.map((item) => item.name);

        return (
          <Tooltip
            hasArrow
            label={<S.ItemText>{items.join(', ')}</S.ItemText>}
            borderRadius="large"
            bg="neutral.800"
          >
            <Image alt="shop icon" src={ShopIcon} />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      field: 'review_detail',
      editable: 'never',
      emptyValue: 'Não informado',
      minWidth: 60,
      render: (rowData: OrderModel): React.ReactNode => {
        const review = findFinalizedReview(rowData.review_detail.events);

        return (
          <Tooltip
            hasArrow
            p={3}
            label={
              <>
                {handleHintTreeOptions(review)}
                {review?.message ? (
                  <S.Row>
                    <S.ItemText>
                      <b>Comentário: </b>
                      {review?.message}
                    </S.ItemText>
                  </S.Row>
                ) : (
                  '-'
                )}
              </>
            }
            borderRadius="large"
            bg="neutral.800"
          >
            <Image alt="chat icon" src={ChatIcon} />
          </Tooltip>
        );
      },
    },
  ] as Column<OrderModel>[];
};
