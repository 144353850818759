import { Icon } from '@chakra-ui/react';
import React from 'react';
import * as I from './interfaces';
import * as S from './styles';
import handleBondStatus from './utils';

const BondsText = ({
  color,
  size = 'medium',
  similarity,
  text,
}: I.BondsTextProps): React.ReactElement => {
  const status = handleBondStatus(similarity);

  return (
    <S.Container data-testid="container-bonds-text">
      {status.icon && (
        <Icon
          as={status.icon}
          fontSize={size}
          color={color ?? status.color}
          style={{ marginRight: '4px' }}
          data-testid="icon-bonds-text"
        />
      )}
      <S.Text
        fontSize={size}
        color={color ?? status.color}
        data-testid="text-bonds-text"
      >
        {text}
      </S.Text>
    </S.Container>
  );
};

export default BondsText;
