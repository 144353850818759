/* eslint-disable camelcase */
import React from 'react';
import { GridItem } from '@chakra-ui/react';
import {
  checkPostalCode,
  checkString,
} from '../../../../../utils/check_values';
import BondsText from '../../../../bonds';
import Stat from '../../../../stat';
import * as I from './interface';

const AddressRow = ({
  street,
  number,
  city,
  state,
  postal_code,
  address_type,
  identifier,
  similarity,
}: I.AddressInfoType) => {
  return (
    <>
      <GridItem colSpan={3} data-testid={`address-info-street-${identifier}`}>
        <Stat
          title="Rua"
          copy={`${checkString(street)}, nº ${checkString(number)}`}
        >
          <BondsText
            text={`${checkString(street)}, nº ${checkString(number)}`}
            similarity={similarity}
          />
        </Stat>
      </GridItem>
      <GridItem
        colSpan={2}
        data-testid={`address-info-city-state-${identifier}`}
      >
        <Stat
          title="Cidade/Estado"
          copy={`${checkString(city)}/${checkString(state)}`}
        >
          <BondsText
            text={`${checkString(city)}/${checkString(state)}`}
            similarity={similarity}
          />
        </Stat>
      </GridItem>
      <GridItem colSpan={1} data-testid={`address-info-cep-${identifier}`}>
        <Stat title="CEP" copy={checkPostalCode(postal_code)}>
          <BondsText
            text={checkPostalCode(postal_code)}
            similarity={similarity}
          />
        </Stat>
      </GridItem>
      <GridItem colSpan={1} data-testid={`address-info-type-${identifier}`}>
        <Stat title="Tipo" copy={checkString(address_type)}>
          <BondsText text={checkString(address_type)} />
        </Stat>
      </GridItem>
    </>
  );
};

export default AddressRow;
