import React from 'react';
import { toast } from 'react-toastify';
import { Route, Redirect } from 'react-router-dom';

import * as I from '../interfaces/routes';

import checkRoles from '../utils/check_roles';
import generateUUID from '../utils/generateUUID';
import { SETID } from '../services/storage';

export default function PrivateRoute(
  props: I.PrivateRoute
): React.ReactElement {
  const { component, roles, ...rest } = props;
  const Component = component;
  const hasRole = checkRoles(roles);
  const UUID = generateUUID();
  localStorage.setItem(SETID, UUID);

  const handleRole = (): string | number | React.ReactElement => {
    if (hasRole) {
      return <Component {...props} />;
    }

    return (
      toast.error(`Você não possui acesso a este recurso`) && (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )
    );
  };

  return <Route {...rest}>{handleRole()}</Route>;
}

PrivateRoute.defaultProps = {
  exact: true,
};
