import * as I from './interfaces';
import TermsBase from './base';
import { isEditingAtom, rulesDataAtom } from '../stores';

export default function TermsFlows(props: I.TermsProps) {
  return (
    <TermsBase
      {...props}
      isEditingAtom={isEditingAtom}
      rulesDataAtom={rulesDataAtom}
    />
  );
}
