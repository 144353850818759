import React from 'react';
import { Wrap, WrapItem } from '@chakra-ui/react';

import * as I from './interfaces';

import { ErrorPolicyTranslations } from './utils';
import Stat from '../../stat';

export default function Information({
  term,
  nodeIndex,
  termIndex,
}: Readonly<I.InformationTermsProps>): React.ReactElement {
  const { rule, complement, error_policy: errorPolicy } = term;

  const errorPolicyKey = errorPolicy ?? 'default';

  const translatedComplement = complement ? 'Sim' : 'Não';
  const translatedErrorPolicy =
    ErrorPolicyTranslations[errorPolicyKey] ?? 'Não Informado';

  return (
    <Wrap spacing="large">
      <WrapItem minW="320px">
        <Stat
          title="Regra"
          text={rule?.name}
          identifier={`flow_nodes_${nodeIndex}_terms_${termIndex}_rule_name`}
        />
      </WrapItem>

      <WrapItem>
        <Stat
          title="Complemento"
          text={translatedComplement}
          identifier={`flow_nodes_${nodeIndex}_terms_${termIndex}_complement`}
        />
      </WrapItem>

      <WrapItem>
        <Stat
          title="Política de erro"
          text={translatedErrorPolicy}
          identifier={`flow_nodes_${nodeIndex}_terms_${termIndex}_error_policy`}
        />
      </WrapItem>
    </Wrap>
  );
}
