import React from 'react';
import Container from './Container';
import * as I from './interfaces';

export default function CreateIcon({
  img,
  text,
}: I.Create): React.ReactElement {
  return (
    <Container>
      <span>
        {img || null} {text}
      </span>
    </Container>
  );
}

CreateIcon.defaultProps = {
  img: null,
};
