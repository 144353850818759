const totalTimeInSeconds = 48 * 3600;

export const formatTime = (seconds: number) => {
  const hours = Math.floor(Math.abs(seconds) / 3600);
  const minutes = Math.floor((Math.abs(seconds) % 3600) / 60);
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${hours}h${formattedMinutes}`;
};

export const getColor = (seconds: number) => {
  if (seconds <= 4 * 3600) return 'danger.100';
  if (seconds <= 24 * 3600) return 'warning.100';
  return 'info.100';
};

export const getProgressValue = (seconds: number) => {
  if (seconds >= totalTimeInSeconds) return 0;
  if (seconds < 0) return 100;
  return ((totalTimeInSeconds - seconds) / totalTimeInSeconds) * 100;
};
