/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default function fetchFromObject(obj: any, prop: string): string {
  if (typeof obj === 'undefined') {
    return '';
  }

  const pathArr = prop.split('.');
  let current = obj;

  for (const key of pathArr) {
    if (key.endsWith(']')) {
      const match = key.match(/\[([^\]]{0,100})\]/);
      if (!match) return '';

      const arrayIndex = match[1];
      if (!current || !current.hasOwnProperty(key.slice(0, -3))) {
        return '';
      }

      current = current[key.slice(0, -3)][arrayIndex];
    } else {
      if (!current || !current.hasOwnProperty(key)) {
        return '';
      }

      current = current[key];
    }
  }

  return current || '';
}
