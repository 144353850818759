import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GroupsProvider } from '../../../providers/groups';
import useQuery from '../../../hooks/queryParams';
import GenericForm from '../../../components/base/v2/genericForm';
import GroupsFormContainer from '../../../container/groups/forms';
import useGroups from '../../../hooks/api/groups';
import { GroupModel } from '../../../interfaces/groups';
import LoadingSpinner from '../../../components/loadingSpinner';

import * as S from '../styles';

const GroupsUpdate: React.FC = () => {
  const query = useQuery();
  const id = query.get('id') ?? undefined;
  const [isDisabled, setIsDisabled] = useState(true);
  const [groupData, setGroupData] = useState<GroupModel>();

  const { push } = useHistory();
  const { getOneGroup } = useGroups();

  const handleEditing = () => {
    setIsDisabled(!isDisabled);
  };

  const handleDirectToList = () => {
    push(`/groups/info/list?id=${groupData?.id}`);
  };

  const loadData = async () => {
    const { data } = await getOneGroup(id);

    setGroupData(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!groupData) {
    return (
      <S.LoadingContainer>
        <LoadingSpinner />
      </S.LoadingContainer>
    );
  }

  return (
    <GroupsProvider id={id}>
      {groupData && (
        <GenericForm
          breadcrumb={[
            { label: 'Início', href: '/' },
            { label: 'Grupos', href: '/groups' },
            { label: groupData.name },
          ]}
          title={groupData.name}
          showBackButton
          backButtonUrl="/groups"
          handleAction={handleEditing}
          actionText="Editar Grupo"
          isDisabled={isDisabled}
          secondAction={handleDirectToList}
          secondActionText="Clientes"
        >
          <GroupsFormContainer
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
        </GenericForm>
      )}
    </GroupsProvider>
  );
};

export default GroupsUpdate;
