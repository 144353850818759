import React from 'react';
import { Heading, Icon, Image, Stack, Text } from '@chakra-ui/react';

import * as I from './interfaces';

export default function ImageWithText({
  image,
  icon,
  title,
  text,
}: I.ImageWithTextProps): React.ReactElement {
  return (
    <Stack
      spacing="smallest"
      align="center"
      maxWidth="16.25rem"
      margin="0 auto"
    >
      {image && (
        <Image
          data-testid="image"
          alt={image.alt}
          src={image.src}
          height="7.5rem"
          width="7.5rem"
        />
      )}
      {icon && (
        <Icon data-testid="icon" as={icon} height="7.5rem" width="7.5rem" />
      )}
      <Heading as="h4" fontSize="medium" textAlign="center">
        {title}
      </Heading>
      <Text fontSize="regular" textAlign="center">
        {text}
      </Text>
    </Stack>
  );
}

ImageWithText.defaultProps = {
  image: null,
  icon: null,
};
