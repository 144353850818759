import * as I from './interfaces';

import { trexApi } from '../../../services/api';
import { SETID } from '../../../services/storage';
import checkValid from '../../../utils/check_valid';
import { handleApiRequest } from '../utils';

const useQueues = () => {
  const UUID = localStorage.getItem(SETID);
  const prefix = 'queues';

  const createQueue = async ({ data }: Pick<I.QueuesAPIsProps, 'data'>) => {
    const response = await handleApiRequest(
      trexApi.post(prefix, data, {
        headers: { 'X-SetID': UUID },
      })
    );

    return response;
  };

  const updateQueue = async ({ id, data, version }: I.QueuesAPIsProps) => {
    const response = await handleApiRequest(
      trexApi.put(`${prefix}/${id}`, data, {
        headers: {
          'if-match': version,
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getQueues = async ({
    page,
    pageSize,
    orderBy,
    orderDirection,
    search,
  }: I.QueuesAPIsQueryType) => {
    const response = await handleApiRequest(
      trexApi.get(prefix, {
        params: {
          page: page + 1,
          'page-size': pageSize,
          'sort-by': orderBy?.field
            ? `${orderBy.field.toString()},${orderDirection}`
            : null,
          search: checkValid(search),
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getOneQueue = async ({ id }: Pick<I.QueuesAPIsProps, 'id'>) => {
    const response = await handleApiRequest(
      trexApi.get(`${prefix}/${id}`, {
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getAllQueueFilters = async () => {
    const response = await handleApiRequest(
      trexApi.get(`${prefix}/filters`, {
        params: { 'page-size': 100 },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  return {
    createQueue,
    updateQueue,
    getOneQueue,
    getQueues,
    getAllQueueFilters,
  };
};
export default useQueues;
