import React from 'react';
import { PrimitiveAtom, useAtom } from 'jotai';
import { Grid, GridItem, Stack } from '@chakra-ui/react';

import Stat from '../../stat';
import Badge from '../../badge';
import { checkBoolean } from '../../../utils/check_values';
import { formatDate } from '../../../utils/format_date';
import { BlocksModel } from '../../../interfaces/blocks';
import { FlowModel } from '../../../interfaces/flow';

interface InformationBaseProps {
  initialDataAtom: PrimitiveAtom<FlowModel> | PrimitiveAtom<BlocksModel>;
  identifierPrefix: string;
  dataTestId: string;
  name: string;
}

export default function InformationBase({
  dataTestId,
  initialDataAtom,
  identifierPrefix,
  name,
}: InformationBaseProps): React.ReactElement {
  const [initialData] = useAtom(initialDataAtom);

  return (
    <Stack
      data-testid={dataTestId}
      spacing="medium"
      background="white"
      direction="row"
      p="medium"
      borderRadius="extra-large"
      mb="16px"
    >
      <Grid templateColumns="repeat(6, 1fr)" gap="medium" width="100%">
        <GridItem colSpan={2}>
          <Stat
            title={name}
            text={initialData.description}
            identifier={`${identifierPrefix}_description`}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <Stat
            title="Permitir revisão"
            text={checkBoolean(initialData.allow_review)}
            identifier={`${identifierPrefix}_allow-review`}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Stat
            title="Última modificação"
            text={formatDate(initialData.modified_at)}
            identifier={`${identifierPrefix}_modified-at`}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Stat
            title="Modificado por"
            text={initialData.modified_by}
            identifier={`${identifierPrefix}_modified-by`}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Stat title="Status" identifier={`${identifierPrefix}_status`}>
            <Badge
              label={initialData.status === 'enabled' ? 'Ativo' : 'Inativo'}
              schema={initialData.status === 'enabled' ? 'green' : 'red'}
              transparent
            />
          </Stat>
        </GridItem>
      </Grid>
    </Stack>
  );
}
