import React from 'react';
import { Wrap, WrapItem } from '@chakra-ui/react';

import * as I from './interfaces';

import Stat from '../../stat';
import Badge from '../../badge';

export default function ParamsInformation({
  term,
  nodeIndex,
  termIndex,
  rules,
}: Readonly<I.InformationParamsProps>): React.ReactElement {
  const { rule, id } = term;

  let parameterStats;

  if (rule?.parameters) {
    const validRule = rules.find((r) => r.name === rule.name);

    const mappedParameters = validRule?.parameters
      ?.map((param) => {
        const value = rule.parameters ? rule.parameters[param.name] : undefined;
        return { key: param.name, value };
      })
      .filter(({ key, value }) => key && value !== undefined);

    parameterStats = mappedParameters?.map(({ key, value }, i) => {
      const identifier = `flow_nodes_${nodeIndex}_terms_${termIndex}_${id}_${key}_${i}`;

      if (Array.isArray(value)) {
        return (
          <WrapItem key={identifier} maxW="320px">
            <Stat title={key} identifier={identifier}>
              <Wrap spacing="smallest">
                {value.map((val, index) => {
                  const childKey = `flow_nodes_${nodeIndex}_terms_${termIndex}_${id}_${key}_${i}_${index}`;
                  return (
                    <WrapItem key={childKey}>
                      <Badge
                        label={val?.toString()}
                        schema="gray"
                        transparent
                      />
                    </WrapItem>
                  );
                })}
              </Wrap>
            </Stat>
          </WrapItem>
        );
      }

      return (
        <WrapItem key={identifier}>
          <Stat title={key} text={value?.toString()} identifier={identifier} />
        </WrapItem>
      );
    });
  }

  return (
    <Wrap
      spacing="large"
      borderBottom="1px solid"
      borderColor="neutral.400"
      paddingBottom="medium"
    >
      {parameterStats}
    </Wrap>
  );
}
