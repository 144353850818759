/* eslint-disable import/prefer-default-export */
import fetchFromObject from '../../../../../utils/fetchFromObject';
import jaro from '../../../../../utils/jaro';
import * as I from './interface';

export const sortAddressArr = ({
  birotContext,
  orderContext,
  similarity,
}: I.SortFunctionProps) => {
  const similarityArray = birotContext?.document?.addresses?.map((item) => {
    const itemSimilarity = jaro(
      item.full_address,
      fetchFromObject(orderContext, similarity.full_address)
    );

    return { ...item, similarity: itemSimilarity };
  });

  similarityArray?.sort((a, b) => b.similarity - a.similarity);

  return similarityArray;
};
