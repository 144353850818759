import { atom } from 'jotai';
import { FlowModel, RuleModel } from '../../../interfaces/flow';
import { defaultFlow } from '../utils';

interface RulesNames {
  label: string;
  value: string;
}

export const rulesDataAtom = atom<{
  rules: RuleModel[];
  rulesNames: RulesNames[];
}>({
  rules: [],
  rulesNames: [],
});

export const initialDataAtom = atom<FlowModel>(defaultFlow);

export const loadingAtom = atom(false);

export const disabledAtom = atom(false);

export const isEditingAtom = atom(false);

export const isEditingNodeAtom = atom(false);

export const isLoadedAtom = atom(false);

export const erroredAccordionIndexAtom = atom<number | null>(null);
