import styled from 'styled-components';
import { Text } from '@chakra-ui/react';
import { theme, styledTheme } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
`;

export const Title = styled(Text).attrs(() => ({
  fontSize: 'small',
  marginBottom: `${styledTheme.spacing(0.5)}px`,
}))``;

export const SubTitle = styled(Text).attrs(() => ({
  fontSize: 'small',
  color: theme.colors.v2.dark[200],
  marginTop: `${styledTheme.spacing(0.5)}px`,
}))``;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const CopyContainer = styled.div`
  min-height: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
