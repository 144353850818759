/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledForm = styled.form`
  height: 270px;
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
