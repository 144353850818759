import { Tooltip } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { TooltipWrapperProps } from './interface';

const TooltipWrapper = ({
  description,
  children,
}: PropsWithChildren<TooltipWrapperProps>) => (
  <Tooltip label={description || ''} placement="top-start">
    <span>{children}</span>
  </Tooltip>
);

export default TooltipWrapper;
