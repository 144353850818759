/* eslint-disable consistent-return */
/* eslint-disable complexity */
/* eslint-disable import/prefer-default-export */
import { Image } from '@chakra-ui/react';
import Stat from '../../../stat';
import * as I2 from './interfaces';

import AmericanExpress from '../../../../assets/brands/amex.svg';
import Aura from '../../../../assets/brands/Aura.svg';
import Carnet from '../../../../assets/brands/carnet.svg';
import Cirrus from '../../../../assets/brands/cirrus.svg';
import Dankort from '../../../../assets/brands/dankort.svg';
import DinersClub from '../../../../assets/brands/diners-club.svg';
import Discover from '../../../../assets/brands/discover.svg';
import Eftpos from '../../../../assets/brands/eftpos.svg';
import Elo from '../../../../assets/brands/elo.svg';
import Hipercard from '../../../../assets/brands/hipercard.svg';
import Jcb from '../../../../assets/brands/JCB.svg';
import Maestro from '../../../../assets/brands/maestro.svg';
import Mastercard from '../../../../assets/brands/mastercard.svg';
import Nspk from '../../../../assets/brands/NSPKMIR.svg';
import Rupay from '../../../../assets/brands/rupay.svg';
import Sodexo from '../../../../assets/brands/sodexo.svg';
import Uatp from '../../../../assets/brands/UATP.svg';
import ChinaUnionPay from '../../../../assets/brands/union.svg';
import Visa from '../../../../assets/brands/visa.svg';

const brandImages = {
  AURA: Aura,
  DINERSCLUBINTERNATIONAL: DinersClub,
  CIRRUS: Cirrus,
  NSPKMIR: Nspk,
  DISCOVER: Discover,
  MASTERCARD: Mastercard,
  SODEXO: Sodexo,
  ELO: Elo,
  AMERICANEXPRESS: AmericanExpress,
  MAESTRO: Maestro,
  DANKORT: Dankort,
  EFTPOS: Eftpos,
  UATP: Uatp,
  VISA: Visa,
  CHINAUNIONPAY: ChinaUnionPay,
  CARNET: Carnet,
  JCB: Jcb,
  HIPERCARD: Hipercard,
  RUPAY: Rupay,
};

export const handleBrands = (brand?: string) => {
  if (!brand)
    return (
      <Stat
        fontColor="#FFF"
        fontWeight={400}
        fontSize="14px"
        text="não informado"
      />
    );

  const cleanedBrand = brand.replace(/\s+/g, '').toUpperCase();
  const BrandImage = brandImages[cleanedBrand as keyof I2.LogoSvgsType];

  return BrandImage ? (
    <Image maxWidth="40px" maxHeight="40px" src={BrandImage} />
  ) : (
    <Stat fontColor="#FFF" fontWeight={400} fontSize="14px" text={brand} />
  );
};

export const handleInstallmentValue = (
  amountDisplay: string,
  installments: number
): string => {
  const cleanedValue = amountDisplay.replace(/\D/g, '');
  const valueToFloat = parseFloat(cleanedValue) / 100;
  const installment = (valueToFloat / installments).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `R$ ${installment}`;
};
