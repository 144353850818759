export function handleProgressColor(
  value: number,
  mode: 'score' | 'time' = 'score'
): 'green' | 'orange' | 'purple' | 'alienPurple' {
  if (mode === 'score') {
    if (value && value >= 0.9) {
      return 'green';
    }
    if (value && value >= 0.5) {
      return 'orange';
    }
    return 'purple';
  }

  if (value <= 1000) {
    return 'green';
  }
  if (value <= 3000) {
    return 'orange';
  }
  return 'alienPurple';
}

export function formatValue(value?: number | null) {
  if (value === null) {
    return 'Sem dados';
  }
  if (value === 0) {
    return 'Não';
  }
  if (value === 1) {
    return 'Sim';
  }
  if (typeof value === 'number') {
    const seconds = value / 1000;
    return `${seconds.toFixed(2)} s`;
  }
  return value;
}

function getColorByScore(score: number): string {
  if (score >= 0.9) {
    return 'v2.green.300';
  }
  if (score >= 0.5) {
    return 'v2.orange.200';
  }

  if (score <= 0.5) {
    return 'base.alienPurple.200';
  }

  return 'v2.green.400';
}

function getColorByTime(time: number): string {
  if (time <= 1000) {
    return 'v2.green.300';
  }
  if (time <= 3000) {
    return 'v2.orange.200';
  }
  return 'base.alienPurple.200';
}

export function handleTitleColor(
  value: number,
  mode: 'score' | 'time' = 'score'
): string {
  if (!value) return 'v2.green.400';

  if (mode === 'score') {
    return getColorByScore(value);
  }

  return getColorByTime(value);
}
