import React from 'react';
import {
  FormControl as ChakraFormControl,
  FormErrorMessage,
} from '@chakra-ui/react';

import * as I from './interfaces';

export default function FormControl({
  children,
  error,
  isDisabled,
  isRequired,
  style,
  variant,
  ...rest
}: Readonly<I.FormControlProps>): React.ReactElement {
  return (
    <ChakraFormControl
      variant={variant ?? 'floating'}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isInvalid={!!error}
      style={style}
      width="fit-content"
      minWidth="177px"
      {...rest}
    >
      {children}
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </ChakraFormControl>
  );
}
